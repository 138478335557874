import React from "react";
import ProductUsersContainer from "../../Containers/ProductDetails/ProductUsersContainer";

const UsersListScreen = React.forwardRef((props, ref) => {
  return (
    <>
      <React.Fragment>
        <ProductUsersContainer ref={ref} />
      </React.Fragment>
    </>
  );
});

export default UsersListScreen;
