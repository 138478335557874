import React from "react";
import AddContentRolloutContainer from "../../Containers/ContentRollouts/AddContentRolloutsContainer";

const ContentRolloutAddScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <AddContentRolloutContainer />
      </React.Fragment>
    </>
  );
};

export default ContentRolloutAddScreen;

