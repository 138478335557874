import React from "react";
import AddTargetAttributeContainer from "../../Containers/ProductDetails/AddTargetAttributeContainer";

const TargetAttributesAddScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <AddTargetAttributeContainer />
      </React.Fragment>
    </>
  );
};

export default TargetAttributesAddScreen;
