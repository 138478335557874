import { Button, Col, Collapse, Form, Input, Row, Select, Space } from "antd";
import { useEffect } from "react";

const CommonFilterForm = ({
  filterFields,
  onSubmit,
  onReset,
  initialValues = {},
  layout = {},
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      className="filter-form"
      {...layout}
    >
      <Collapse expandIconPosition="right" defaultActiveKey={1}>
        <Collapse.Panel header="Filter" key="1">
          <Row className="list-form-gap" gutter={16}>
            {filterFields.map((field) => (
              <Col flex={1} key={field.name}>
                <Form.Item
                  name={field.name}
                  className="deco-ant-form-item-no-bottom-margin"
                >
                  {field.component}
                </Form.Item>
              </Col>
            ))}
            <Col flex={1}>
              <Space style={{ marginTop: "-5px" }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <Button type="default" onClick={onReset}>
                  Reset
                </Button>
              </Space>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </Form>
  );
};

export default CommonFilterForm;
