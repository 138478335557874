import React from "react";
import ListFeatureFlagContainer from "../../Containers/ProductFeatureFlag/ListFeatureFlagContainer";

const FeatureFlagAddScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <ListFeatureFlagContainer />
      </React.Fragment>
    </>
  );
};

export default FeatureFlagAddScreen;
