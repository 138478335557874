import { createSlice } from "@reduxjs/toolkit";
let initialState = {
  allTargetOperators: undefined,
  allTargetConditionsByType: undefined,
};

export const lookUpSlice = createSlice({
  name: "lookup",
  initialState: initialState,
  reducers: {
    setAllTargetOperatorsDetails(state, action) {
      state.allTargetOperators = action.payload.allTargetOperators;
    },
    setAllTargetConditionsByType(state, action) {
      state.allTargetConditionsByType =
        action.payload.allTargetConditionsByType;
    },
    resetLookupSliceData(state, action) {
      state.allTargetOperators = undefined;
      state.allTargetConditionsByType = undefined;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setAllTargetOperatorsDetails,
  setAllTargetConditionsByType,
  resetLookupSliceData,
} = lookUpSlice.actions;

export default lookUpSlice.reducer;
