// import PropTypes from "prop-types";
// import { connect } from "react-redux";

// const ShowForRoleComponent = (props) => {
//   const couldShow = props.userRoles.includes(props.permission);
//   return couldShow ? props.children : null;
// };

// ShowForRoleComponent.propTypes = {
//   permission: PropTypes.string.isRequired,
//   userRoles: PropTypes.array.isRequired,
// };

// const mapStateToProps = (state) => {
//     return {
//     userRoles: state.auth.accountData.roles, //<--- here you will get permissions for your user from Redux store
//   };
// };

// export const ShowForRoles = connect(mapStateToProps)(ShowForRoleComponent);

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const ShowForRightAccessComponent = ({ children }) => {
  const isFullAccess = useSelector(
    (state) => state.product.selectedEnvironment?.isFullAccess
  );

  return isFullAccess ? children : null;
};

ShowForRightAccessComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ShowForRightAccessComponent;
