import { addArrayDefaultValueToSchema } from "../../Util/commonUtility";

const defaultConfigSchema = {
  type: "object",
  required: ["Prop"],
  properties: {
    Prop: {
      type: "string",
    },
  },
};
export const defaultConfig = addArrayDefaultValueToSchema(defaultConfigSchema);
