import { useEffect, useState } from "react";
import AddProductBuildComponent from "../../Components/Build/addProductBuildComponent";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { useSelector } from "react-redux";
import { isNullOrEmpty } from "../../Util/commonUtility";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import NotificationService from "../../Services/notification";

const AddBuildContainer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showLoading, setShowLoading] = useState(false);
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [addEditProductBuild, setAddEditProductBuild] = useState();
  useEffect(() => {
    const getProductBuildByBuildId = async (id) => {
      setShowLoading(true);
      const response = await httpClient.get(
        `${Urls.TENANT_GET_PRODUCT_BUILD_ID.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{environmentId}", productState?.selectedEnvironment?.id)
          .replace("#{buildId}", id)}`
      );
      if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
        setAddEditProductBuild(response.data);
      } else {
        backToListPage();
      }
      setShowLoading(false);
    };
    if (id) {
      getProductBuildByBuildId(id);
    } else {
      setShowLoading(false);
    }
  }, [id, productState, authState]);
  const addProductBuildAPI = async (data) => {
    // let prepBuildMeta = {};
    // data?.buildMeta?.forEach((element) => {
    //   prepBuildMeta[element.key] = element.value;
    // });
    // data.buildMeta = prepBuildMeta;
    if (data.id) {
      setShowLoading(true);
      let newObj = { ...data };
      delete newObj.id;
      const response = await httpClient.put(
        `${Urls.TENANT_UPDATE_PRODUCT_BUILD.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{environmentId}", productState?.selectedEnvironment?.id)
          .replace("#{buildId}", data.id)}`,
        newObj
      );
      if (isSuccessStatus(response?.status)) {
        backToListPage();
        NotificationService.success("Success", "Build edit successfully");
      }
      setShowLoading(false);
    } else {
      setShowLoading(true);
      let newObj = { ...data };
      newObj.cdCiIdentifier = newObj.name;
      delete newObj.id;
      const response = await httpClient.post(
        `${Urls.TENANT_ADD_PRODUCT_BUILD.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{environmentId}", productState?.selectedEnvironment?.id)}`,
        newObj
      );
      if (isSuccessStatus(response?.status)) {
        backToListPage();
        NotificationService.success("Success", "Build Added successfully");
      } else {
        setShowLoading(false);
      }
    }
  };

  const backToListPage = () => {
    navigate("/product/build/");
  };
  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <AddProductBuildComponent
          onFinish={(data) => addProductBuildAPI(data)}
          editProductBuildData={addEditProductBuild}
          id={id}
          backToListPage={() => {
            backToListPage();
          }}
        />
      )}
    </>
  );
};
export default AddBuildContainer;
