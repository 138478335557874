import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allProducts: undefined,
  selectedProduct: undefined,
  allAllowedEnvs: undefined,
  selectedEnvironment: undefined,
  selectedEnvironmentHistory: [],
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductDetails(state, action) {
      const {
        allProducts,
        selectedProduct: { id: selectedProductId, environments },
        allAllowedEnvs,
      } = action.payload;

      state.allProducts = allProducts;
      state.selectedProduct = action.payload.selectedProduct;
      state.allAllowedEnvs = allAllowedEnvs;

      const selectedEnvironmentHistory = [...state.selectedEnvironmentHistory];
      const selectedEnvironmentIndex = selectedEnvironmentHistory.findIndex(
        (history) => history.selectedProductId === selectedProductId
      );

      if (selectedEnvironmentIndex > -1) {
        state.selectedEnvironment = environments.find(
          (env) =>
            env.id ===
            selectedEnvironmentHistory[selectedEnvironmentIndex]
              .selectedEnvironmentId
        );
      } else {
        const initialEnvironmentId = environments[0]?.id;
        if (initialEnvironmentId) {
          selectedEnvironmentHistory.push({
            selectedProductId,
            selectedEnvironmentId: initialEnvironmentId,
          });
          state.selectedEnvironment = environments[0];
        }
      }

      state.selectedEnvironmentHistory = selectedEnvironmentHistory;
    },

    setSelectedEnvironmentDetails(state, action) {
      const { selectedProductId, selectedEnvironmentId } = action.payload;
      state.selectedEnvironment = state.selectedProduct.environments.find(
        (env) => env.id === selectedEnvironmentId
      );

      const selectedEnvironmentHistory = [...state.selectedEnvironmentHistory];
      const selectedEnvironmentIndex = selectedEnvironmentHistory.findIndex(
        (history) => history.selectedProductId === selectedProductId
      );

      if (selectedEnvironmentIndex > -1) {
        selectedEnvironmentHistory[selectedEnvironmentIndex] = {
          selectedProductId,
          selectedEnvironmentId,
        };
      } else {
        selectedEnvironmentHistory.push({
          selectedProductId,
          selectedEnvironmentId,
        });
      }

      state.selectedEnvironmentHistory = selectedEnvironmentHistory;
    },
  },
  extraReducers: (builder) => {},
});

export const { setProductDetails, setSelectedEnvironmentDetails } =
  productSlice.actions;

export default productSlice.reducer;
