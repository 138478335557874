import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Divider, Input, Popconfirm, Select, Space, Table } from "antd";
import React from "react";
import ShowDescriptionInListing from "../Common/showDescriptionInListing";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import CommonFilterForm from "../Common/commonFilterForm";
import { TARGET_ATTRIBUTE_TYPE } from "../../Constant/DropDown";

const ListTargetAttributes = (props) => {
  const {
    dataSource,
    handlePageChange,
    paging,
    editTargetAttribute,
    deleteTargetAttributes,
    listFilterSubmit,
    resetFilter,
    listFilterData,
  } = props;

  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
    },
    {
      name: "description",
      component: (
        <Input
          className="list-filter-common-style"
          placeholder="Description"
        />
      ),
    },
    {
      name: "type",
      component: (
        <Select
          id="selectTargetAttributeType"
          className="list-filter-common-style"
          options={TARGET_ATTRIBUTE_TYPE}
          placeholder="Type"
        />
      ),
    },
  ];
  
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <ShowDescriptionInListing title={text} text={text} />,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space>
            <EditOutlined
              id={`editTargetAttribute-${record.id}`}
              title="Edit"
              onClick={() => {
                editTargetAttribute(record);
              }}
            />
            <Divider type="vertical"></Divider>
            <Popconfirm
              title="Are you sure to delete this target attribute ?"
              onConfirm={() => {
                deleteTargetAttributes(record);
              }}
            >
              <DeleteOutlined
                id={`deleteTargetAttribute-${record.id}`}
                title="Delete"
              />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={listFilterSubmit}
        onReset={resetFilter}
        initialValues={listFilterData}
      />
      <Divider />
      <Table
        dataSource={dataSource?.result}
        pagination={{
          showTotal: (total, range) => (
            <TotalReocordsInListingComponent
              totalRecords={paging.totalRecords}
            />
          ),
          pageSize: paging.pageSize,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          totalRecords: paging.totalRecords,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
        }}
        columns={columns}
        rowKey={"id"}
        size="small"
      />
    </>
  );
};

export default ListTargetAttributes;
