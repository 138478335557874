import { Route, Routes } from "react-router-dom";
import ListTenantUsersScreen from "./ListTenantUsers";
import AddTenantUsersScreen from "./AddTenantUsers";
import RestrictedRoute from "../../Routes/RestrictedRoute";
import { Role } from "../../Constant/Common";
import React from "react";

const TenantUsersRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route index path="/" element={<ListTenantUsersScreen />} />
        <Route
          exact
          path="/"
          element={<RestrictedRoute requiredRole={[Role.ADMIN]} />}
        >
          <Route exact path="/add" element={<AddTenantUsersScreen />} />
          <Route exact path="/edit/:id" element={<AddTenantUsersScreen />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default TenantUsersRoutes;
