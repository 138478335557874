import { useEffect, useState } from "react";
import { QueryBuilder } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.scss";

import { QueryBuilderAntD } from "@react-querybuilder/antd";
import { Button } from "antd";

import {
  isEmpty,
} from "../../Util/commonUtility";
import { targetCriteriaEquationCreationFunction } from "../../Util/targetCriteriaEquationCreationFunction";
import { CustomValueEditor } from "./QueryBuilderHelpers";

const initialQueryOuter = {
  rules: [],
};

export const TargetCriteriaComponent = (props) => {
  const {
    SubmitFinalQuery,
    SelectorDropdownArray,
    initialQuery,
    targetOperators,
    targetConditions,
  } = props;
  const [query, setQuery] = useState(initialQueryOuter);
  useEffect(() => {
    if (!isEmpty(initialQuery)) {
      setQuery(initialQuery);
    }
  }, [initialQuery]);

  const AddRuleButtons = (props) => (
    <>
      <Button 
        className="custom-add-action-button color"
        onClick={(e) => props.handleOnClick(e)}
      >
        + Equation
      </Button>
    </>
  );

  const AddGroupButtons = (props) => (
    <>
      <Button
        className="custom-add-action-button"
        onClick={(e) => props.handleOnClick(e)}
      >
        + Equation Group
      </Button>
    </>
  );
  useEffect(() => {
    // console.log(targetCriteriaEquationCreationFunction(query, SelectorDropdownArray));
    //Changes
    // console.log(579,JSON.stringify(query));
    SubmitFinalQuery(
      targetCriteriaEquationCreationFunction(query, SelectorDropdownArray),
      query
    );
  }, [query]);
  const getOperators = (fieldName) => {
    let typeObject = SelectorDropdownArray.find((o) => o.name === fieldName);
    if (typeObject?.type) {
      return targetConditions[typeObject?.type];
    }
  };

  return (
    <>
      <QueryBuilderAntD>
        <QueryBuilder 
          fields={SelectorDropdownArray}
          query={query}
          getOperators={getOperators}
          getValues={(e, y) => {}}
          onQueryChange={(q) => {
            setQuery(q);
          }}
          autoSelectField={false}
          autoSelectOperator={false}
          independentCombinators
          controlElements={{
            addRuleAction: AddRuleButtons,
            addGroupAction: AddGroupButtons,
            valueEditor: CustomValueEditor,
          }}
          combinators={targetOperators}
          controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
        />
      </QueryBuilderAntD>
    </>
  );
};

export default TargetCriteriaComponent;
