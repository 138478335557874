import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DefaultPagingValue } from "../../Constant/Common";
import { useNavigate } from "react-router-dom";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { handleLinkClick, isNullOrEmpty } from "../../Util/commonUtility";
import Loader from "../../Components/Common/Loader";
import {
  setProductDetails,
  setSelectedEnvironmentDetails,
} from "../../Store/Reducers/Slices/Product/productSlice";
import ListProductsComponent from "../../Components/Products/ListProductsComponent";

import NotificationService from "../../Services/notification";

const ListProductsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchInput, SetSearchInput] = useState(undefined);
  const [showLoading, setShowLoading] = useState(false);

  const authState = useSelector((state) => state.auth);
  const [tenantProducts, SetTenantProducts] = useState([]);

  const [paging, SetPaging] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE,
    pageIndex: DefaultPagingValue.PAGE_INDEX,
    totalPages: DefaultPagingValue.TOTAL_PAGES,
    totalRecords: DefaultPagingValue.TOTAL_RECORDS,
  });
  useEffect(() => {
    fetchAllTenantProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging.pageIndex, paging.pageSize]);
  const fetchAllTenantProducts = async (SearchText = "") => {
    setShowLoading(true);
    const response = await httpClient.get(
      `${Urls.TENANT_GET_ALL_PRODUCTS.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )}?PageNumber=${paging.pageIndex}&PageSize=${
        paging.pageSize
      }&SearchText=${SearchText}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      SetTenantProducts(response.data);
      if (response.data?.pagingStrategy) {
        SetPaging({
          pageIndex: response.data?.pagingStrategy?.currentPage,
          pageSize: response.data?.pagingStrategy?.pageSize,
          totalPages: response.data?.pagingStrategy?.totalPages,
          totalRecords: response.data?.pagingStrategy?.totalRecords,
        });
      }
      if (response.data?.result?.length <= 0) {
        SetPaging({
          ...paging,
          pageIndex: DefaultPagingValue.PAGE_INDEX,
        });
      }
    } else {
    }
    setShowLoading(false);
  };
  const handlePageChange = async (page, pageSize) => {
    SetPaging({
      ...paging,
      pageIndex: page,
      pageSize: pageSize,
    });
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    fetchAllTenantProducts(selectedKeys[0]);
    SetSearchInput(selectedKeys[0]);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    fetchAllTenantProducts();
    SetSearchInput(undefined);
  };
  const addEditProduct = (id = null) => {
    if (id) {
      navigate(`/products/edit/${id}`);
    } else {
      navigate("/products/add");
    }
  };
  const viewProductDetails = (data, target = "product") => {
    dispatch(
      setProductDetails({
        selectedProduct: data,
        allProducts: tenantProducts,
      })
    );
    if (target === "details") {
      navigate("/product/details");
    } else {
      navigate("/product/build");
    }
  };
  const deleteProduct = async (data) => {
    setShowLoading(true);
    const response = await httpClient.delete(
      `${Urls.TENANT_DELETE_PRODUCT_BY_ID.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      ).replace("#{productId}", data.id)}`
    );
    if (isSuccessStatus(response?.status)) {
      fetchAllTenantProducts();
      NotificationService.success("Success", "Product deleted successfully");
    } 
    setShowLoading(false);
  };
  const onProductEnvironmentClick = (event, envId, productData) => {
    dispatch(
      setProductDetails({
        selectedProduct: productData,
        allProducts: tenantProducts,
      })
    );
    dispatch(
      setSelectedEnvironmentDetails({
        selectedEnvironmentId: envId,
        selectedProductId: productData.id,
      })
    );
    handleLinkClick(event, "/product/build", navigate);
  };
  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <ListProductsComponent
          dataSource={tenantProducts}
          viewProductDetails={(data) => viewProductDetails(data, "details")}
          addProduct={() => addEditProduct()}
          viewProduct={(data) => viewProductDetails(data)}
          editProduct={(data) => addEditProduct(data.id)}
          deleteProduct={(data) => deleteProduct(data)}
          onProductEnvironmentClick={(e, envId, productData) =>
            onProductEnvironmentClick(e, envId, productData)
          }
          handleSearch={handleSearch}
          handleReset={handleReset}
          searchInput={searchInput}
          paging={paging}
          handlePageChange={(page, pageSize) => {
            handlePageChange(page, pageSize);
          }}
        />
      )}
    </>
  );
};

export default ListProductsContainer;
