import React from "react";
import AddFeatureRolloutContainer from "../../Containers/ProductFeatureRollout/AddFeatureRolloutContainer";

const FeatureRolloutAddScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <AddFeatureRolloutContainer />
      </React.Fragment>
    </>
  );
};

export default FeatureRolloutAddScreen;
