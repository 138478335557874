import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import BuildRoute from "./buildRoute";
import ProductInfoTabLayout from "../../Components/Common/productInfoTabLayout";
import { useNavigate, useLocation } from "react-router-dom";
import ReleaseRoute from "./releaseRoute";
import { Space, Button } from "antd";
import {
  AppstoreOutlined,
  CheckCircleOutlined,
  SwapOutlined,
  AimOutlined,
  PlusOutlined,
  ApartmentOutlined,
  UsergroupAddOutlined,
  FlagOutlined,
  RiseOutlined,
  NotificationOutlined,
  FundViewOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import TargetRoute from "./targetRoute";

import TestContainer from "../../Containers/Test/Test";
import DetailsContainer from "../../Containers/ProductDetails/DetailsContainer";
import TargetAttributesRoute from "../ProductDetails/TargetAttributesRoute";
import UsersRoute from "../ProductDetails/usersRoute";
import { Role } from "../../Constant/Common";
import FeatureFlagRoute from "./featureFlagRoute";
import FeatureRolloutRoute from "./featureRolloutRoute";
import { internalRoutes } from "../../Constant/internalRoutes";
import ContentTemplatesRoute from "./contentTemplatesRoute";
import ContentRolloutRoutes from "./contentRolloutRoutes";
import FileRoute from "./fileRoute";

const ProductInfoRoutes = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [extraButton, setExtraButton] = useState(false);
  const [selectedTab, setSelectedTab] = useState({ id: "1", action: "list" });
  const prepareExtraButton = (title, actionCallback) => {
    return (
      <ShowForRightAccessComponent>
        <Button
          type="primary"
          className="common-button"
          onClick={actionCallback}
          title={title}
          icon={<PlusOutlined />}
        >
          {title}
        </Button>
      </ShowForRightAccessComponent>
    );
  };
  const handleBuildRolloutEvent = () => {
    navigate("/product/release/add");
  };
  const handleBuildAddEvent = () => {
    navigate("/product/build/add");
  };

  const handleProductTargetEvent = () => {
    navigate("/product/target/add");
  };
  const handleTargetAttributeEvent = () => {
    navigate("/product/targetattributes/add");
  };
  const handleUserEvent = () => {
    navigate(`/product/users/add`);
  };
  const handleFeatureFlagEvent = () => {
    navigate(`/product/featureFlag/add`);
  };
  const handleFeatureRolloutEvent = () => {
    navigate(`/product/featureRollout/add`);
  };

  const handleNotificationTemplatesEvent = () => {
    navigate(internalRoutes.contentTemplatesAdd);
  };

  const handleNotificationRolloutEvent = () => {
    navigate(internalRoutes.contentRolloutAdd);
  };
  const handleListFileEvent = () => {
    navigate(internalRoutes.listFileAdd);
  };

  const onTabChange = (tabId) => {
    switch (tabId) {
      case "1":
        navigate("/product/build");
        setExtraButton(prepareExtraButton("Add Build", handleBuildAddEvent));
        break;
      case "2":
        navigate("/product/release");
        setExtraButton(
          prepareExtraButton("Add Release", handleBuildRolloutEvent)
        );
        break;
      case "3":
        navigate("/product/target");
        setExtraButton(
          prepareExtraButton("Add Target", handleProductTargetEvent)
        );
        break;
      case "4":
        navigate("/product/test");
        setExtraButton();
        break;
      case "5":
        setExtraButton();
        navigate("/product/details");
        break;
      case "6":
        setExtraButton(
          prepareExtraButton("Add Target Attribute", handleTargetAttributeEvent)
        );
        navigate("/product/targetattributes");
        break;
      case "7":
        setExtraButton(prepareExtraButton("Add Product User", handleUserEvent));
        navigate("/product/users");
        break;
      case "8":
        setExtraButton(
          prepareExtraButton("Add Feature Flag", handleFeatureFlagEvent)
        );
        navigate("/product/featureFlag");
        break;
      case "9":
        setExtraButton(
          prepareExtraButton("Add Feature Rollout", handleFeatureRolloutEvent)
        );
        navigate("/product/featureRollout");
        break;
      case "10":
        setExtraButton(
          prepareExtraButton(
            "Add Content Template",
            handleNotificationTemplatesEvent
          )
        );
        navigate(internalRoutes.contentTemplates);
        break;
      case "11":
        setExtraButton(
          prepareExtraButton(
            "Add Content Rollout",
            handleNotificationRolloutEvent
          )
        );
        navigate(internalRoutes.contentRollout);
        break;
      case "12":
        setExtraButton(prepareExtraButton("Add File", handleListFileEvent));
        navigate(internalRoutes.files);
        break;
      default:
    }
  };
  const tabItems = [
    {
      key: "6",
      url: "/product/targetattributes",
      label: (
        <Space>
          <ApartmentOutlined />
          Target Attributes
        </Space>
      ),
      access: [Role.ADMIN, Role.USER],
    },
    {
      key: "3",
      url: "/product/target",
      label: (
        <Space>
          <SwapOutlined />
          Targets
        </Space>
      ),
    },
    {
      label: (
        <Space>
          <AppstoreOutlined /> Builds
        </Space>
      ),
      key: "1",
      url: "/product/build",
    },
    {
      key: "2",
      url: "/product/release",
      label: (
        <Space>
          <CheckCircleOutlined />
          Releases
        </Space>
      ),
    },
    {
      key: "8",
      url: "/product/featureFlag",
      label: (
        <Space>
          <FlagOutlined />
          Features
        </Space>
      ),
      access: [Role.ADMIN],
    },
    {
      key: "9",
      url: "/product/featureRollout",
      label: (
        <Space>
          <RiseOutlined />
          Feature Rollouts
        </Space>
      ),
      access: [Role.ADMIN],
    },
    {
      key: "4",
      url: "/product/test",
      label: (
        <Space>
          <AimOutlined />
          Test
        </Space>
      ),
    },
    // {
    //   label: (
    //     <Space>
    //       <SlidersOutlined /> Details
    //     </Space>
    //   ),
    //   key: "5",
    //   url: "/product/details",
    //   children: <DetailsContainer />,
    //   access: [Role.ADMIN, Role.USER],
    // },
    {
      key: "7",
      url: "/product/users",
      label: (
        <Space>
          <UsergroupAddOutlined />
          Users
        </Space>
      ),
      access: [Role.ADMIN],
    },
    {
      key: "10",
      url: "/product/contentTemplates",
      label: (
        <Space>
          <NotificationOutlined /> Content Templates
        </Space>
      ),
      access: [Role.ADMIN],
    },
    {
      key: "11",
      url: "/product/contentRollouts",
      label: (
        <Space>
          <FundViewOutlined /> Content Rollouts
        </Space>
      ),
      access: [Role.ADMIN],
    },
    {
      key: "12",
      url: "/product/files",
      label: (
        <Space>
          <FileSearchOutlined /> Files
        </Space>
      ),
      access: [Role.ADMIN],
    },
  ];

  useEffect(() => {
    const path = location.pathname.split("/"); // Split the path into segments
    switch (path[1]) {
      case "product":
        switch (path[2]) {
          case "build":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "1", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "1", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "1", action: "list" });
                onTabChange("1");
                break;
            }
            break;
          case "release":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "2", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "2", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "2", action: "list" });
                onTabChange("2");
                break;
            }
            break;
          case "target":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "3", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "3", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "3", action: "list" });
                onTabChange("3");
                break;
            }
            break;
          case "test":
            setSelectedTab({ id: "4", action: "list" });
            onTabChange("4");
            break;
          case "details":
            setSelectedTab({ id: "5", action: "list" });
            onTabChange("5");
            break;
          case "targetattributes":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "6", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "6", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "6", action: "list" });
                onTabChange("6");
                break;
            }
            break;
          case "users":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "7", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "7", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "7", action: "list" });
                onTabChange("7");
                break;
            }
            break;
          case "featureFlag":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "8", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "8", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "8", action: "list" });
                onTabChange("8");
                break;
            }
            break;
          case "featureRollout":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "9", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "9", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "9", action: "list" });
                onTabChange("9");
                break;
            }
            break;
          case "contentTemplates":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "10", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "10", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "10", action: "list" });
                onTabChange("10");
                break;
            }
            break;
          case "contentRollouts":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "11", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "11", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "11", action: "list" });
                onTabChange("11");
                break;
            }
            break;
          case "files":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "12", action: "add" });
                break;
              default:
                setSelectedTab({ id: "12", action: "list" });
                onTabChange("12");
                break;
            }
            break;

          default:
            setSelectedTab({ id: "1", action: "list" });
            onTabChange("1");
            break;
        }
        break;
      default:
        // Handle default case or other paths
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <React.Fragment>
        <div>
          <ProductInfoTabLayout
            selectedTabId={selectedTab.id}
            tabs={tabItems}
            onTabChange={onTabChange}
            extraButton={selectedTab?.action === "list" && extraButton}
          />
        </div>
        <Routes>
          <Route index path="/" element={<BuildRoute />} />
          <Route index path="/build/*" element={<BuildRoute />} />
          <Route index path="/release/*" element={<ReleaseRoute />} />
          <Route
            index
            path="/contentTemplates/*"
            element={<ContentTemplatesRoute />}
          />
          <Route
            index
            path="/contentRollouts/*"
            element={<ContentRolloutRoutes />}
          />
          <Route index path="/target/*" element={<TargetRoute />} />
          <Route index path="/test" element={<TestContainer />} />
          <Route index path="/details" element={<DetailsContainer />} />
          <Route
            index
            path="/targetattributes/*"
            element={<TargetAttributesRoute />}
          />
          <Route index path="/users/*" element={<UsersRoute />} />
          <Route index path="/featureFlag/*" element={<FeatureFlagRoute />} />
          <Route
            index
            path="/featureRollout/*"
            element={<FeatureRolloutRoute />}
          />
          <Route index path="/files/*" element={<FileRoute />} />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default ProductInfoRoutes;

