import React from "react";
import ListContentRolloutsContainer from "../../Containers/ContentRollouts/ListContentRolloutsContainer";

const ContentRolloutListScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <ListContentRolloutsContainer />
      </React.Fragment>
    </>
  );
};

export default ContentRolloutListScreen;

