import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Popconfirm,
  Space,
  Table,
  Tag,
} from "antd";
import ShowForRoleComponent from "../../Services/ShowForRoles";
import { Role } from "../../Constant/Common";
import ShowDescriptionInListing from "../Common/showDescriptionInListing";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";

const ListProductsComponent = (props) => {
  const {
    dataSource,
    viewProduct,
    viewProductDetails,
    addProduct,
    handlePageChange,
    paging,
    deleteProduct,
    editProduct,
    onProductEnvironmentClick,
    handleSearch,
    handleReset,
    searchInput,
  } = props;

  const tableHeaderStyle = {
    backgroundColor: "#c4dcf6",
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          autoFocus
          placeholder={`Search product name / code`}
          value={selectedKeys[0] ?? searchInput}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            if (!e.target.value) {
              handleSearch([], confirm, dataIndex);
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    render: (text) => text,
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <ShowDescriptionInListing title={text} text={text} />,
    },
    {
      title: "Environments",
      dataIndex: "environments",
      key: "environments",
      render: (text, record) => {
        return (
          <>
            {record?.environments?.map((env, index) => {
              return (
                <Tag
                  id={`environment-${env.id}`}
                  key={index}
                  className={
                    env.isFullAccess
                      ? "common-tag-color"
                      : "common-tag-color-inactive"
                  }
                  onClick={(e) => {
                    onProductEnvironmentClick(e, env?.id, record);
                  }}
                  // className="tags"
                  // color={colorCodeBasedOnEnvironemnt(env.name.toLowerCase())}
                >
                  <a
                    href={`/product/${env.name}`}
                    onContextMenu={(e) => {
                      onProductEnvironmentClick(e, env?.id, record);
                    }}
                  >
                    {env?.name?.toUpperCase()}
                  </a>
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: "5%",
      render: (text, record) => {
        return (
          <Space>
            <Dropdown overlay={menu(record)} trigger={["click"]}>
              <Button
                className="margin-bottom-zero"
                type="link"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </Space>
        );
      },
    },
  ];
  const handleMenuClick = (e, record) => {
    switch (e.key) {
      case "productInfo":
        viewProductDetails(record);
        break;
      case "viewProduct":
        viewProduct(record);
        break;
      case "editProduct":
        editProduct(record);
        break;

      default:
        break;
    }
  };
  const menu = (record) => (
    <Menu onClick={(e) => handleMenuClick(e, record)}>
      <Menu.Item key="viewProduct">
        <Space>
          <EyeOutlined id={`viewProduct-${record.id}`} title="Details" />
          Product Details
        </Space>
      </Menu.Item>
      <Menu.Item key="editProduct">
        <ShowForRoleComponent permissions={[Role.ADMIN]}>
          <Space>
            <EditOutlined
              id={`editProduct-${record.id}`}
              title="Edit"
              onClick={() => {
                editProduct(record);
              }}
            />
            Edit Product
          </Space>
        </ShowForRoleComponent>
      </Menu.Item>
      <Menu.Item key="deleteProduct">
        <ShowForRoleComponent permissions={[Role.ADMIN]}>
          <Popconfirm
            title="Are you sure to delete this product ?"
            onConfirm={() => {
              deleteProduct(record);
            }}
          >
            <Space>
              <DeleteOutlined
                title="Delete"
                id={`deleteProduct-${record.id}`}
              />
              Delete product
            </Space>
          </Popconfirm>
        </ShowForRoleComponent>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div>
        <ShowForRoleComponent permissions={[Role.ADMIN]}>
          <Button
            id="addProduct"
            type="primary"
            className="common-button"
            onClick={() => {
              addProduct();
            }}
            title="Add Product"
            icon={<PlusOutlined />}
          >
            Add Product
          </Button>
        </ShowForRoleComponent>
      </div>

      <Table
        prefixCls="list-products"
        className="product-listing"
        components={{
          header: {
            cell: (props) => {
              return <th style={tableHeaderStyle}>{props.children}</th>;
            },
          },
        }}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              viewProduct(record);
            },
            style: {
              cursor: "pointer",
              "&:hover": {
                background: "black",
              },
            },
          };
        }}
        dataSource={dataSource?.result}
        pagination={{
          showTotal: (total, range) => (
            <TotalReocordsInListingComponent
              totalRecords={paging.totalRecords}
            />
          ),
          pageSize: paging.pageSize,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          totalRecords: paging.totalRecords,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
        }}
        columns={columns}
        rowKey={"id"}
        size="small"
      />
    </>
  );
};
export default ListProductsComponent;
