import React from "react";
import ListFeatureRolloutContainer from "../../Containers/ProductFeatureRollout/ListFeatureRolloutContainer";

const FeatureRolloutAddScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <ListFeatureRolloutContainer />
      </React.Fragment>
    </>
  );
};

export default FeatureRolloutAddScreen;
