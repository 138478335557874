import React, { useState } from "react";
import {
  Space,
  Input,
  Typography,
  InputNumber,
  DatePicker,
  Row,
  Col,
} from "antd";
import { MASKED_BUILD_VERSION } from "../../Constant/Common";
import dayjs from "dayjs";
import { isJson } from "../../Util/commonUtility";
import ErrorMessages from "../../Constant/strings/errors";
import ErrorToolTip from "./ErrorToolTip";
const { Text } = Typography;
const { RangePicker } = DatePicker;
const CommonRangeSelector = (props) => {
  const { initialValue, onTagChange, inputType, validationMessage, touched } =
    props;
  const setInitialValue = (values) => {
    if (isJson(values)) {
      const parsedArray = JSON.parse(values);
      if (Array.isArray(parsedArray)) {
        return parsedArray;
      }
    } else if (Array.isArray(values)) {
      return values;
    }
    return inputType === "Date" ? null : ["", ""];
  };
  const [values, setValues] = useState(setInitialValue(initialValue));

  const handleInputChange = (index, value) => {
    const newValues = [...values];
    newValues[index] = inputType === "Number" ? value : value;
    setValues(newValues);
    onTagChange(newValues);
  };
  const convertArrayDateToDayJsString = (data) => {
    if (Array.isArray(data) && data.length === 2) {
      const [from, to] = data.map(dayjs);
      return [from, to];
    }
  };
  const renderInput = (inputType) => {
    switch (inputType) {
      case "Number":
        return (
          <React.Fragment>
            {/* <Row>
              <Col>
                <Text type="secondary">
                  {ErrorMessages.QUERYBUILDER.HELP.NUMBER}
                </Text>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Space>
                  <InputNumber
                    value={values[0]}
                    onChange={(e) => handleInputChange(0, e)}
                  />
                  <span style={{ margin: "0 10px" }}>To</span>
                  <InputNumber
                    value={values[1]}
                    onChange={(e) => handleInputChange(1, e)}
                  />
                  {validationMessage && touched && (
                    <ErrorToolTip
                      title={ErrorMessages.QUERYBUILDER.RANGESELECTOR.NUMBER}
                    />
                  )}
                </Space>
              </Col>
            </Row>
          </React.Fragment>
        );
      case "Version":
        return (
          <React.Fragment>
            {/* <Row>
              <Col>
                <Text type="secondary">
                  {ErrorMessages.QUERYBUILDER.HELP.VERSION}
                </Text>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Space>
                  <Input
                    mask={MASKED_BUILD_VERSION}
                    value={values[0]}
                    onChange={(e) => handleInputChange(0, e.target.value)}
                  />
                  <span style={{ margin: "0 10px" }}>To</span>
                  <Input
                    mask={MASKED_BUILD_VERSION}
                    value={values[1]}
                    onChange={(e) => handleInputChange(1, e.target.value)}
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col  style={{display: 'flex', justifyContent: 'center'}}>
                {validationMessage && touched && (
                  <ErrorToolTip
                    title={ErrorMessages.QUERYBUILDER.RANGESELECTOR.VERSION}
                  />
                )}
              </Col>
            </Row>
          </React.Fragment>
        );
      case "Date":
        return (
          <RangePicker
            defaultValue={values ? convertArrayDateToDayJsString(values) : null}
            onChange={(date, dateString) => {
              onTagChange(dateString);
            }}
          />
        );
      default:
        break;
    }
  };
  return <div>{renderInput(inputType)}</div>;
};
export default CommonRangeSelector;
