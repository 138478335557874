import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import ListProductBuildRollOutComponent from "../../Components/BuildRollOut/listProductBuildRollOutComponent";
import {
  DefaultPagingValue,
  DefaultPagingValueForSearchableDropdown,
} from "../../Constant/Common";
import {
  convertDataToQueryParam,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import CommonTestMessage from "../../Components/Common/commonTestMessage";
import { message, Form } from "antd";
import NotificationService from "../../Services/notification";
import CommonFeatureCopy from "../../Components/Common/commonFeatureCopy";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../Hooks/useFetchData";

const ListBuildRollOutContainer = React.forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const navigate = useNavigate();
  const [productBuild, SetProductBuild] = useState([]);
  const [listFilterData, setListFilterData] = useState({});
  const [productTargets, setProductTargets] = useState([]);
  const [targetOperators, setTargetOperators] = useState([]);
  const [addEditBuildRollOut, setAddEditBuildRollOut] = useState({
    visible: false,
    data: undefined,
  });
  const [showTestMessage, setShowTestMessage] = useState({
    visible: false,
    message: undefined,
    title: undefined,
  });
  const [showCopyBuildRolloutModal, setShowCopyBuildRolloutModal] = useState({
    visible: false,
    data: undefined,
  });

  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.pageSize || 10,
    pageIndex: DefaultPagingValue.pageIndex || 1,
    totalPages: 0,
    totalRecords: 0,
  });

  useEffect(() => {
    fetchAllProductBuild();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize, productState]);

  useEffect(() => {
    if (addEditBuildRollOut.visible) fetchAllTargetOperators();
  }, [addEditBuildRollOut]);

  const editBuildRollOut = async (data) => {
    navigate(`/product/release/edit/${data.id}`);
  };
 
  const {
    data: productBuildRollout,
    loading: showLoading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_PRODUCT_BUILD_ROLLOUT.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    )
      .replace("#{productId}", productState?.selectedProduct?.id)
      .replace(
        "#{environmentId}",
        productState?.selectedEnvironment?.id
      )}?${convertDataToQueryParam(listFilterData)}&PageNumber=${
      pagination.pageIndex
    }&PageSize=${pagination.pageSize}`,
    [
      pagination.pageIndex,
      pagination.pageSize,
      listFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
    ]
  );

  // Update local pagination state when fetchedPagination changes
  useEffect(() => {
    if (fetchedPagination) {
      setPagination(fetchedPagination);
    }
  }, [fetchedPagination]);

  const fetchAllProductBuild = async () => {
    const response = await httpClient.get(
      `${Urls.TENANT_GET_ALL_PRODUCT_BUILDS.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace(
          "#{environmentId}",
          productState?.selectedEnvironment?.id
        )}?PageNumber=${
        DefaultPagingValueForSearchableDropdown.PAGE_INDEX
      }&PageSize=${DefaultPagingValueForSearchableDropdown.PAGE_SIZE}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      SetProductBuild(response?.data?.result);
    } else {
    }
  };

  const fetchAllProductTarget = async () => {
    const response = await httpClient.get(
      `${Urls.TENANT_GET_ALL_PRODUCT_TARGETS.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace(
          "#{environmentId}",
          productState?.selectedEnvironment?.id
        )}?PageNumber=${
        DefaultPagingValueForSearchableDropdown.PAGE_INDEX
      }&PageSize=${DefaultPagingValueForSearchableDropdown.PAGE_SIZE}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      setProductTargets(response?.data?.result);
    } else {
    }
  };

  const fetchAllTargetOperators = async () => {
    const response = await httpClient.get(
      `${Urls.LOOKUP_TARGET_TARGETOPERATORS}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      setTargetOperators(response?.data);
    } else {
    }
  };

  const deleteBuildRollOut = async (data) => {
    const response = await httpClient.delete(
      Urls.TENANT_DELETE_PRODUCT_BUILD_ROLLOUT.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{environmentId}", productState?.selectedEnvironment?.id)
        .replace("#{rolloutId}", data.id)
    );
    if (isSuccessStatus(response?.status)) {
      NotificationService.success("Success", " Release deleted successfully.");
      setListFilterData({}); // Reset filter to reflect changes
    } 
  };

  const testBuildRolloutByRolloutId = async (data) => {
    const response = await httpClient.get(
      `${Urls.TENANT_GET_BUILD_ROLLOUT_TEST.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{environmentId}", productState?.selectedEnvironment?.id)
        .replace("#{rolloutId}", data.id)}`
    );
    if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
      setShowTestMessage({
        visible: true,
        message: response?.data?.map((item) => item),
        title: "Release Hit",
      });
    } else {
    }
  };

  const fetchEnvs = () => {
    return productState?.selectedProduct?.environments?.map((item) => {
      return {
        id: item.id,
        value: item.id,
        label: item.name,
      };
    });
  };

  const fetchBuilds = () => {
    return productBuild?.map((build) => {
      return {
        id: build.id,
        value: build.id,
        label: `${build.name} ${build.version}`,
      };
    });
  };

  const copyReleaseUrl = async (id) => {
    const allEnvs = fetchEnvs();
    const allBuilds = fetchBuilds();
    setShowCopyBuildRolloutModal({
      visible: true,
      data: { id: id, productBuild: allBuilds, environments: allEnvs },
    });

    // setCopyUrl({
    //   visible: true,
    //   data: id,
    // });
  };

  const copyPatnerDownloadUrl = async (url) => {
    const input = document.createElement("input");
    input.value = url;
    document.body.appendChild(input);

    // Select and copy the URL
    input.select();
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(input);

    message.success("Partner download URL copied to clipboard!");
  };

  const copyFeatureRelease = async () => {
    const formData = form.getFieldValue();
    const response = await httpClient.post(
      `${Urls.TENANT_ADD_BUILD_ROLLOUT_COPIED_URL.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{environmentId}", productState?.selectedEnvironment?.id)
        .replace("#{rolloutId}", showCopyBuildRolloutModal?.data[0]?.id)}`,
      formData
    );
    if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
      setShowCopyBuildRolloutModal({
        ...showCopyBuildRolloutModal,
        visible: false,
      });
      NotificationService.success(
        "Success",
        "Please check respective Environment"
      );
      setListFilterData({}); // Reset filter to reflect changes
      form.resetFields();
    } else {
    }
  };

  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize: pageSize,
    }));
  };

  const onCloseModalRelease = () => {
    form.resetFields();
  };

  const listFilterSubmit = (data) => {
    setListFilterData(data);
    setPagination((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter change
  };

  const resetFilter = () => {
    setListFilterData({});
    setPagination((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter reset
  };

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <ListProductBuildRollOutComponent
          productBuildRollout={productBuildRollout}
          listFilterData={listFilterData}
          paging={pagination}
          copyPatnerDownloadUrl={(id) => copyPatnerDownloadUrl(id)}
          CopyFeatureById={(id) => copyReleaseUrl(id)}
          editBuildRolloutData={(data) => editBuildRollOut(data)}
          deleteProductBuildRollOut={(data) => deleteBuildRollOut(data)}
          testBuildRolloutByRolloutId={(data) =>
            testBuildRolloutByRolloutId(data)
          }
          handlePageChange={handlePageChange}
          listFilterSubmit={listFilterSubmit}
          resetFilter={resetFilter}
        />
      )}
      {showTestMessage && (
        <CommonTestMessage
          title={showTestMessage.title}
          visible={showTestMessage.visible}
          testMessage={showTestMessage.message}
          closeTestMessage={() => {
            setShowTestMessage({ visible: false, message: null });
          }}
        />
      )}
      {showCopyBuildRolloutModal && (
        <CommonFeatureCopy
          form={form}
          visible={showCopyBuildRolloutModal.visible}
          copyFeatureModalData={showCopyBuildRolloutModal.data}
          closeTestMessage={() => {
            setShowCopyBuildRolloutModal({ visible: false, data: null });
          }}
          copyFeatureApi={copyFeatureRelease}
          onCloseModalReset={onCloseModalRelease}
        />
      )}
    </>
  );
});

export default ListBuildRollOutContainer;
