import React from "react";
import AddContentTemplateContainer from "../../Containers/ContentTemplates/AddContentTemplatesContainer";

const ContentTemplatesAddScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <AddContentTemplateContainer />
      </React.Fragment>
    </>
  );
};

export default ContentTemplatesAddScreen;

