import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Space,
  Tag,
  Button,
  Input,
  Typography,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import { IP_ADDRESS_REGEX } from "../../Constant/Common";
import {
  handleKeyDownAllowOnlyNumber,
  isValidVersion,
} from "../../Util/commonUtility";
import ErrorMessages from "../../Constant/strings/errors";
import ErrorToolTip from "./ErrorToolTip";
const { Text } = Typography;
const CommonTagSelector = (props) => {
  const { initialValue, onTagChange, inputType, validationMessage } = props;
  const [selectedTag, setSelectedTag] = useState({ data: null, index: -1 });
  const [tagList, setTagList] = useState(
    Array.isArray(initialValue) ? initialValue : []
  );
  const [errorMessage, setErrorMessage] = useState("");
  // console.log(12, tagList,initialValue);
  // useEffect(()=>{
  //   if(initialValue){

  //   }
  // },[initialValue])
  const handleTagValueChange = (value) => {
    setSelectedTag({ ...selectedTag, data: value });
    setErrorMessage("");
  };

  const handleTagClose = (removedTag, index) => {
    const updatedTags = tagList.filter((tag, i) => i !== index);
    onTagChange(updatedTags);
    setTagList(updatedTags);
  };

  const handleTagClick = (clickedTag, index) => {
    setSelectedTag({ data: clickedTag, index: index });
  };

  const validateInput = (input, inputType) => {
    switch (inputType) {
      case "Version":
        if (!isValidVersion(input)) {
          return ErrorMessages.QUERYBUILDER.TAGSELECTOR.VERSION.ERROR;
        }
        break;
      default:
        break;
    }
    return null;
  };
  const handleTagValueOnBlur = (value, inputType) => {
    const errorMessage = validateInput(value, inputType);
    if (errorMessage) {
      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage("");
  };
  const handleTagAdd = () => {
    if (selectedTag?.data) {
      const errorMessage = validateInput(selectedTag?.data, inputType);
      if (errorMessage) {
        setErrorMessage(errorMessage);
        return;
      }
      const existingTagIndex = selectedTag.index;
      if (existingTagIndex !== -1) {
        const updatedTags = [...tagList];
        updatedTags[existingTagIndex] = selectedTag.data;
        setTagList(updatedTags);
        onTagChange(updatedTags);
      } else {
        // console.log(47, [...tagList, selectedTag.data]);
        setTagList([...tagList, selectedTag.data]);
        onTagChange([...tagList, selectedTag.data]);
      }

      setSelectedTag({ data: null, index: -1 });
      setErrorMessage("");
    }
  };
  const renderInput = (inputType) => {
    switch (inputType) {
      case "Version":
        return (
          <Space.Compact direction="vertical">
            <Input
              value={selectedTag?.data ? selectedTag.data : null}
              onChange={(e) => handleTagValueChange(e.target.value)}
              onBlur={(e) => handleTagValueOnBlur(e.target.value, inputType)}
              placeholder={ErrorMessages.QUERYBUILDER.VERSION}
            />
            {errorMessage && <ErrorToolTip title={errorMessage} />}
            {validationMessage && (
              <ErrorToolTip
                title={ErrorMessages.QUERYBUILDER.TAGSELECTOR.VERSION.ADD}
              />
            )}
          </Space.Compact>
        );
      case "Input":
        return (
          <Space.Compact direction="vertical">
            <Input
              value={selectedTag?.data ? selectedTag.data : null}
              onChange={(e) => handleTagValueChange(e.target.value)}
              placeholder={ErrorMessages.QUERYBUILDER.STRING}
            />
            {validationMessage && (
              <ErrorToolTip
                title={ErrorMessages.QUERYBUILDER.TAGSELECTOR.STRING}
              />
            )}
          </Space.Compact>
        );
      case "InputNumber":
        return (
          <Space.Compact direction="vertical">
            <Input
              value={selectedTag?.data ? selectedTag.data : null}
              onChange={(e) => handleTagValueChange(e.target.value)}
              onKeyDown={handleKeyDownAllowOnlyNumber}
              placeholder={ErrorMessages.QUERYBUILDER.NUMBER}
            />
            {validationMessage && (
              <ErrorToolTip
                title={ErrorMessages.QUERYBUILDER.TAGSELECTOR.NUMBER}
              />
            )}
          </Space.Compact>
        );
      case "Date":
        return (
          <Space.Compact direction="vertical">
            <DatePicker
              value={selectedTag.data ? dayjs(selectedTag.data) : null}
              onChange={(date, dateString) => handleTagValueChange(dateString)}
            />
            {validationMessage && (
              <ErrorToolTip
                title={ErrorMessages.QUERYBUILDER.TAGSELECTOR.DATE}
              />
            )}
          </Space.Compact>
        );
      default:
        break;
    }
  };

  return (
    <Space direction="horizontal">
      {renderInput(inputType)}
      <Button
        onClick={handleTagAdd}
        icon={<PlusOutlined />}
        title="Add"
      ></Button>
      <div>
        {tagList?.map((text, index) => (
          <Tag
            color="volcano"
            key={index}
            closable
            onClose={() => handleTagClose(text, index)}
            onClick={() => handleTagClick(text, index)}
          >
            {text}
          </Tag>
        ))}
      </div>
    </Space>
  );
};

export default CommonTagSelector;
