import React, { useEffect, useRef } from "react";
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import { isNullOrEmpty } from "../../Util/commonUtility";
import { inValidJson } from "../../Util/config";

const JsonEditor = ({
  jsonSchema,
  mode = "code",
  onChangeJSON = () => {},
  setCanSubmit,
}) => {
  const containerRef = useRef(null);
  const jsonEditorRef = useRef(null);

  useEffect(() => {
    const options = {
      onChange: () => {
        const errors = jsonEditorRef.current.validate();
        errors.then((result) => {
          if (result && result.length > 0) {
            setCanSubmit && setCanSubmit(inValidJson);
          }
        });
        mode === "view" && jsonEditorRef.current.expandAll();
        const updatedJSON = jsonEditorRef.current?.get();
        onChangeJSON(updatedJSON);
      },
      mode: mode,
      navigationBar: false,
    };
    if (!isNullOrEmpty(jsonSchema)) {
      jsonEditorRef.current = new JSONEditor(containerRef.current, options);
      jsonEditorRef.current.set(jsonSchema);
      mode === "view" && jsonEditorRef.current.expandAll();
    }
    return () => {
      if (jsonEditorRef.current) {
        jsonEditorRef.current.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonSchema, mode]);

  return <div className="jsoneditor-react-container" ref={containerRef} />;
};

export default JsonEditor;

