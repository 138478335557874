import { Form } from "antd";
import React, { useState } from "react";
import ChangePasswordForm from "../../Components/Settings/changePassword";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import { useSelector } from "react-redux";
import NotificationService from "../../Services/notification";
const ChangePasswordContainer = (props) => {
  const [changePassword] = Form.useForm();
  const [showLoading, SetShowLoading] = useState(false);
  const authState = useSelector((state) => state.auth);
  const changePasswordRequest = async (data) => {
    try {
      SetShowLoading(true);
      const response = await httpClient.put(
        Urls.ACCOUNT_CHANGEPASSWORD.replace(
          "#{accountId}", 
          authState?.accountData?.accountId
        ),
        data
      );
      if (isSuccessStatus(response?.status)) {
        changePassword.resetFields();
        NotificationService.success("Success", "Password change successfully");
      }
      SetShowLoading(false);
    } catch (error) {
      console.error("err", error);
    }
  };
  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <ChangePasswordForm
          formObject={changePassword}
          onFinish={(data) => {
            changePasswordRequest(data);
          }}
          backToListPage={() => {}}
        />
      )}
    </>
  );
};
export default ChangePasswordContainer;
