import { Tooltip } from "antd";

const ErrorToolTip = (props) => {
  return (
    <Tooltip
      placement={"bottom"}
      open={true}
      color="#ff1d16"
      zIndex={"1040"}
      overlayInnerStyle={{ color: "#fff" }}
      title={<span>{props.title}</span>}
    ></Tooltip>
  );
};
export default ErrorToolTip;
