import React, { useEffect, useState } from "react";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { DefaultPagingValueForSearchableDropdown } from "../../Constant/Common";
import { useSelector } from "react-redux";
import {
  checkValueOfEquIsValidOrNot,
  isNullOrEmpty,
  operatorArrayToKeyValueObjectWithRegex,
} from "../../Util/commonUtility";
import TargetCriteriaComponent from "../../Components/TargetCriteria";
import Loader from "../../Components/Common/Loader";
import { TARGET_ATTRIBUTE_TYPE } from "../../Constant/DropDown";
import NotificationService from "../../Services/notification";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setAllTargetOperatorsDetails,
  setAllTargetConditionsByType,
} from "../../Store/Reducers/Slices/LookUp/lookupSlice";
const TargetCriteria = (props) => {
  const { TargetCriteriaFinalValue, initialQuery } = props;
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [showLoading, setShowLoading] = useState(false);
  const [redirectToProductAttribute, setRedirectToProductAttribute] =
    useState(false);
  const [productAttributes, setProductAttributes] = useState(null);
  const [targetOperators, setTargetOperators] = useState([]);
  const [targetConditions, setTargetConditions] = useState([]);

  const { allTargetOperators } = useSelector((state) => state.lookup);
  const allTargetConditionsByType = useSelector(
    (state) => state.lookup.allTargetConditionsByType
  );
  const dispatch = useDispatch();
  let navigate = useNavigate();
  useEffect(() => {
    fetchAllTargetConditionsByType();
    fetchAllTargetAttributes();
    fetchAllTargetOperators();
  }, [productState]);

  useEffect(() => {
    if (allTargetOperators) {
      setTargetOperators(allTargetOperators);
    } else {
      fetchAllTargetOperators();
    }
  }, [allTargetOperators]);

  useEffect(() => {
    if (allTargetConditionsByType) {
      setTargetConditions(allTargetConditionsByType);
    } else {
      fetchAllTargetConditionsByType();
    }
  }, [allTargetConditionsByType]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowLoading(true);

        // Fetch all necessary data
        await Promise.all([
          // fetchAllTargetConditionsByType(),
          fetchAllTargetAttributes(),
          // fetchAllTargetOperators(),
        ]);

        setShowLoading(false);
      } catch (error) {
        setShowLoading(false);
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (redirectToProductAttribute) {
      navigate("/product/details");
      NotificationService.error(
        "Error",
        "No product target Attributes found. Please add product target attributes."
      );
    }
  }, [redirectToProductAttribute]);

  const SubmitFinalQuery = (data, json) => {
    TargetCriteriaFinalValue(data, json);
  };
  const fetchAllTargetOperators = async () => {
    const response = await httpClient.get(
      `${Urls.LOOKUP_TARGET_TARGETOPERATORS}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      dispatch(
        setAllTargetOperatorsDetails({
          allTargetOperators: response?.data?.map((item) => ({
            name: item,
            label: item,
          })),
        })
      );
    } else {
    }
  };
  const fetchAllTargetConditionsByType = async () => {
    const newDataMapping = {};
    const requests = TARGET_ATTRIBUTE_TYPE.map(async (type) => {
      const response = await httpClient.get(
        `${Urls.TENANT_TARGET_CONDITION.replace(
          "#{TargetAttributeType}",
          type.value
        )}`
      );
      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        newDataMapping[type.value] = operatorArrayToKeyValueObjectWithRegex(
          response?.data
        );
      }
    });

    await Promise.all(requests);
    dispatch(
      setAllTargetConditionsByType({
        allTargetConditionsByType: {
          targetConditions,
          ...newDataMapping,
        },
      })
    );
    // setTargetConditions({ targetConditions, ...newDataMapping });
  };
  const fetchAllTargetAttributes = async () => {
    setProductAttributes([]);
    const response = await httpClient.get(
      `${Urls.TENANT_GET_ALL_PRODUCT_TARGET_ATTRIBUTES.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      ).replace(
        "#{productId}",
        productState?.selectedProduct?.id
      )}?PageNumber=${
        DefaultPagingValueForSearchableDropdown.PAGE_INDEX
      }&PageSize=${DefaultPagingValueForSearchableDropdown.PAGE_SIZE}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      const createValidator = (operator) => (e) => {
        return checkValueOfEquIsValidOrNot(operator, e);
      };
      const data = response?.data?.result?.map((res) => ({
        ...res,
        touched: false,
        validator: createValidator(res.type),
      }));
      setProductAttributes(data);
    } else {
      setRedirectToProductAttribute(true);
    }
  };

  return (
    <>
      {showLoading && (
        <>
          <Loader />
        </>
      )}
      {productAttributes &&
        productAttributes.length > 0 &&
        targetConditions &&
        targetOperators && (
          <TargetCriteriaComponent
            initialQuery={initialQuery}
            targetOperators={targetOperators}
            targetConditions={targetConditions}
            SelectorDropdownArray={productAttributes}
            SubmitFinalQuery={(data, json) => SubmitFinalQuery(data, json)}
          />
        )}
    </>
  );
};

export default TargetCriteria;
