import { useEffect, useState } from "react";
import Loader from "../../Components/Common/Loader";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { useSelector } from "react-redux";
import {
  convertDataToQueryParam,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import { DefaultPagingValue } from "../../Constant/Common";
import { useNavigate } from "react-router-dom";
import NotificationService from "../../Services/notification";
import ListProductFeatureRolloutComponent from "../../Components/FeaturerRollout/listProductFeatureRolloutComponent";
import CopyProductFeatureRollout from "../../Components/FeaturerRollout/copyProductFeatureRolloutComponent";
import useFetchData from "../../Hooks/useFetchData";
const ListFeatureRolloutContainer = () => {
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [featureRolloutFilterData, setFeatureRolloutFilterData] = useState({});
  const [featureRolloutCopy, setFeatureRolloutCopy] = useState({
    visible: false,
    data: null,
  });
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.pageSize || 10,
    pageIndex: DefaultPagingValue.pageIndex || 1,
    totalPages: 0,
    totalRecords: 0,
  });

  // Fetch data using useFetchData
  const {
    data: productFeatureRollouts,
    loading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_PRODUCT_FEATURE_ROLLOUT.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    )
      .replace("#{productId}", productState?.selectedProduct?.id)
      .replace(
        "#{environmentId}",
        productState?.selectedEnvironment?.id
      )}?${convertDataToQueryParam(featureRolloutFilterData)}&PageNumber=${
      pagination.pageIndex
    }&PageSize=${pagination.pageSize}`,
    [
      pagination.pageIndex,
      pagination.pageSize,
      featureRolloutFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
    ]
  );

  // Update local pagination state when fetchedPagination changes
  useEffect(() => {
    if (fetchedPagination) {
      setPagination(fetchedPagination);
    }
  }, [fetchedPagination]);

  const copyFeatureRolloutHandle = async (data) => {
    const response = await httpClient.post(
      `${Urls.TENANT_COPY_PRODUCT_FEATURE_ROLLOUT.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{environmentId}", data?.environmentId)
        .replace("#{featureRolloutId}", data?.featureRolloutId)}`,
      data
    );

    if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
      setFeatureRolloutCopy({ data: undefined, visible: false });
      NotificationService.success(
        "Success",
        "Please check respective environment"
      );
      setFeatureRolloutFilterData({}); // Reset filter to reflect changes
    }
  };

  const deleteProductFeatureRollout = async (data) => {
    const response = await httpClient.delete(
      Urls.TENANT_DELETE_PRODUCT_FEATURE_ROLLOUT.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{featureRolloutId}", data.id)
        .replace("#{environmentId}", productState?.selectedEnvironment?.id)
    );
    if (isSuccessStatus(response?.status)) {
      NotificationService.success("Success", " Feature deleted successfully.");
      setFeatureRolloutFilterData({}); // Reset filter to reflect changes
    } 
  };

  const handlePageChange = (nextPage, pageSize) => {
    setPagination((prevPaging) => ({
      ...prevPaging,
      pageSize: pageSize,
      pageIndex: nextPage,
    }));
  };

  const editProductFeatureRollout = (data) => {
    navigate(`/product/featureRollout/edit/${data.id}`);
  };

  const featureRolloutFilterSubmit = (data) => {
    setFeatureRolloutFilterData(data);
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  const resetFilter = () => {
    setFeatureRolloutFilterData({});
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  const fetchEnvs = () => {
    return productState?.selectedProduct?.environments?.map((item) => {
      return {
        id: item.id,
        value: item.id,
        label: item.name,
      };
    });
  };
  const copyProductFeatureRollout = (data) => {
    const allEnvs = fetchEnvs();
    setFeatureRolloutCopy({
      visible: true,
      data: data,
      environments: allEnvs,
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ListProductFeatureRolloutComponent
          productFeatureRollouts={productFeatureRollouts}
          featureRolloutFilterData={featureRolloutFilterData}
          paging={pagination}
          editProductFeatureRollout={(data) => editProductFeatureRollout(data)}
          deleteProductFeatureRollout={(data) =>
            deleteProductFeatureRollout(data)
          }
          copyProductFeatureRollout={copyProductFeatureRollout}
          featureRolloutFilterSubmit={featureRolloutFilterSubmit}
          handlePageChange={handlePageChange}
          resetFilter={resetFilter}
        />
      )}
      {featureRolloutCopy.visible && (
        <CopyProductFeatureRollout
          visible={featureRolloutCopy.visible}
          data={featureRolloutCopy.data}
          environments={featureRolloutCopy.environments}
          closeCopyModal={() =>
            setFeatureRolloutCopy({ visible: false, data: undefined })
          }
          handleSubmit={(data) => {
            copyFeatureRolloutHandle(data);
          }}
        />
      )}
    </>
  );
};
export default ListFeatureRolloutContainer;

