import React from 'react';
import { Input } from 'antd';

const NumericInput = (props) => {
  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow only numbers and control keys (backspace, delete, etc.)
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    // Remove any non-numeric characters from the input value
    if (/[^0-9]/.test(value)) {
      event.target.value = value.replace(/[^0-9]/g, '');
    }
    // Call the provided onChange handler
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <Input
      {...props}
      onKeyPress={handleKeyPress}
      onChange={handleChange}
    />
  );
};

export default NumericInput;