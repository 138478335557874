import React from "react";
import AddBuildRollOutContainer from "../../Containers/BuildRollOut/AddBuildRollOutContainer";
const ReleaseAddScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <AddBuildRollOutContainer />
      </React.Fragment>
    </>
  );
};

export default ReleaseAddScreen;
