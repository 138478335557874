import React from "react";
import { Route, Routes } from "react-router-dom";
import FileListScreen from "./fileListScreen";
import FileAddScreen from "./fileAddScreen";
const FileRoute = (props) => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route index path="/" element={<FileListScreen />} />
          <Route index path="/add" element={<FileAddScreen />} />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default FileRoute;

