import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { Button, Col, Collapse, Divider, Form, Input, Popconfirm, Popover, Row, Select, Space, Table } from "antd";
import React, { useEffect } from "react";
import ShowDescriptionInListing from "../Common/showDescriptionInListing";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import config from "../../Util/config";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import CommonFilterForm from "../Common/commonFilterForm";

const ListProductBuildRollOutComponent = (props) => {
  const {
    productBuildRollout,
    paging,
    handlePageChange,
    deleteProductBuildRollOut,
    editBuildRolloutData,
    listFilterSubmit,
    resetFilter,
    listFilterData,
    copyPatnerDownloadUrl,
  } = props;
  const [listFilter] = Form.useForm();

  useEffect(() => {
    listFilter.setFieldsValue(listFilterData);
  }, [listFilterData, listFilter]);

  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
    },
    {
      name: "description",
      component: (
        <Input
          className="list-filter-common-style"
          placeholder="Description"
        />
      ),
    },
    {
      name: "isEnabled",
      component: (
        <Select
          className="list-filter-common-style select-target-min-width"
          placeholder="Is Enabled?"
        >
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      ),
    },
  ];
  
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <ShowDescriptionInListing title={text} text={text} />,
    },
    {
      title: "Enabled",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (isEnabled, row) => (isEnabled ? "Yes" : "NO"),
    },
    {
      title: "Download Url",
      dataIndex: "downloadUrl",
      key: "downloadUrl",
      render: (downloadUrl, row) => (
        <Popover content={downloadUrl} title="Url">
          <a href={downloadUrl}>
            <Button size="small" icon={<DownloadOutlined />}>
              Download
            </Button>
          </a>
        </Popover>
      ),
    },
    {
      title: "CI/CD Identifier",
      dataIndex: "cdCiIdentifier",
      key: "cdCiIdentifier",
      render: (cdCiIdentifier, row) => cdCiIdentifier,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space style={{ width: "200px" }}>
            {/* <ThunderboltOutlined
              title="Test"
              onClick={() => {
                testBuildRolloutByRolloutId(record);
              }}
            />
            <Divider type="vertical"></Divider> */}
            <CopyOutlined
              title="Copy partner download url"
              onClick={() => {
                copyPatnerDownloadUrl(
                  config.apiUrl.replace("/api/", "") + record.partnerDownloadUrl
                );
              }}
            />
            <Divider type="vertical"></Divider>
            <ShowForRightAccessComponent>
              <EditOutlined
                title="Edit"
                onClick={() => {
                  editBuildRolloutData(record);
                }}
              />
              <Divider type="vertical"></Divider>
            </ShowForRightAccessComponent>
            <ShowForRightAccessComponent>
              <Popconfirm
                title="Are you sure to delete this release ?"
                onConfirm={() => {
                  deleteProductBuildRollOut(record);
                }}
              >
                <DeleteOutlined title="Delete" />
              </Popconfirm>
            </ShowForRightAccessComponent>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={listFilterSubmit}
        onReset={resetFilter}
        initialValues={listFilterData}
      />
      <Divider />
      <Table
        dataSource={productBuildRollout?.result}
        pagination={{
          showTotal: (total, range) => (
            <TotalReocordsInListingComponent
              totalRecords={paging.totalRecords}
            />
          ),
          pageSize: paging.pageSize,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
        }}
        columns={columns}
        rowKey={"id"}
        size="small"
      />
    </>
  );
};

export default ListProductBuildRollOutComponent;
