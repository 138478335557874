import React from "react";
import AddUserContainer from "../../Containers/ProductDetails/AddUserContainer";

const UsersAddScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <AddUserContainer />
      </React.Fragment>
    </>
  );
};

export default UsersAddScreen;
