import React from "react";
import "./commonTestMessage.scss";

const TotalRecordsInListingComponent = (props) => {
  const { totalRecords } = props;
  return (
    <div className="total-records-container">
      <p className="total-records-text">Total Records :</p>
      <div className="total-records-number">
        {totalRecords}
      </div>
    </div>
  );
};

export default TotalRecordsInListingComponent;
