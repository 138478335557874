import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Select, Space, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import { PRODUCT_TYPE } from "../../Constant/DropDown";
import { EditOutlined } from "@ant-design/icons";
const AddProductComponent = (props) => {
  const { onFinish, onFinishFailed, backToListPage, editProductData } = props;
  const [addProductForm] = Form.useForm();
  return (
    <>
      <Divider
        className="divider-with-tag"
        orientation="left"
        orientationMargin={0}
      >
        <Tag className={"tag"} icon={<EditOutlined />}>
          {editProductData ? "Edit" : "Add"} Product
        </Tag>
      </Divider>
      <Form
        form={addProductForm}
        className="common-form-add-pro"
        name="add-product"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
      
        initialValues={editProductData}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item hidden={true} name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your name!",
            },
          ]}
        >
          <Input id="addProductName" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please input your description!",
            },
          ]}
        >
          <TextArea  id="addProductDescription" />
        </Form.Item>
        <Form.Item
          label={"Type"}
          name={"type"}
          rules={[
            {
              required: true,
              message: "Please select the type!",
            },
          ]}
          hidden={editProductData?.id ? true : false}
        >
          <Select id="productType" options={PRODUCT_TYPE}></Select>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Space className="btn_box"> 
            <Button
              type="default"
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                backToListPage();
              }}
            >
              Back
            </Button>
            <Button className="submit-btn" id="submitProduct" type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};
export default AddProductComponent;
