import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

export const LocalLoader = () => (
  <div className="flex justify-center h-full w-full p-[40%]">
    <Spin indicator={antIcon} />
  </div>
);

export default LocalLoader;

