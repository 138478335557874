import React from "react";
import { Route, Routes } from "react-router-dom";
import ProfileListScreen from "./profileListScreen";
import ProfileEditScreen from "./profileEditScreen";
const ProfileRoute = (props) => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route index path="/" element={<ProfileListScreen />} />
          <Route index path="/edit" element={<ProfileEditScreen />} />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default ProfileRoute;
