import React from "react";
import JsonEditor from "./jsonEditor";
import { validateCodeSyntax } from "../../Util/commonUtility";
import { ContentTypes, jsonEditorMode } from "../../Constant/Common";

const PreviewComponent = ({ content, height, language }) => {
  const { isValid, message } = validateCodeSyntax(content, language);
  const renderContent = () => {
    if (language === ContentTypes.JSON) {
      try {
        const parsedContent = JSON.parse(content);
        return (
          <div style={{ height }}>
            <JsonEditor jsonSchema={parsedContent} mode={jsonEditorMode} />
          </div>
        );
      } catch (error) {
        return <div>Error parsing JSON content: {error.message}</div>;
      }
    } else {
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }
  };

  return <div>{isValid ? renderContent() : <div>{message}</div>}</div>;
};

export default PreviewComponent;

