import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { httpClient, isSuccessStatus } from "../../Api/client";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import AddProductUserComponent from "../../Components/ProductUsers/AddProductUserComponent";
import { isNullOrEmpty } from "../../Util/commonUtility";
import { DefaultPagingValueForSearchableDropdown } from "../../Constant/Common";
import NotificationService from "../../Services/notification";

const AddUserContainer = () => {
  const [showLoading, setShowLoading] = useState(false);
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [allProductEnvironmentsData, setAllProductEnvironmentsData] =
    useState(false);
  const [editProductUsersData, setEditProductUsersData] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const [productUsers, setProductUsers] = useState([]);
  const navigate = useNavigate();
  let { id } = useParams();
  const submitAddUserForm = async (data, action = "Add") => {
    setShowLoading(true);
    let prepData = [];
    data.environment?.forEach((dto) => {
      if (dto?.environment && dto?.environmentId) {
        prepData.push({
          environmentId: dto?.environmentId,
          accountId: data.accountId,
          isFullAccess: dto?.isFullAccess ? dto?.isFullAccess : false,
        });
      }
    });
    if (action === "Edit") {
      const response = await httpClient.put(
        `${Urls.TENANT_UPDATE_PRODUCT_USER.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{userId}", data.accountId)}`,
        prepData
      );
      if (isSuccessStatus(response?.status)) {
        NotificationService.success("Success", "Product User edit successfully");
        backToListPage();
      } 
    } else {
      const response = await httpClient.post(
        Urls.TENANT_ADD_PRODUCT_USERS.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        ).replace("#{productId}", productState?.selectedProduct?.id),
        prepData
      );
      if (isSuccessStatus(response?.status)) {
        backToListPage();NotificationService.success("Success", "Product User added successfully");
      } 
    }
    setShowLoading(false);
  };
  const backToListPage = () => {
    navigate("/product/users");
  };
  useEffect(() => {
    const fetchUserWithId = async (id) => {
      setShowLoading(true);
      const response = await httpClient.get(
        Urls.TENANT_GET_PRODUCT_USER_BY_ID.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{userId}", id)
      );
      if (isSuccessStatus(response?.status)) { 
        setEditProductUsersData(response.data);
      } else {
        backToListPage();
      }
      setShowLoading(false);
    };
    if (id) fetchUserWithId(id);
    getAllUsersByTenantId();
    getAllProductEnvironmentById();
    getAllProductUsers();
  }, [id, productState, authState]);

  useEffect(() => {
    if (allUser && productUsers) {
      const filteredArray1 = allUser.filter((item1) => {
        const foundInArray2 = productUsers.some((item2) => {
          return item1.id === item2.accountId && item1.email === item2.email;
        });
        return !foundInArray2;
      });
      setAllUser(filteredArray1);
    }
  }, [productUsers]);
  const getAllUsersByTenantId = async () => {
    setShowLoading(true);
    const response = await httpClient.get(
      `${Urls.TENANT_GET_ALL_TENANTS_USERS.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )}?PageSize=${DefaultPagingValueForSearchableDropdown.PAGE_SIZE}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      setAllUser(response?.data.result);
    } else {
    }
    setShowLoading(false);
  };

  // const getProductUserById = async (data) => {
  //   setShowLoading(true);
  //   const response = await httpClient.get(
  //     `${Urls.TENANT_GET_PRODUCT_USER_BY_ID.replace(
  //       "#{tenantId}",
  //       authState?.accountData?.tenantId
  //     )
  //       .replace("#{productId}", productState?.selectedProduct?.id)
  //       .replace("#{userId}", data.accountId)}`
  //   );
  //   if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
  //     setEditProductUsersData(response.data);
  //   } else {
  //   }
  //   setShowLoading(false);
  // };
  const getAllProductEnvironmentById = async () => {
    setShowLoading(true);
    const response = await httpClient.get(
      `${Urls.TENANT_GET_ALL_PRODUCT_ENVIRONMENTS.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      ).replace("#{productId}", productState?.selectedProduct?.id)}`
    );
    if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
      setAllProductEnvironmentsData(response.data);
    } else {
    }
    setShowLoading(false);
  };
  const getAllProductUsers = async () => {
    setShowLoading(true);
    const response = await httpClient.get(
      `${Urls.TENANT_GET_ALL_PRODUCT_USERS.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      ).replace("#{productId}", productState?.selectedProduct?.id)}?PageSize=${
        DefaultPagingValueForSearchableDropdown.PAGE_SIZE
      }`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      setProductUsers(response?.data.result);
    } else {
    }
    setShowLoading(false);
  };
  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <AddProductUserComponent
          editProductUsersData={editProductUsersData}
          allUser={allUser}
          allowedEnvironments={productState?.selectedProduct?.environments}
          allProductEnvironmentsData={allProductEnvironmentsData}
          onFinish={(data, action) => {
            submitAddUserForm(data, action);
          }}
          backToListPage={backToListPage}
        />
      )}
    </>
  );
};
export default AddUserContainer;
