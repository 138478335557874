import { Card, Col, Divider, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { isNullOrEmpty } from "../../Util/commonUtility";
import Loader from "../../Components/Common/Loader";
import { useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const ProfileContainer = (props) => {
  const [showLoading, setShowLoading] = useState(false);
  const [profileData, setProfieData] = useState(false);
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  useEffect(() => {
    const getProfileRequest = async () => {
      try {
        setShowLoading(true);
        const response = await httpClient.get(
          Urls.ACCOUNT_PROFILE.replace(
            "#{accountId}",
            authState?.accountData?.accountId
          )
        );
        if (
          !isNullOrEmpty(response) &&
          !isNullOrEmpty(response?.data) &&
          isSuccessStatus(response?.status)
        ) {
          setProfieData(response.data);
        }
        setShowLoading(false);
      } catch (error) {
        console.error("err", error);
      }
    };
    getProfileRequest();
  }, [authState]);

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <Card
          extra={
            <EditOutlined
              id="editProfile"
              onClick={() => {
                navigate("/settings/profile/edit");
              }}
            />
          }
          className="custom-card"
          title="Profile Info"
        >
          <Row className="row-container">
            <Col lg={7 } md={5}    sm={6} xs={12}>
              <div className="label-wapper">
                <label> Username</label>
                <p>{profileData.name}</p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={8} md={9} sm={8}xs={12}>
              <div className="label-wapper">
                <label> Email</label>
                <p>{profileData.email} </p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={5} md={6 } sm={6}xs={24}>
              <div className="label-wapper">
                <label> Phone Number</label>
                <p>{profileData.phone}</p>
              </div>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};
export default ProfileContainer;
