import React from "react";
import AddFeatureFlagContainer from "../../Containers/ProductFeatureFlag/AddFeatureFlagContainer";

const FeatureFlagAddScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <AddFeatureFlagContainer />
      </React.Fragment>
    </>
  );
};

export default FeatureFlagAddScreen;
