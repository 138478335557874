import React from "react";
import ListBuildRollOutContainer from "../../Containers/BuildRollOut/ListBuildRollOutContainer";

const ReleaseListScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <ListBuildRollOutContainer />
      </React.Fragment>
    </>
  );
};

export default ReleaseListScreen;
