import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import CommonFilterForm from "../Common/commonFilterForm";
import { ENABLE_OPTIONS } from "../../Constant/Common";
import { Option } from "antd/es/mentions";

const ListContentRolloutsComponent = (props) => {
  const {
    editContentRolloutData,
    deleteContentRollout,
    allContentTemplates,
    allContentRollouts,
    ContentRollouts,
    productBuildArray,
    listFilterSubmit,
    handlePageChange,
    listFilterData,
    resetFilter,
    paging,
    productState,
    onFinish,
  } = props;
  const [listFilter] = Form.useForm();
  // const Navigate = useNavigate();
  const [contentTemplateCopyForm] = Form.useForm();
  const [selectedContentRollout, setSelectedContentRollout] = useState();
  const [copiedRolloutName, setCopiedRolloutName] = useState();
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const allEnvironments = productState?.selectedProduct?.environments;
  const selectedEnvironment = productState?.selectedEnvironment?.id;

  useEffect(() => {
    listFilter.setFieldsValue(listFilterData);
  }, [listFilterData, listFilter]);

  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
    },
    {
      name: "ContentTemplateName",
      component: (
        <Select
          allowClear
          id="templateId"
          placeholder="Content Template Name"
          className="list-filter-common-style"
        >
          {allContentTemplates?.map((template) => (
            <Select.Option key={template.id} value={template.name}>
              {template.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: "BuildName",
      component: (
        <Select
          allowClear
          id="selectBuildVersion"
          placeholder="Build Name"
          className="list-filter-common-style"
        >
          {productBuildArray?.map((build, index) => (
            <Select.Option key={build.id} value={build.name}>
              {build.name} {`( ${build.version} )`}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: "IsEnable",
      component: (
        <Select
          allowClear
          className="list-filter-common-style select-target-min-width"
          placeholder="Is Enable?"
        >
          {ENABLE_OPTIONS?.map(({ label, value }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      ),
    },
  ];

  // Updated table columns based on the provided object structure
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Template Name",
      dataIndex: "contentTemplateName",
      key: "contentTemplateName",
    },
    {
      title: "Template Type",
      dataIndex: "templateType",
      key: "templateType",
    },
    {
      title: "Build Name",
      dataIndex: "buildName",
      key: "buildName",
    },
    {
      title: "Enabled",
      dataIndex: "isEnable",
      key: "isEnable",
      render: (isEnable) => (isEnable ? "Yes" : "No"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space style={{ width: "200px" }}>
            <ShowForRightAccessComponent>
              <CopyOutlined
                title="Copy"
                onClick={() => {
                  setSelectedContentRollout(record?.id);
                  setCopiedRolloutName(`${record.name}_Copy`);
                  setIsCopyModalOpen(true);
                }}
              />
              <Divider type="vertical" />
            </ShowForRightAccessComponent>
            <ShowForRightAccessComponent>
              <EditOutlined
                title="Edit"
                onClick={() => {
                  editContentRolloutData(record);
                }}
              />
              <Divider type="vertical" />
            </ShowForRightAccessComponent>
            <ShowForRightAccessComponent>
              <Popconfirm
                title="Are you sure to delete this content rollout?"
                onConfirm={() => {
                  deleteContentRollout(record);
                }}
              >
                <DeleteOutlined title="Delete" />
              </Popconfirm>
            </ShowForRightAccessComponent>
          </Space>
        );
      },
    },
  ];

  const handleTemplateChange = (value) => {
    const contentTemplateData = allContentRollouts.find(
      (obj) => obj.id === value
    );
    setCopiedRolloutName(`${contentTemplateData.name}_Copy`);
    contentTemplateCopyForm.setFieldValue(
      "name",
      `${contentTemplateData.name}_Copy`
    );
  };

  const handleCopyFormFinish = (values) => {
    const contentTemplateData = {
      ...values,
    };
    onFinish(
      contentTemplateData,
      contentTemplateCopyForm,
      setSelectedContentRollout,
      setCopiedRolloutName,
      setIsCopyModalOpen
    );
  };
  const handleCancel = () => {
    contentTemplateCopyForm.resetFields();
    setSelectedContentRollout();
    setCopiedRolloutName();
    setIsCopyModalOpen(false);
  };

  return (
    <>
      <Modal open={isCopyModalOpen} footer={false} onCancel={handleCancel}>
        <Form onFinish={handleCopyFormFinish} form={contentTemplateCopyForm}>
          <div>
            <label>Copy From Rollout</label>

            <Form.Item name="rolloutId" initialValue={selectedContentRollout}>
              <Select
                placeholder="Select content rollout to copy"
                onChange={handleTemplateChange}
                value={selectedContentRollout}
              >
                {allContentRollouts?.map((rollout) => (
                  <Option key={rollout.id} value={rollout.id}>
                    {rollout.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <label>Select Environment</label>

            <Form.Item name="environmentId" initialValue={selectedEnvironment}>
              <Select
                placeholder="Select Environment"
                value={selectedEnvironment}
              >
                {allEnvironments &&
                  allEnvironments?.map((environment) => (
                    <Option key={environment.id} value={environment.id}>
                      {environment.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>

          <div>
            <label>Name</label>
            <Form.Item
              name="name"
              initialValue={copiedRolloutName}
              rules={[
                { required: true, message: "Please input rollout name!" },
              ]}
            >
              <Input
                id="ContentRolloutName"
                placeholder="Enter Content Rollout Name"
                value={copiedRolloutName}
              />
            </Form.Item>
          </div>
          <Form.Item prefixCls="content-rollout-copy-submit">
            <Row gutter={10}>
              <Col>
                <Button
                  id="ContentCopyRolloutBackButton"
                  type="default"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  id="ContentCopyRolloutSubmitButton"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={listFilterSubmit}
        onReset={resetFilter}
        initialValues={listFilterData}
      />
      <Divider />
      <Table
        dataSource={ContentRollouts?.result}
        pagination={{
          showTotal: (total, range) => (
            <TotalReocordsInListingComponent
              totalRecords={paging.totalRecords}
            />
          ),
          pageSize: paging.pageSize,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
        }}
        columns={columns}
        rowKey={"id"}
        size="small"
      />
    </>
  );
};

export default ListContentRolloutsComponent;

