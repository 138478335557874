import {
  ArrowLeftOutlined,
  DownloadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Popover,
  Row,
  Space,
  Switch,
} from "antd";
import Loader from "../Common/Loader";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import {
  fromKeyValueArrayToObject,
  fromObjectToKeyValueArray,
  isValidVersion,
} from "../../Util/commonUtility";
import ErrorMessages from "../../Constant/strings/errors";
const AddProductBuildComponent = (props) => {
  const { id, onFinish, onFinishFailed, backToListPage, editProductBuildData } =
    props;
  const [addProductBuildForm] = Form.useForm();
  const [initialFormValues, SetInitialFormValues] = useState();
  useEffect(() => {
    if (id) {
      SetInitialFormValues(editProductBuildData);
    } else {
      SetInitialFormValues({ description: null });
    }
  }, []);
  return (
    <>
      {editProductBuildData && id && (
        <Card className="custom-card" title="Build Info">
          <Row className="row-container">
            <Col lg={5} md={8} sm={12} xs={24}>
              <div className="label-wapper">
                <label> Build Name / Version</label>
                <p>
                  {editProductBuildData.name} ( # {editProductBuildData.version}{" "}
                  )
                </p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={6} md={8} sm={12} xs={24}>
              <div className="label-wapper">
                <label> Description</label>
                <p>{editProductBuildData.description}</p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={5} md={8} sm={12} xs={24}>
              <div className="label-wapper">
                <label> Published Date</label>
                <p>
                  {new Date(editProductBuildData.publishedDate).toDateString()}
                </p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={5} md={8} sm={12} xs={24}>
              <div className="label-wapper">
                <label> CdCiIdentifier / URL</label>
                <p>{editProductBuildData.cdCiIdentifier}</p>
                <p>
                  <Popover
                    content={editProductBuildData.downloadUrl}
                    title="Url"
                  >
                    <a href={editProductBuildData.downloadUrl}>
                      <Button size="small" icon={<DownloadOutlined />}>
                        Download
                      </Button>
                    </a>
                  </Popover>
                  {}
                </p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={6} md={8} sm={12} xs={24}>
              <div className="label-wapper">
                <label> Meta Data </label>
                <p className="discription">
                  {fromObjectToKeyValueArray(
                    editProductBuildData?.buildMeta
                  ).map((a) => {
                    return (
                      <Space key={a.key}>
                        {a.key} : {a.value}
                      </Space>
                    );
                  })}
                </p>
              </div>
            </Col>
          </Row>
        </Card>
      )}

      {initialFormValues ? (
        <Form
          colon={false}
          form={addProductBuildForm}
          className="common-form"
          name="add-build"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={editProductBuildData}
          onFinish={(values) => {
            values.buildMeta = fromKeyValueArrayToObject(values.buildMeta);
            onFinish(values);
          }}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item hidden={true} name="id">
            <Input id="buildAddInput" />
          </Form.Item>
          {!id && (
            <>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please input build name!",
                  },
                ]}
              >
                <Input id="buildName" />
              </Form.Item>
              <Form.Item
                name="version"
                label="Version"
                rules={[
                  {
                    required: true,
                    message: "Please input build version!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !isValidVersion(value)) {
                        return Promise.reject(
                          `${ErrorMessages.QUERYBUILDER.TAGSELECTOR.VERSION.ERROR}`
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input id="buildVersion" />
              </Form.Item>
              <Form.Item
                name="downloadUrl"
                label="Download Url"
                rules={[
                  {
                    required: true,
                    message: "Please input build download url!",
                  },
                  {
                    type: "url",
                    message: "Please input valid build download url!",
                  },
                ]}
              >
                <Input id="buildDownloadUrl" />
              </Form.Item>
              <Form.Item
                label="Metadata"
                name="metaData" // No need for validation here as it's handled in Form.List
                required
              >
                <Form.List
                  name="buildMeta"
                  rules={[
                    {
                      validator: async (_, metaData) => {
                        if (!metaData || metaData.length < 1) {
                          return Promise.reject(
                            new Error("Please add at least 1 metadata")
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row key={key}>
                          <Col>
                            <Space align="baseline">
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                className="build-target-max-width"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input key",
                                  },
                                ]}
                              >
                                <Input
                                  id="inputKeyOfTarget"
                                  placeholder="key"
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                className="build-target-max-width"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input value",
                                  },
                                ]}
                              >
                                <Input
                                  id="inputValueOfTarget"
                                  placeholder="Value"
                                />
                              </Form.Item>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Space>
                          </Col>
                        </Row>
                      ))}

                      {/* Add Metadata Button */}
                      <Button
                        id="addMetaDataInRelease"
                        type="dashed"
                        onClick={() => add()}
                        block
                        size="small"
                        icon={<PlusOutlined />}
                        className={errors.length > 0 ? "button-danger" : ""}
                      >
                        Add Metadata
                      </Button>

                      {/* Error List */}
                      <Form.ErrorList errors={errors} />
                    </>
                  )}
                </Form.List>
              </Form.Item>
              {/* <Form.Item
                name="cdCiIdentifier"
                label="Cd Ci Identifier"
                rules={[
                  {
                    required: true,
                    message: "Please input build  cd ci identifier!",
                  },
                ]}
              >
                <Input id="buildCdCiIdentifier" />
              </Form.Item> */}
            </>
          )}
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input build description!",
              },
            ]}
          >
            <TextArea id="buildDescription" />
          </Form.Item>
          <Form.Item
            label="Enabled ?"
            name={"isEnabled"}
            valuePropName="checked"
          >
            <Switch id="buildSwitch" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Space>
              <Button
                id="backToBuildList"
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  backToListPage();
                }}
              >
                Back
              </Button>
              <Button
                className="submit-btn"
                id="submitBuild"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  );
};
export default AddProductBuildComponent;
