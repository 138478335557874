import React, { Fragment, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Notfound from "../Screens/ErrorPages/notFound";
import NotAuthorized from "../Screens/ErrorPages/notAuthorize";
import PrivateRoute from "./PrivateRoute";
import MainLayout from "../Components/Layout/mainLayout";
import { matchPath } from "react-router";
import LoginScreen from "../Screens/Auth/login";
import Setting from "../Screens/Settings";
import ProductsRoutes from "../Screens/Products";
import TenantUsersRoutes from "../Screens/TenantUsers";
import ProductInfoRoutes from "../Screens/ProductInfo";
import { accountLogout } from "../Store/Reducers/Slices/Auth/authSlice";
import { resetLookupSliceData } from "../Store/Reducers/Slices/LookUp/lookupSlice";
import { useDispatch } from "react-redux";
// import JsonEditorViewerFormBuilder from "../Screens/JsonEditorViewerFormBuilder";

const AppRoute = ({ isDarkMode, toggleTheme }) => {
  const match = useLocation();
  const dispatch = useDispatch();
  const checkResetPasswordToken = matchPath(
    { path: "/resetPassword/:token", exact: true, strict: true },
    match.pathname
  );
  useEffect(() => {
    if (match.pathname === "/logout") {
      dispatch(accountLogout());
      dispatch(resetLookupSliceData());
      window.location.href = "/login";
    }
  }, [match]);
  return (
    <>
      <Fragment>
        {match.pathname === "/login" ||
        match.pathname === "/logout" ||
        match.pathname === "/sendLink" ||
        checkResetPasswordToken ? (
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/logout" element={<LoginScreen />} />
          </Routes>
        ) : (
          <MainLayout isDarkMode={isDarkMode} onToggle={toggleTheme}>
            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                <Route index path="/" element={<ProductsRoutes />} />
                <Route index path="dashboard" element={<ProductsRoutes />} />
                <Route path="products/*" element={<ProductsRoutes />} />
                <Route path="tenant-users/*" element={<TenantUsersRoutes />} />
                <Route index path="product/*" element={<ProductInfoRoutes />} />
                <Route index path="settings/*" element={<Setting />} />
              </Route>
              <Route path="*" element={<Notfound />} />
              <Route path="unauthorized" element={<NotAuthorized />} />
            </Routes>
          </MainLayout>
        )}
      </Fragment>
    </>
  );
};

export default AppRoute;

