import { Card, Col, Divider, Row, Tag } from "antd";
import { colorCodeBasedOnEnvironemnt } from "../../Util/commonUtility";
import Loader from "../Common/Loader";

const ProductDetails = (props) => {
  const { data } = props;
  const items = [
    {
      key: "1",
      label: "Product Name",
      children: data.name,
      span: 3,
    },
    {
      key: "2",
      label: "Type",
      children: data.type,
      span: 3,
    },
    {
      key: "3",
      label: "Code",
      children: data.code,
      span: 3,
    },
    {
      key: "4",
      label: "Status",
      span: 3,

      children: (
        <>
          {data.environments?.map((env, index) => {
            return (
              <Tag
                key={index}
                color={colorCodeBasedOnEnvironemnt(env.name.toLowerCase())}
              >
                {env.name.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      key: "5",
      label: "Description",
      children: data.description,
    },
  ];

  return (
    <>
      {data ? (
        <Card className="custom-card" title="Product Info">
          <Row className="row-container">
            <Col lg={4} md={6 } sm={10} xs={24}>
              <div className="label-wapper">
                <label> Product Name</label>
                <p>{data.name}</p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={4} md={6 } sm={7} xs={24}>
              <div className="label-wapper">
                <label> Type</label>
                <p>{data.type}</p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={4} md={6 } sm={7} xs={24}>
              <div className="label-wapper">
                <label> Code</label>
                <p>{data.code}</p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={4} md={8 } sm={12} xs={24}>
              <div className="label-wapper">
                <label> Environments</label>
                <div className="text-label">
                {data.environments?.map((env, index) => {
                  return (
                    <Tag
                      key={index}
                      className="common-tag-color"
                      // className="tags"
                      // color={colorCodeBasedOnEnvironemnt(
                      //   env.name.toLowerCase()
                      // )}
                    >
                      {env.name.toUpperCase()}
                    </Tag>
                  );
                })}

                </div>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={5} md={8} sm={12} xs={24}>
              <div className="label-wapper">
                <label> Description</label>
                <p className="discription">{data.description}</p>
              </div>
            </Col>
          </Row>
        </Card>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default ProductDetails;
