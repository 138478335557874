import { Form, Input, Modal, Select } from "antd";

const CopyProductFeatureRollout = (props) => {
  const { visible, data, closeCopyModal, handleSubmit, environments } = props;
  const [featureRolloutCopyForm] = Form.useForm();
  return (
    <Modal
      className="modal-testMessage"
      open={visible}
      onOk={featureRolloutCopyForm.submit}
      onCancel={closeCopyModal}
      title="Copy Feature Rollout"
    >
      <Form
        form={featureRolloutCopyForm}
        onFinish={handleSubmit}
        initialValues={{ featureRolloutId: data.id }}
        layout="vertical"
      >
        <Form.Item
          label="Environment"
          name="environmentId"
          rules={[
            {
              required: true,
              message: "Please select any Environment!",
            },
          ]}
        >
          <Select
            className="select-items-copy-feature"
            placeholder="Select Environment"
            options={environments}
          />
        </Form.Item>
        <Form.Item name={"featureRolloutId"} hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Feature Rollout Name"
          rules={[
            {
              required: true,
              message: "Please enter feature rollout name!",
            },
          ]}
        >
          <Input placeholder="Enter feature rollout name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CopyProductFeatureRollout;
