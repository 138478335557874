import { Route, Routes } from "react-router-dom";
import ListProductsScreen from "./ListProducts";
import AddProductsScreen from "./AddProducts";
import RestrictedRoute from "../../Routes/RestrictedRoute";
import { Role } from "../../Constant/Common";
import React from "react";

const ProductsRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route index path="/" element={<ListProductsScreen />} />
        <Route
          exact
          path="/"
          element={<RestrictedRoute requiredRole={[Role.ADMIN]} />}
        >
          <Route exact path="/add" element={<AddProductsScreen />} />
          <Route exact path="/edit/:id" element={<AddProductsScreen />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default ProductsRoutes;
