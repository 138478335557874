import React from "react";
import { Route, Routes } from "react-router-dom";
import TargetAttributesListScreen from "./TargetAttributesListScreen";
import TargetAttributesAddScreen from "./TargetAttributesAddScreen";

const TargetAttributesRoute = React.forwardRef((props, ref) => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route
            index
            path="/"
            element={<TargetAttributesListScreen/>}
          />
          <Route index path="/add" element={<TargetAttributesAddScreen />} />
          <Route
            index
            path="/edit/:id"
            element={<TargetAttributesAddScreen />}
          />
        </Routes>
      </React.Fragment>
    </>
  );
});

export default TargetAttributesRoute;
