import {  PlusOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, Card, Popconfirm, Space, Table, Tag } from "antd";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";

const TenantUserListing = (props) => {
  const {
    dataSource,
    addTenantUser,
    resetPassword,
    paging,
    handlePageChange,
  } = props;
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
    },
    {
      title: "Roles",
      dataIndex: "roles",
      render: (role, record) => {
        return role.map((role, index) => {
          return (
            <Tag key={index} className="common-tag-color">
              {role}
            </Tag>
          );
        });
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (text, record) => {
        return (
          <Space.Compact block>
            <Popconfirm
              title={`Are you sure to reset password for tenant user - "${record.name}" ?`}
              onConfirm={() => {
                resetPassword(record.id, record.email);
              }}
            >
              <RedoOutlined
                id={`resetTenantUser${record.id}`}
                title="Reset Tenant User Password"
              />
            </Popconfirm>
          </Space.Compact>
        );
      },
    },
  ];

  return (
    <>
      <Card
        title={"Tenant Users"}
        extra={
          <Button
            id="addTenantUser"
            onClick={() => {
              addTenantUser();
            }}
            type="primary"
            icon={<PlusOutlined />}
            title="Add Tenant User"
          >
            Add Tenant User
          </Button>
        }
      >
        <Table
          rowKey={"id"}
          dataSource={dataSource?.result}
          pagination={{
            showTotal: (total, range) => (
              <TotalReocordsInListingComponent
                totalRecords={paging.totalRecords}
              />
            ),
            pageSize: paging.pageSize,
            total: paging.totalPages * paging.pageSize,
            current: paging.pageIndex,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
          }}
          columns={columns}
          size="small"
        />
      </Card>
    </>
  );
};
export default TenantUserListing;
