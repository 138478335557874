import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setProductDetails,
  setSelectedEnvironmentDetails,
} from "../../Store/Reducers/Slices/Product/productSlice";
import { Breadcrumb, Button, Divider, Input, List, Tag, Tooltip } from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import {
  getLocalStorage,
  getProductIcon,
  THEME_KEY,
} from "../../Util/commonUtility";

const BreadCrumb = ({ breadCrumbItems, defaultValue, selection }) => {
  const dispatch = useDispatch();
  const panelRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const productState = useSelector((state) => state.product);
  const [selectedProduct, setSelectedProduct] = useState(
    productState?.selectedProduct
  );
  const isDarkMode = getLocalStorage(THEME_KEY) || false;
  const [updatedItems, setUpdatedItems] = useState({
    selectedProduct: defaultValue,
    selectedEnvironment: productState?.selectedEnvironment?.name,
  });
  const [selectedEnvironment, setSelectedEnvironment] = useState(
    productState?.selectedEnvironment
  );
  const productsArray = selection?.map((product) => {
    return {
      name: product.name,
      type: product.type,
      id: product.id,
      environments: product.environments,
    };
  });

  const [filteredProductList, setFilteredProductList] = useState([]);

  const handleMenuClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleDone = () => {
    setDropdownVisible(false);
    const updatedBreadCrumbItems = {
      selectedProduct: selectedProduct.name,
      selectedEnvironment: selectedEnvironment?.name,
    };
    setUpdatedItems(updatedBreadCrumbItems);

    dispatch(
      setProductDetails({
        selectedProduct: selectedProduct,
        allProducts: productState?.allProducts,
      })
    );

    if (selectedEnvironment?.id !== productState?.selectedEnvironment?.id) {
      dispatch(
        setSelectedEnvironmentDetails({
          selectedProductId: selectedProduct?.id,
          selectedEnvironmentId: selectedEnvironment?.id,
        })
      );
    }
  };

  const handleClickOutside = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const handleProductSelect = (product) => {
    if (product.environments.length === 1) {
      setSelectedEnvironment(product.environments[0]);
    } else {
      setSelectedEnvironment(product?.environments[0]);
    }
    setSelectedProduct(product);
  };

  const handleEnvironmentSelect = (environment) => {
    setSelectedEnvironment(environment);
  };

  const handleSearch = (e) => {
    const filteredList = productsArray?.filter((product) => {
      return product.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setFilteredProductList(filteredList);
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownVisible]);

  return (
    <div>
      {breadCrumbItems?.map((breadcrumb) => {
        const AddBreadCrumbItem = breadcrumb.name === "Product";

        return (
          AddBreadCrumbItem && (
            <>
              <div
                className={`breadcrumb-container ${
                  dropdownVisible ? "expanded" : ""
                }`}
                onClick={handleMenuClick}
              >
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>All Products</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {updatedItems?.selectedProduct.length >= 50 ? (
                      <Tooltip title={updatedItems?.selectedProduct}>
                        {updatedItems?.selectedProduct.slice(0, 47)}...
                      </Tooltip>
                    ) : (
                      updatedItems?.selectedProduct
                    )}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Tag color="blue" className="breadcrumb-tag">
                      {updatedItems?.selectedEnvironment}
                    </Tag>
                  </Breadcrumb.Item>
                </Breadcrumb>
                <DownOutlined
                  style={{
                    float: "right",
                    transition: "transform 0.3s",
                    transform: dropdownVisible
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              </div>
              {dropdownVisible && (
                <div className="dropdown-wrapper" ref={panelRef}>
                  <div className="dropdown-search">
                    <Input
                      placeholder="Search Product"
                      value={searchTerm}
                      onChange={handleSearch}
                      className="search-box"
                      allowClear
                    />
                  </div>
                  <Divider className="horizontal-divider" type="horizontal" />
                  <div className="list-container">
                    <div className="list-column">
                      <List
                        header={<div className="list-header">Products</div>}
                        dataSource={
                          filteredProductList.length === 0
                            ? productsArray
                            : filteredProductList
                        }
                        className="list-wrapper"
                        renderItem={(product) => (
                          <List.Item
                            className="product-item"
                            onClick={() => handleProductSelect(product)}
                            style={{
                              background:
                                selectedProduct?.name === product.name
                                  ? isDarkMode
                                    ? "#333333"
                                    : "#EAEAEA"
                                  : "",
                            }}
                          >
                            <div className="list-item">
                              <span style={{ marginRight: "10px" }}>
                                {getProductIcon(product.type)}
                              </span>
                              <span className="list-item-name">
                                {product.name.length >= 40 ? (
                                  <Tooltip title={product.name}>
                                    {product.name.slice(0, 47)}...
                                  </Tooltip>
                                ) : (
                                  product.name
                                )}
                              </span>
                              {selectedProduct?.id === product?.id && (
                                <span className="list-item-icon">
                                  <RightOutlined />
                                </span>
                              )}
                            </div>
                          </List.Item>
                        )}
                      />
                    </div>

                    <Divider className="list-divider" type="vertical" />

                    <div className="list-column">
                      <List
                        header={<div className="list-header">Environments</div>}
                        className="list-wrapper"
                        dataSource={selectedProduct?.environments}
                        renderItem={(env) => (
                          <List.Item
                            className="env-item"
                            style={{
                              background:
                                selectedEnvironment?.name === env.name
                                  ? isDarkMode
                                    ? "#333333"
                                    : "#EAEAEA"
                                  : "",
                            }}
                            onClick={() => handleEnvironmentSelect(env)}
                          >
                            <div className="list-item">{env?.name}</div>
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>

                  <div className="done-button-container">
                    <span>
                      <Button type="primary" onClick={handleDone}>
                        Done
                      </Button>
                    </span>
                  </div>
                </div>
              )}
            </>
          )
        );
      })}
    </div>
  );
};

export default BreadCrumb;
