import React from "react";
import TargetAttributesContainer from "../../Containers/ProductDetails/TargetAttributesContainer";

const TargetAttributesListScreen = React.forwardRef((props, ref) => {
  return (
    <>
      <React.Fragment>
        <TargetAttributesContainer ref={ref} />
      </React.Fragment>
    </>
  );
});

export default TargetAttributesListScreen;
