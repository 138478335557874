import React from "react";
import AddFileContainer from "../../Containers/FileModule/addFile";

const FileAddScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <AddFileContainer />
      </React.Fragment>
    </>
  );
};

export default FileAddScreen;

