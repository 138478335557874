import React from "react";
import { Route, Routes } from "react-router-dom";
import TargetListScreen from "./targetListScreen";
import TargetAddScreen from "./targetAddScreen";

const TargetRoute = React.forwardRef((props, ref) => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route index path="/" element={<TargetListScreen />} />
          <Route index path="/add" element={<TargetAddScreen />} />
          <Route index path="/edit/:id" element={<TargetAddScreen />} />
        </Routes>
      </React.Fragment>
    </>
  );
});

export default TargetRoute;
