import { Card, Col, Divider, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { isNullOrEmpty } from "../../Util/commonUtility";
import Loader from "../../Components/Common/Loader";
import { useSelector } from "react-redux";
import ProfileForm from "../../Components/Settings/profile";
import { useNavigate } from "react-router-dom";

  import NotificationService from "../../Services/notification";

const EditProfileContainer = (props) => {
  const [profile] = Form.useForm();
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const [profileData, SetProfieData] = useState(false);
  const authState = useSelector((state) => state.auth);
  useEffect(() => {
    getProfileRequest();
  }, []);

  const submitProfileRequest = async (data) => {
    try {
      data.phoneNumber = data.phoneNumber?.replace(/-/g, "");
      setShowLoading(true);
      const response = await httpClient.put(
        Urls.ACCOUNT_PROFILE.replace(
          "#{accountId}",
          authState?.accountData?.accountId
        ),
        data
      );
      if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
        await getProfileRequest();
        NotificationService.success("Success", "Password update successfully"); 
        backToListPage();
      }
      setShowLoading(false);
    } catch (error) {
      console.error("err", error);
    }
  };
  const getProfileRequest = async () => {
    try {
      setShowLoading(true);
      const response = await httpClient.get(
        Urls.ACCOUNT_PROFILE.replace(
          "#{accountId}",
          authState?.accountData?.accountId
        )
      );
      if (
        !isNullOrEmpty(response) &&
        !isNullOrEmpty(response?.data) &&
        isSuccessStatus(response?.status)
      ) {
        SetProfieData(response.data);
      } 
      setShowLoading(false);
    } catch (error) {
      console.error("err", error);
    }
  };

  const backToListPage = () => {
    navigate("/settings/profile");
  };

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <ProfileForm
          formObject={profile}
          initialValuesObject={profileData}
          onFinish={(data) => {
            submitProfileRequest(data);
          }}
          backToListPage={() => {
            backToListPage();
          }}
        />
      )}
    </>
  );
};
export default EditProfileContainer;
