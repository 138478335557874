import React from "react";
import { Route, Routes } from "react-router-dom";
import ChangePasswordContainer from "../../Containers/Settings/changePassword";
const ChangePasswordRoute = (props) => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route index path="/" element={<ChangePasswordContainer />} />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default ChangePasswordRoute;
