import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import {
  DefaultPagingValue,
  DefaultPagingValueForSearchableDropdown,
} from "../../Constant/Common";
import {
  convertDataToQueryParam,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import NotificationService from "../../Services/notification";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../Hooks/useFetchData";
import { internalRoutes } from "../../Constant/internalRoutes";
import ListContentRolloutsComponent from "../../Components/ContentRollouts/listContentRolloutsComponent";

const ListContentRolloutsContainer = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [listFilterData, setListFilterData] = useState({});
  const [productBuild, SetProductBuild] = useState([]);
  const [allContentTemplates, setAllContentTemplates] = useState([]);
  const [allContentRollouts, setAllContentRollouts] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.pageSize || 10,
    pageIndex: DefaultPagingValue.pageIndex || 1,
    totalPages: 0,
    totalRecords: 0,
  });

  const {
    data: ContentRollouts,
    loading: showLoading,
    pagination: fetchedPagination,
    refetch: reFetchAllContentRollouts,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_CONTENT_ROLLOUTS.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    )
      .replace("#{productId}", productState?.selectedProduct?.id)
      .replace(
        "#{environmentId}",
        productState?.selectedEnvironment?.id
      )}?${convertDataToQueryParam(listFilterData)}&PageNumber=${
      pagination.pageIndex
    }&PageSize=${pagination.pageSize}`,
    [
      pagination.pageIndex,
      pagination.pageSize,
      listFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
    ]
  );

  const deleteContentRollout = async (data) => {
    const response = await httpClient.delete(
      Urls.TENANT_DELETE_CONTENT_ROLLOUT.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{environmentId}", productState?.selectedEnvironment?.id)
        .replace("#{rolloutId}", data.id)
    );
    if (isSuccessStatus(response?.status)) {
      NotificationService.success(
        "Success",
        "Content rollout deleted successfully."
      );
      setListFilterData({}); // Reset filter to reflect changes
    }
  };

  // This will called for filter selection filter for build name?
  const fetchAllProductBuild = async () => {
    try {
      const response = await httpClient.get(
        `${Urls.TENANT_GET_ALL_PRODUCT_BUILDS.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace(
            "#{environmentId}",
            productState?.selectedEnvironment?.id
          )}?PageNumber=${
          DefaultPagingValueForSearchableDropdown.PAGE_INDEX
        }&PageSize=${DefaultPagingValueForSearchableDropdown.PAGE_SIZE}`
      );

      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        SetProductBuild(response?.data?.result);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // This will called for filter selection filter for content Templates  name?
  const fetchAllContentTemplates = async () => {
    try {
      const response = await httpClient.get(
        `${Urls.TENANT_GET_ALL_CONTENT_TEMPLATES.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace(
            "#{environmentId}",
            productState?.selectedEnvironment?.id
          )}?PageSize=1000&isEnable=true`
      );

      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        setAllContentTemplates(response.data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchAllContentRollouts = async () => {
    try {
      const response = await httpClient.get(
        `${Urls.TENANT_GET_ALL_CONTENT_ROLLOUTS.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace(
            "#{environmentId}",
            productState?.selectedEnvironment?.id
          )}?pageSize=1000&isEnabled=true`
      );

      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        setAllContentRollouts(response.data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const editContentRolloutData = async (data) => {
    navigate(`${internalRoutes.contentRolloutEdit}${data.id}`);
  };
  const copyRolloutApi = async (
    data,
    contentTemplateCopyForm,
    setSelectedContentRollout,
    setCopiedRolloutName,
    setIsCopyModalOpen
  ) => {
    try {
      const response = await httpClient.post(
        `${Urls.TENANT_CONTENT_ROLLOUT_COPY.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{environmentId}", data?.environmentId)
          .replace(
            "#{rolloutId}",
            data?.rolloutId
          )}?pageSize=1000&isEnabled=true`,
        {
          name: data?.name,
        }
      );

      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        contentTemplateCopyForm.resetFields();
        setSelectedContentRollout();
        setCopiedRolloutName();
        setIsCopyModalOpen(false);
        reFetchAllContentRollouts();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize: pageSize,
    }));
  };

  const listFilterSubmit = (data) => {
    setListFilterData(data);
    setPagination((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter change
  };

  const resetFilter = () => {
    setListFilterData({});
    setPagination((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter reset
  };

  useEffect(() => {
    if (fetchedPagination) {
      fetchAllContentTemplates();
      fetchAllContentRollouts();
      setPagination(fetchedPagination);
      fetchAllProductBuild();
    }
  }, [fetchedPagination]);

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <ListContentRolloutsComponent
          ContentRollouts={ContentRollouts}
          allContentTemplates={allContentTemplates}
          allContentRollouts={allContentRollouts}
          listFilterData={listFilterData}
          productBuildArray={productBuild}
          paging={pagination}
          editContentRolloutData={(data) => editContentRolloutData(data)}
          deleteContentRollout={(data) => deleteContentRollout(data)}
          handlePageChange={handlePageChange}
          listFilterSubmit={listFilterSubmit}
          resetFilter={resetFilter}
          onFinish={(
            data,
            contentTemplateCopyForm,
            setSelectedContentRollout,
            setCopiedRolloutName,
            setIsCopyModalOpen
          ) =>
            copyRolloutApi(
              data,
              contentTemplateCopyForm,
              setSelectedContentRollout,
              setCopiedRolloutName,
              setIsCopyModalOpen
            )
          }
          productState={productState}
        />
      )}
    </>
  );
});

export default ListContentRolloutsContainer;

