import { createAsyncThunk } from "@reduxjs/toolkit";
import {  httpClient } from "./../../../../Api/client";
import Urls from "../../../../Constant/Urls";

export const getAllTenantUsers = createAsyncThunk(
  "tenant/user",
  async (data, state) => {
    let routeToCall = Urls.TENANT_GET_ALL_TENANTS_USERS.replace(
      "#{tenantId}",
      data.tenantId
    );
    const response = await httpClient.get(
      `${routeToCall}?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortBy=${data.sortBy}`
    );
    return response;
  }
);

export const addTenantUser = createAsyncThunk(
  "tenant/adduser",
  async (data, state) => {
    let routeToCall = Urls.TENANT_ADD_TENANT_USER.replace(
      "#{tenantId}",
      data.tenantId
    );
    const response = await httpClient.post(routeToCall, data);
    return response;
  }
);
