import { Navigate, Outlet } from "react-router-dom";
import { isJson } from "../Util/commonUtility";

const RestrictedRoute = ({ requiredRole }) => {
  const accountdata = localStorage.getItem("account");
  const localAccount = isJson(accountdata) ? JSON.parse(accountdata) : null;

  const hasAccessRights = () => {
    return (
      localAccount &&
      localAccount.roles &&
      localAccount.roles.some((element) => requiredRole.includes(element))
    );
  };
  return hasAccessRights() ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" replace />
  );
};

export default RestrictedRoute;
