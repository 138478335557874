import React from "react";
import ContentTemplatesContainer from "../../Containers/ContentTemplates/ListContentTemplatesContainer";

const ContentTemplatesListScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <ContentTemplatesContainer />
      </React.Fragment>
    </>
  );
};

export default ContentTemplatesListScreen;

