import React, { useRef, useEffect } from "react";
import { Editor } from "@monaco-editor/react";
import "./monocoEditor.scss";
import { transformString } from "../../Util/commonUtility";
import { defaultHeight, vsCodeTheme } from "../../Constant/Common";

const MonacoEditor = ({
  height = defaultHeight,
  language,
  theme = vsCodeTheme,
  value,
  onChange,
  options,
  allPlaceHolderData,
}) => {
  const editorRef = useRef(null);
  const templateLanguageType = transformString("LowercaseFirst", language);

  useEffect(() => {
    if (editorRef?.current) {
      const { monaco } = editorRef?.current;
      monaco?.languages?.registerCompletionItemProvider(templateLanguageType, {
        provideCompletionItems: () => {
          const suggestions = allPlaceHolderData?.map((item) => {
            let defaultValueString = JSON.stringify(item.defaultValue, null, 2);
            return {
              label: item.name,
              kind: monaco.languages.CompletionItemKind.Variable,
              insertText: `${item.name}`,
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: `Type: ${item.controlType}, Default: ${defaultValueString}`,
            };
          });
          return { suggestions };
        },
      });
    }
  }, [allPlaceHolderData, value, templateLanguageType]);

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = { editor, monaco };
  };

  return (
    <div className="monoco-code-editor">
      <Editor
        height={height}
        language={templateLanguageType}
        defaultValue={value}
        theme={theme}
        value={value}
        onMount={handleEditorDidMount}
        onChange={onChange}
        options={options}
      />
    </div>
  );
};

export default MonacoEditor;
