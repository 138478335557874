import React, { useState, useEffect } from "react";
import { ConfigProvider, theme } from "antd";
import AppRoute from "./Routes/AppRoute";
import "./Scss/application.scss";
import { THEME_KEY, getLocalStorage, setLocalStorage } from "./Util/commonUtility";

function App() {
  // Retrieve initial theme mode from localStorage
  const [isDarkMode, setIsDarkMode] = useState(
    () => getLocalStorage(THEME_KEY) || false
  );

  // Toggle theme mode and save to localStorage
  const toggleTheme = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    setLocalStorage(THEME_KEY, newMode);
  };

  useEffect(() => {
    // Apply the current theme mode when the component mounts
    document.body.className = isDarkMode ? "dark-mode" : "light-mode";
  }, [isDarkMode]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: isDarkMode ? "#808080" : "#1677ff",
        },
        algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
      }}
    >
      <div
        className={`app-container ${isDarkMode ? "dark-mode" : "light-mode"}`}
      >
        <AppRoute isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
      </div>
    </ConfigProvider>
  );
}

export default App;
