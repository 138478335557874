import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import ProfileRoute from "./profileRoute";
import ChangePasswordRoute from "./changePasswordRoute";
import { ApartmentOutlined, SlidersOutlined } from "@ant-design/icons";
import ProductInfoTabLayout from "../../Components/Common/productInfoTabLayout";

import { Space } from "antd";
const SettingsRoutes = (props) => {
  const [selectedTab, setSelectedTab] = useState({ id: "", action: "list" });
  const navigate = useNavigate();
  const location = useLocation();
  const settingTabItems = [
    {
      label: (
        <Space>
          <SlidersOutlined /> Profile
        </Space>
      ),
      url: "/settings/profile",
      key: "1",
    },
    {
      key: "2",
      url: "/settings/changepassword",
      label: (
        <Space>
          <ApartmentOutlined />
          Change Password
        </Space>
      ),
    },
  ];
  const onTabChange = (tabId) => {
    switch (tabId) {
      case "1":
        navigate("/settings/profile");
        break;
      case "2":
        navigate("/settings/changepassword");
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    const path = location.pathname.split("/"); // Split the path into segments
    switch (path[1]) {
      case "settings":
        switch (path[2]) {
          case "profile":
            switch (path[3]) {
              case "edit":
                setSelectedTab({ id: "1", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "1", action: "list" });
                onTabChange("1");
                break;
            }
            break;
          case "changepassword":
            setSelectedTab({ id: "2", action: "list" });
            break;
          default:
            setSelectedTab({ id: "1", action: "list" });
            onTabChange("1");
            break;
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <React.Fragment>
        <div className="product-details-card">
          <ProductInfoTabLayout
            selectedTabId={selectedTab.id}
            tabs={settingTabItems}
            onTabChange={onTabChange}
          />
        </div>
        <Routes>
          <Route index path="/" element={<ProfileRoute />} />
          <Route index path="/profile/*" element={<ProfileRoute />} />
          <Route
            index
            path="/changepassword/*"
            element={<ChangePasswordRoute />}
          />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default SettingsRoutes;
