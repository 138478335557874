import { useState, useEffect } from "react";
import { httpClient, isSuccessStatus } from "../Api/client";
import { isNullOrEmpty } from "../Util/commonUtility";

const useFetchData = (url, dependencies = []) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 1,
    totalPages: 0,
    totalRecords: 0,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await httpClient.get(url);
      if (isSuccessStatus(response.status) && !isNullOrEmpty(response.data)) {
        setData(response.data);
        if (response.data.pagingStrategy) {
          setPagination({
            pageIndex: response.data.pagingStrategy.currentPage,
            pageSize: response.data.pagingStrategy.pageSize,
            totalPages: response.data.pagingStrategy.totalPages,
            totalRecords: response.data.pagingStrategy.totalRecords,
          });
        }
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, dependencies);

  return { data, loading, pagination, refetch: fetchData };
};

export default useFetchData;

