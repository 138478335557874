import React, { useState } from "react";
import { Modal, Row, Col, Button, message, Tooltip } from "antd";
import {
  CopyOutlined,
  ExpandOutlined,
  CloseCircleOutlined,
  CompressOutlined,
} from "@ant-design/icons";
import PreviewComponent from "../../Common/previewComponent";
import {
  column24,
  ContentTypes,
  defaultHeight,
  defaultWidth,
  fullScreenHeight,
  fullScreenWidth,
  vsCodeTheme,
} from "../../../Constant/Common";
import EditorComponent from "./../../Common/editorComponent";
import {
  getLocalStorage,
  THEME_KEY,
  transformString,
} from "../../../Util/commonUtility";

const EditorPreview = ({ visible, onClose, content, language }) => {
  const [isFullScreen, setIsFullScreen] = useState(true);
  const modalWidth = isFullScreen ? fullScreenWidth : defaultWidth;
  const modalHeight = isFullScreen ? fullScreenHeight : defaultHeight;
  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);
  const isDarkMode = getLocalStorage(THEME_KEY) || false;
  const templateLanguageType = transformString("LowercaseFirst", language);

  const handleCopy = () => {
    if (content) {
      navigator.clipboard
        .writeText(content)
        .then(() => message.success("Copied to clipboard!"))
        .catch(() => message.error("Failed to copy!"));
    }
  };

  const renderEditorActions = () => (
    <div className="editor-preview-actions">
      <Tooltip title="Copy content">
        <Button
          id="editor-copy-content-button"
          icon={<CopyOutlined />}
          onClick={handleCopy}
        />
      </Tooltip>
      <Tooltip title={isFullScreen ? "Exit full screen" : "Full screen"}>
        <Button
          id="editor-full-screen-toggle-button"
          icon={isFullScreen ? <CompressOutlined /> : <ExpandOutlined />}
          onClick={toggleFullScreen}
        />
      </Tooltip>
      <Tooltip title={"Close"}>
        <Button
          id="editor-full-screen-toggle-button"
          icon={<CloseCircleOutlined />}
          onClick={onClose}
        />
      </Tooltip>
    </div>
  );

  return (
    <Modal
      title={
        <div className="editor-preview-modal-header">
          <span>Preview</span>
          {renderEditorActions()}
        </div>
      }
      open={visible}
      styles={{
        body: { height: modalHeight },
      }}
      width={modalWidth}
      className="editor-preview-modal-container"
      centered
      closeIcon={false}
      footer={[
        <Button
          key="close"
          className="editor-modal-close-button"
          id="editor-preview-close-button"
          onClick={onClose}
        >
          Close
        </Button>,
      ]}
    >
      <Row gutter={[16, 16]}>
        {language !== ContentTypes.XAML ? (
          <Col span={24}>
            <div
              className={`editor-preview-preview preview-dynamic-${modalHeight}`}
            >
              <PreviewComponent
                height={modalHeight}
                content={content}
                language={language}
              />
            </div>
          </Col>
        ) : (
          <Col span={column24}>
            <EditorComponent
              height={modalHeight}
              readOnly={true}
              content={content}
              theme={isDarkMode ? vsCodeTheme.dark : vsCodeTheme.light}
              language={templateLanguageType}
            />
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default EditorPreview;

