import React from "react";
import ListBuildContainer from "../../Containers/ProductBuild/ListBuildContainer";

const BuildListScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <ListBuildContainer />
      </React.Fragment>
    </>
  );
};

export default BuildListScreen;
