import React, { useEffect, useState } from "react";
import AddTestComponent from "../../Components/Test/addTestComponent";
import Urls from "../../Constant/Urls";
import { useSelector } from "react-redux";
import { DefaultPagingValue } from "../../Constant/Common";
import { httpClient, isSuccessStatus } from "../../Api/client";
import {
  fromKeyValueArrayToObject,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import Loader from "../../Components/Common/Loader";

const TestContainer = () => {
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [showLoading, setShowLoading] = useState(false);
  const [productAttributes, setProductAttributes] = useState(null);
  const [testDistributionData, setTestDistributionData] = useState(null);
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const [paging, SetPaging] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE,
    pageIndex: DefaultPagingValue.PAGE_INDEX,
    totalPages: DefaultPagingValue.TOTAL_PAGES,
  });

  const fetchAllTargetAttributes = async () => {
    setShowLoading(true);
    const response = await httpClient.get(
      `${Urls.TENANT_GET_ALL_PRODUCT_TARGET_ATTRIBUTES.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      ).replace(
        "#{productId}",
        productState?.selectedProduct?.id
      )}?PageNumber=${paging.pageIndex}&PageSize=${paging.pageSize}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      setProductAttributes(
        response.data?.result?.map((item) => {
          return {
            value: item.name,
            label: item.name,
            type: item.type,
          };
        })
      );
      if (response.data?.pagingStrategy) {
        SetPaging({
          pageIndex: response.data?.pagingStrategy?.currentPage,
          pageSize: response.data?.pagingStrategy?.pageSize,
          totalPages: response.data?.pagingStrategy?.totalPages,
        });
      }
      if (response.data?.result?.length <= 0) {
        SetPaging({
          ...paging,
          pageIndex: DefaultPagingValue.PAGE_INDEX,
        });
      }
    } else {
      setShowLoading(false);
    }
    setShowLoading(false);
  };
  const testDistribution = async (data) => {
    const maskObj = {
      tenantId: authState?.accountData?.tenantId,
      productId: productState?.selectedProduct?.id,
      environmentId: productState?.selectedEnvironment?.id,
      data: fromKeyValueArrayToObject(data.data),
    };
    setShowLoading(true);
    let url = Urls.TENANT_TEST_BUILD_ROLLOUT;
    if (data.testType === "feature") {
      url = Urls.TENANT_TEST_BUILD_FEATURE;
    }
    if (data.testType === "content") {
      url = Urls.TENANT_TEST_BUILD_CONTENT;
    }

    const response = await httpClient.post(url, maskObj);
    if (isSuccessStatus(response?.status)) {
      setTestDistributionData({
        responseData: response?.data,
        submittedData: data,
      });
      setIsDataAvailable(true);
    }
    setShowLoading(false);
  };
  const testFormSubmitHandler = async (values) => {
    await testDistribution(values);
  };
  const onClickClearButton = () => {
    setTestDistributionData(null);
    setIsDataAvailable(false);
  };

  useEffect(() => {
    onClickClearButton();
  }, [productState]);

  useEffect(() => {
    fetchAllTargetAttributes();
  }, [paging.pageIndex, paging.pageSize, authState, productState]);

  return (
    <>
      {showLoading && <Loader />}
      {productAttributes && (
        <AddTestComponent
          testFormSubmitHandler={(data) => testFormSubmitHandler(data)}
          testDistributionData={testDistributionData}
          targetAttributeDropdownData={productAttributes}
          onClickClearButton={() => onClickClearButton()}
          isDataAvailable={isDataAvailable}
        />
      )}
    </>
  );
};

export default TestContainer;

