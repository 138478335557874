import { useDispatch, useSelector } from "react-redux";
import LoginComponent from "../../Components/Auth/login";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { isNullOrEmpty } from "../../Util/commonUtility";
import { accountLoginRequest } from "../../Store/Reducers/Slices/Auth/authSlice";
import NotificationService from "./../../Services/notification";
const LoginContainer = () => {
  //#region hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion

  //#region selector
  const localAccount = localStorage.getItem("account");
  const authState = useSelector((state) => state.auth);
  const [showLoading, SetShowLoading] = useState(false);
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (localAccount) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [authState, localAccount, navigate]);
  //#endregion

  //#region function
  const onFinish = async (values) => {
    try {
      SetShowLoading(true);
      const response = await httpClient.post(Urls.ACCOUNT_LOGIN, values);
      if (
        !isNullOrEmpty(response) &&
        !isNullOrEmpty(response?.data) &&
        isSuccessStatus(response?.status)
      ) { NotificationService.success("Success", " Login successfully");
        dispatch(accountLoginRequest(response.data));
      } 
      SetShowLoading(false); 
      // NotificationService.error("Error", "Login not successfull");
    } catch (error) {
    }
  };

  //#endregion

  //#region render
  return <LoginComponent onFinish={onFinish} showLoading={showLoading} />;
  //#endregion
};
export default LoginContainer;
