import {
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  ColorPicker,
  Image,
  Input,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import {
  CONTROL_TYPE,
  DateFormat,
  fallbackImage,
  LocalStorageEnums,
} from "../../../Constant/Common";
import Highlighter from "react-highlight-words";
import NotificationService from "../../../Services/notification";
import { getPluralSuffix, setLocalStorage } from "../../../Util/commonUtility";
import AddEditContentPlaceHolder from "./addContentPlaceholder";

const ListContentPlaceholder = (props) => {
  const searchInput = useRef(null);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [placeHolderData, setPlaceHolderData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { allPlaceHolderData, setAllPlaceHolderData, templateSchema } = props;
  const editorCode = JSON.stringify(templateSchema);

  // Conditional button label
  const clearPlaceholderButtonDisabled = !allPlaceHolderData.length > 0;
  const hasSelected = selectedRowKeys.length > 0;

  const showPlaceholderDrawer = () => {
    setOpen(true);
    setIsEdit(false);
  };

  // Clear all placeholders or delete selected ones
  const handleClearOrDelete = () => {
    if (hasSelected) {
      // Delete selected placeholders
      const remainingData = allPlaceHolderData.filter(
        (item) => !selectedRowKeys.includes(item.name)
      );
      setAllPlaceHolderData(remainingData);
      setSelectedRowKeys([]); // Clear selected rows
    } else {
      // Clear all placeholders
      setAllPlaceHolderData([]);
      setLocalStorage("allPlaceholderData", []);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchedColumn(null);
    setSearchText("");
  };

  const handleEditClick = (data) => {
    setIsEdit(true);
    setPlaceHolderData(data);
    setOpen(true);
  };

  const handleDeleteClick = (data) => {
    if (editorCode) {
      if (editorCode.includes(`{{${data.name}}}`)) {
        NotificationService.error("PlaceHolder already in use!!");
        return;
      }
      const filteredData = allPlaceHolderData?.filter(
        (obj) => obj.name !== data.name
      );
      setAllPlaceHolderData(filteredData);
    }
  };

  const renderDefaultValue = (value, record) => {
    let finalDefaultValue = (
      <Tooltip title={value}>
        <p className="long-character-text">{value}</p>
      </Tooltip>
    );

    if (record.controlType === CONTROL_TYPE.COLOR_PICKER) {
      finalDefaultValue = (
        <ColorPicker value={value} showText size="small" disabled />
      );
    }
    if (record.controlType === CONTROL_TYPE.IMAGE_UPLOADER) {
      finalDefaultValue = (
        <Image width={60} src={value} fallback={fallbackImage} />
      );
    }
    if (record.controlType === CONTROL_TYPE.DATE) {
      finalDefaultValue = dayjs(value)?.format(DateFormat);
    }
    return finalDefaultValue;
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: editorCode?.includes(`{{${record?.name}}}`),
      name: record.name,
    }),
  };

  useEffect(() => {
    if (allPlaceHolderData.length >= 0) {
      setSelectedRowKeys([]);
      setLocalStorage(LocalStorageEnums.allPlaceholderData, allPlaceHolderData);
    }
  }, [allPlaceHolderData]);

  const columns = [
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      render: (text) => (
        <Tooltip title={text}>
          <p className="long-character-text-name">{text}</p>
        </Tooltip>
      ),
      sorter: (a, b) => a.label.localeCompare(b.label),
      ...getColumnSearchProps("label"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name"),
    },
    {
      title: "Default Value",
      dataIndex: "defaultValue",
      key: "defaultValue",
      render: (text, record) => {
        return renderDefaultValue(text, record);
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "15%",
      render: (_, record) => {
        const isPlaceholderInUse =
          editorCode?.includes(`{{${record?.name}}}`) || hasSelected;
        return (
          <>
            <Button
              icon={<EditOutlined />}
              onClick={() => handleEditClick(record)}
              disabled={isPlaceholderInUse}
              type="text"
            ></Button>
            <Popconfirm
              title="Delete Placeholder"
              description={`Are you sure you want to delete ${record.name} placeholder?`}
              okText="Yes"
              cancelText="No"
              icon={<QuestionCircleOutlined className="color-red-icon" />}
              onConfirm={() => handleDeleteClick(record)}
            >
              <Button
                icon={<DeleteOutlined />}
                disabled={isPlaceholderInUse}
                type="text"
              ></Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div className="add-Placeholder-container">
        <div className="justify-button-and-center">
          <h1>Template Placeholders</h1>
          <div className="placeholder-button-container">
            <Popconfirm
              title={`Are you sure you want to ${
                hasSelected
                  ? `delete the selected placeholder${getPluralSuffix(
                      selectedRowKeys
                    )}`
                  : `clear the placeholder${getPluralSuffix(
                      allPlaceHolderData
                    )} data`
              }?`}
              onConfirm={handleClearOrDelete}
              okText="Yes"
              cancelText="No"
              icon={<QuestionCircleOutlined className="color-red-icon" />}
            >
              <Button
                type="primary"
                icon={hasSelected ? <DeleteOutlined /> : <ClearOutlined />}
                disabled={clearPlaceholderButtonDisabled}
                danger={hasSelected}
              >
                {hasSelected
                  ? `Delete Selected Placeholder${getPluralSuffix(
                      selectedRowKeys
                    )}`
                  : `Clear Placeholder${getPluralSuffix(allPlaceHolderData)}`}
              </Button>
            </Popconfirm>

            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="button-add-placeHolder"
              onClick={showPlaceholderDrawer}
            >
              Add Placeholder
            </Button>
          </div>
        </div>
        <AddEditContentPlaceHolder
          setAllPlaceHolderData={setAllPlaceHolderData}
          placeHolderData={placeHolderData}
          setPlaceHolderData={setPlaceHolderData}
          allPlaceHolderData={allPlaceHolderData}
          isEdit={isEdit}
          open={open}
          setOpen={setOpen}
        />
      </div>
      <Table
        rowKey={(record) => record.name}
        columns={columns}
        rowSelection={rowSelection}
        dataSource={allPlaceHolderData}
        scroll={{
          x: 500,
          y: 500,
        }}
        pagination={false}
      />
    </div>
  );
};

export default ListContentPlaceholder;

