export const internalRoutes = {
  home: "/",
  dashboard: "/dashboard",
  changePassword: "/change-password",
  product: "/product",
  productAdd: "/product/add",
  productEdit: "/product/edit/:id",
  targetAttributes: "/target-attributes",
  targetAttributesAdd: "/target-attributes/add",
  targetAttributesEdit: "/target-attributes/edit/:id",
  contentTemplates: "/product/contentTemplates",
  contentTemplatesAdd: "/product/contentTemplates/add",
  contentTemplatesEdit: "/product/contentTemplates/edit/",
  contentRollout: "/product/contentRollouts",
  contentRolloutAdd: "/product/contentRollouts/add",
  contentRolloutEdit: "/product/contentRollouts/edit/",
  files: "/product/files",
  listFileAdd: "/product/files/add",
  // Add more routes as per your application requirements
};

