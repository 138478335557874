import { Col, Form, Modal, Row, Select, Space, Typography } from "antd";
const { Title } = Typography;
const CommonFeatureCopy = (props) => {
  const {
    visible,
    copyFeatureModalData,
    copyFeatureHandleSubmit,
    onCloseModalReset,
  } = props;

  const [commonFeatureCopyForm] = Form.useForm();

  const handleSubmit = async (data) => {
    copyFeatureHandleSubmit(data);
  };

  return (
    <Modal
      className="modal-testMessage"
      open={visible}
      onOk={commonFeatureCopyForm.submit}
      onCancel={onCloseModalReset}
    >
      <Form form={commonFeatureCopyForm} onFinish={handleSubmit}>
        <Row>
          <Col>
            <Title level={2}>{copyFeatureModalData?.title}</Title>
            <Space align="baseline">
              <Form.Item
                label="Environment"
                name="environmentId"
                rules={[
                  {
                    required: true,
                    message: "Please select any Environment!",
                  },
                ]}
              >
                <Select
                  className="select-items-copy-feature"
                  placeholder="Select Environment"
                  options={copyFeatureModalData?.environments}
                />
              </Form.Item>
              {/* <Form.Item
                name="buildId"
                rules={[
                  {
                    required: true,
                    message: "Please select any Build!",
                  },
                ]}
              >
                <Select
                  className="select-items-copy-feature"
                  placeholder="Select Build"
                  options={copyFeatureModalData?.productBuild}
                />
              </Form.Item> */}
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default CommonFeatureCopy;
