const ErrorMessages = {
  QUERYBUILDER: {
    STRING: "Please enter string value",
    NUMBER: "Please enter number value ",
    BOOLEAN: "Please select anyone",
    DATE: "Please select date",
    VERSION: "Please enter a valid Version!",
    TAGSELECTOR: {
      STRING: "Please add string value",
      NUMBER: "Please add number value ",
      DATE: "Please add date",
      VERSION: {
        ADD: "Please add version",
        ERROR: "Please enter a valid Version!",
      },
    },
    RANGESELECTOR: {
      NUMBER: "Please enter proper number range value",
      VERSION: "Please enter proper version range value",
    },
    HELP: {
      NUMBER: "**Accept number value",
      VERSION: "**Accept version value",
    },
  },
};
export default ErrorMessages;
