/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import {
  DefaultPagingValueForSearchableDropdown,
  LocalStorageEnums,
} from "../../Constant/Common";
import {
  fromKeyValueArrayToObject,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NotificationService from "../../Services/notification";
import { useDispatch } from "react-redux";
import { setAllTargetOperatorsDetails } from "../../Store/Reducers/Slices/LookUp/lookupSlice";
import AddContentTemplatesComponent from "../../Components/ContentTemplates/addContentTemplatesComponent";
import { internalRoutes } from "../../Constant/internalRoutes";

const AddContentTemplateContainer = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [loading, setLoading] = useState(false);
  const [contentTemplate, setContentTemplate] = useState([]);
  const [productTargets, setProductTargets] = useState([]);
  const [targetOperators, setTargetOperators] = useState([]);
  const [addEditContentTemplate, setAddEditContentTemplate] = useState();
  const { allTargetOperators } = useSelector((state) => state.lookup);
  const dispatch = useDispatch();
  const [languageItems, setLanguageItems] = useState([]);
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    const fetchData = async () => {
      const editContentTemplateById = async (id, usedForCopy = false) => {
        setLoading(true);
        try {
          const response = await httpClient.get(
            `${Urls.TENANT_GET_CONTENT_TEMPLATE_BY_ID.replace(
              "#{tenantId}",
              authState?.accountData?.tenantId
            )
              .replace("#{productId}", productState?.selectedProduct?.id)
              .replace("#{templateId}", id)}`
          );
          if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
            if (usedForCopy) {
              const responseData = response?.data;
              const Data = {
                name: `${responseData?.name}_Copy`,
                description: responseData?.description,
                templateSchema: responseData?.templateSchema,
                templateType: responseData?.templateType,
                languages: responseData?.languages,
                templatePlaceholder: responseData?.templatePlaceholder,
                isEnable: responseData?.isEnable,
              };
              setAddEditContentTemplate(Data);
            } else {
              setAddEditContentTemplate(response.data);
            }
          } else {
            backToListPage();
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      const fetchAllProductBuild = async () => {
        setLoading(true);
        try {
          const response = await httpClient.get(
            `${Urls.TENANT_GET_ALL_PRODUCT_BUILDS.replace(
              "#{tenantId}",
              authState?.accountData?.tenantId
            ).replace(
              "#{productId}",
              productState?.selectedProduct?.id
            )}?PageNumber=${
              DefaultPagingValueForSearchableDropdown.PAGE_INDEX
            }&PageSize=${DefaultPagingValueForSearchableDropdown.PAGE_SIZE}`
          );

          if (
            isSuccessStatus(response?.status) &&
            !isNullOrEmpty(response?.data)
          ) {
            setContentTemplate(response?.data?.result);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      const fetchAllProductTarget = async () => {
        setLoading(true);
        try {
          const response = await httpClient.get(
            `${Urls.TENANT_GET_ALL_PRODUCT_TARGETS.replace(
              "#{tenantId}",
              authState?.accountData?.tenantId
            ).replace(
              "#{productId}",
              productState?.selectedProduct?.id
            )}?PageNumber=${
              DefaultPagingValueForSearchableDropdown.PAGE_INDEX
            }&PageSize=${DefaultPagingValueForSearchableDropdown.PAGE_SIZE}`
          );

          if (
            isSuccessStatus(response?.status) &&
            !isNullOrEmpty(response?.data)
          ) {
            setProductTargets(response?.data?.result);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
      if (id) await editContentTemplateById(id);
      const usedForCopy = true;
      if (state) await editContentTemplateById(state, usedForCopy);
      await fetchAllProductTarget();
      await fetchAllProductBuild();
    };

    fetchData();
  }, [id, productState, authState, state]);

  const fetchAllTargetOperators = async () => {
    try {
      const response = await httpClient.get(
        `${Urls.LOOKUP_TARGET_TARGETOPERATORS}`
      );

      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        dispatch(
          setAllTargetOperatorsDetails({
            allTargetOperators: response?.data?.map((item) => ({
              name: item,
              label: item,
            })),
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addContentTemplateApi = async (data) => {
    try {
      if (data.updateBuildMeta) {
        data.updateBuildMeta = fromKeyValueArrayToObject(data.updateBuildMeta);
      }
      if (data.id) {
        setLoading(true);
        const response = await httpClient.put(
          Urls.TENANT_UPDATE_CONTENT_TEMPLATE.replace(
            "#{tenantId}",
            authState?.accountData?.tenantId
          )
            .replace("#{productId}", productState?.selectedProduct?.id)
            .replace("#{templateId}", data.id),
          data
        );
        if (isSuccessStatus(response?.status)) {
          NotificationService.success(
            "Success",
            "Content template edited successfully"
          );
          backToListPage();
        }
      } else {
        const response = await httpClient.post(
          Urls.TENANT_ADD_CONTENT_TEMPLATE.replace(
            "#{tenantId}",
            authState?.accountData?.tenantId
          ).replace("#{productId}", productState?.selectedProduct?.id),
          data
        );
        if (isSuccessStatus(response?.status)) {
          NotificationService.success(
            "Success",
            "Content template added successfully"
          );
          backToListPage();
        }
        localStorage.removeItem(LocalStorageEnums.allPlaceholderData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const backToListPage = () => {
    navigate(internalRoutes.contentTemplates);
  };

  const fetchLanguageItems = async () => {
    const response = await httpClient.get(Urls.TENANT_LANGUAGE_LOOKUP);
    if (isSuccessStatus(response?.status)) {
      setLanguageItems(response.data);
    }
  };

  const contentTemplatePreview = async (contentData) => {
    try {
      const { tenantId } = authState?.accountData || {};
      const { id: productId } = productState?.selectedProduct || {};
      if (!tenantId || !productId) {
        throw new Error('Missing tenantId or productId.');
      }
      const previewUrl = Urls.CONTENT_TEMPLATE_PREVIEW
        .replace("#{tenantId}", tenantId)
        .replace("#{productId}", productId);
      const response = await httpClient.post(previewUrl, { contentData });
      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        const { contentKey } = response?.data;
        if (contentKey) {
          const xamlUrl = Urls.XAML_TEMPLATE_PREVIEW.replace("#{tenantId}",tenantId).replace("#{productId}",productId).replace("#{contentKey}",contentKey)
          const anchor = document.createElement('a');
          anchor.href = xamlUrl;
          anchor.target = '_blank'; 
          anchor.click();
        } else {
          throw new Error('Content key is missing in the response.');
        }
      } else {
        throw new Error('Failed to retrieve a valid response from the server.');
      }
    } catch (error) {
      console.error('Error previewing the product:', error);
      throw error;
    }
  };
  
  useEffect(() => {
    if (allTargetOperators) {
      setTargetOperators(allTargetOperators);
    } else {
      fetchAllTargetOperators();
    }
  }, [allTargetOperators]);

  useEffect(() => {
    fetchLanguageItems();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <AddContentTemplatesComponent
          onFinish={(data) => addContentTemplateApi(data)}
          onPreviewXaml={(data) => contentTemplatePreview(data)}
          productTargets={productTargets?.filter((target) => target.isEnabled)}
          targetOperators={targetOperators}
          editContentTemplateData={addEditContentTemplate}
          contentTemplateArray={contentTemplate}
          backToListPage={backToListPage}
          languageItems={languageItems}
        />
      )}
    </>
  );
});

export default AddContentTemplateContainer;

