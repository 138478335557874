import { isNullOrEmpty } from "../../Util/commonUtility";
import React, { useEffect, useMemo } from "react";
import { JSONEditor } from "@json-editor/json-editor";

const JsonForm = (props) => {
  const {
    formContainerRef,
    formEditorRef,
    jsonSchema,
    formValues,
    setFormValues,
    setCanSubmit,
    validationErrors,
    setValidationErrors,
  } = props;

  const formOptions = useMemo(
    () => ({
      schema: jsonSchema,
      theme: "bootstrap4",
      iconlib: "fontawesome5",
      remove_button_labels: true,
      disable_edit_json: true,
      disable_properties: true,
      disable_collapse: true,
      disable_array_reorder: true,
      disable_array_delete_all_rows: true,
      disable_array_delete_last_row: true,
      show_errors: "always",
    }),
    [jsonSchema]
  );

  const getRequiredFields = (schema) => {
    return schema.required || [];
  };

  useEffect(() => {
    if (!isNullOrEmpty(jsonSchema)) {
      JSONEditor.defaults.custom_validators.push((schema, value, path) => {
        const errors = [];
        const requiredFields = getRequiredFields(schema);

        requiredFields.forEach((field) => {
          if (!value[field]) {
            errors.push({
              path: `${path}.${field}`,
              property: "required",
              message: `${field} is required`,
            });
          }
        });
        return errors;
      });
      formEditorRef.current = new JSONEditor(
        formContainerRef.current,
        formOptions
      );

      formEditorRef.current.on("change", function () {
        const newFormValues = formEditorRef.current.getValue();
        setFormValues && setFormValues(newFormValues);
        const errors = formEditorRef.current.validate();
        setValidationErrors(errors);
      });
    }
    return () => {
      formEditorRef.current?.destroy();
    };
  }, [
    formContainerRef,
    formEditorRef,
    formOptions,
    jsonSchema,
    setFormValues,
    setValidationErrors,
  ]);

  useEffect(() => {
    if (validationErrors.length > 0) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [setCanSubmit, validationErrors]);

  useEffect(() => {
    if (!isNullOrEmpty(jsonSchema) && formEditorRef.current) {
      formEditorRef.current.schema = jsonSchema;
    }
  }, [formEditorRef, jsonSchema]);

  useEffect(() => {
    if (formEditorRef.current) {
      formEditorRef.current.on("ready", function () {
        formEditorRef.current.setValue(formValues);
        const errors = formEditorRef.current.validate();
        setValidationErrors(errors);
      });
    }
  }, [formValues, formEditorRef, setValidationErrors]);

  return (
    <>
      <div
        className="jsoneditor-form"
        id="jsoneditor-form"
        ref={formContainerRef}
      />
    </>
  );
};

export default JsonForm;
