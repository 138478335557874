import React, { useRef } from "react";
import { Editor } from "@monaco-editor/react";
import { EditorDefaultOptions } from "../../Constant/Common";

const EditorComponent = ({ content,readOnly,height,theme, language }) => {
  const editorRef = useRef(null);
  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
  };

  return (
    <div>
      <Editor
        height={height}
        value={content}
        language={language}
        theme={theme}
        defaultLanguage={language}
        defaultValue={content}
        options={{
          ...EditorDefaultOptions,
          readOnly: readOnly,  // Enable read-only mode for preview
          wordWrap: 'on',
          formatOnPaste:true,
          automaticLayout: true,
        }}
        onMount={handleEditorDidMount}
      />
    </div>
  );
};

export default EditorComponent;
