import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DefaultPagingValue } from "../../Constant/Common";
import { useNavigate } from "react-router-dom";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { isNullOrEmpty } from "../../Util/commonUtility";
import Loader from "../../Components/Common/Loader";
import ListTenantUsersComponent from "../../Components/TenantUsers/ListTenantUsersComponent";
import NotificationService from "../../Services/notification";

const ListTenantUsersContainer = () => {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);

  const authState = useSelector((state) => state.auth);
  const [tenantUsers, setTenantUsers] = useState([]);
  const [paging, SetPaging] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE,
    pageIndex: DefaultPagingValue.PAGE_INDEX,
    totalPages: DefaultPagingValue.TOTAL_PAGES,
    totalRecords: DefaultPagingValue.TOTAL_RECORDS,
  });

  const resetPassword = async (userId, email) => {
    setShowLoading(true);
    const response = await httpClient.put(
      Urls.TENANT_RESET_TENANT_USER_PASSWORD.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      ).replace("#{userId}", userId),
      { email: email }
    );
    if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
      fetchAllTenantUsers();
      NotificationService.success("Reset password Successfully");
    } else {
    }
    setShowLoading(false);
  };
  const fetchAllTenantUsers = async () => {
    setShowLoading(true);

    const response = await httpClient.get(
      `${Urls.TENANT_GET_ALL_TENANTS_USERS.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )}?PageNumber=${paging.pageIndex}&PageSize=${paging.pageSize}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      setTenantUsers(response.data);
      if (response.data?.pagingStrategy) {
        SetPaging({
          pageIndex: response.data?.pagingStrategy?.currentPage,
          pageSize: response.data?.pagingStrategy?.pageSize,
          totalPages: response.data?.pagingStrategy?.totalPages,
          totalRecords: response.data?.pagingStrategy?.totalRecords,
        });
      }
      if (response.data?.result?.length <= 0) {
        SetPaging({
          ...paging,
          pageIndex: DefaultPagingValue.PAGE_INDEX,
        });
      }
    } else {
    }
    setShowLoading(false);
  };

  const addEditProduct = (id = null) => {
    if (id) {
      navigate(`/tenant-users/edit/${id}`);
    } else {
      navigate("/tenant-users/add");
    }
  };

  const handlePageChange = async (page, pageSize) => {
    SetPaging({
      ...paging,
      pageIndex: page,
      pageSize: pageSize,
    });
  };

  useEffect(() => {
    fetchAllTenantUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging.pageIndex, paging.pageSize]);
  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <ListTenantUsersComponent
          dataSource={tenantUsers}
          addTenantUser={() => {
            addEditProduct(null);
          }}
          resetPassword={(userId, email) => {
            resetPassword(userId, email);
          }}
          paging={paging}
          handlePageChange={(page, pageSize) => {
            handlePageChange(page, pageSize);
          }}
        />
      )}
    </>
  );
};

export default ListTenantUsersContainer;
