import { Divider, Form, Select, Table } from "antd";
import React, { useEffect } from "react";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import CommonFilterForm from "../Common/commonFilterForm";
import { FileExtensionTypes, FileTypeOptions } from "../../Constant/Common";
import { Image } from "antd";
import { IsFileExtensionValid } from "../../Util/commonUtility";

const ListFileComponent = (props) => {
  const {
    Files,
    paging,
    handlePageChange,
    listFilterSubmit,
    resetFilter,
    listFilterData,
  } = props;
  const [listFilter] = Form.useForm();

  useEffect(() => {
    listFilter.setFieldsValue(listFilterData);
  }, [listFilterData, listFilter]);

  // Adjusted filter fields to match the new data structure
  const filterFields = [
    {
      name: "fileType",
      component: (
        <Select
          allowClear
          className="list-filter-common-style"
          placeholder="Image/File Type"
        >
          {FileTypeOptions.map(({ label, value }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: "Extension",
      component: (
        <Select
          allowClear
          className="list-filter-common-style"
          placeholder="File Extension"
        >
          {FileExtensionTypes.map(({ label, value }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      ),
    },
  ];

  // Updated table columns based on the provided object structure
  const columns = [
    {
      title: "File",
      dataIndex: "fileUri",
      key: "fileUri",
      render: (fileUri, record) =>
        IsFileExtensionValid(record.fileExtension?.toLowerCase()) ? (
          <Image width={200} src={fileUri} />
        ) : (
          "No Image File"
        ),
    },
    {
      title: "Image/File Type",
      dataIndex: "fileType",
      key: "fileType",
    },
    {
      title: "File Extension",
      dataIndex: "fileExtension",
      key: "fileExtension",
      render: (data) => data?.toLowerCase(),
    },
  ];

  return (
    <>
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={listFilterSubmit}
        onReset={resetFilter}
        initialValues={listFilterData}
      />
      <Divider />
      <Table
        dataSource={Files?.result}
        pagination={{
          showTotal: (total, range) => (
            <TotalReocordsInListingComponent
              totalRecords={paging.totalRecords}
            />
          ),
          pageSize: paging.pageSize,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
        }}
        columns={columns}
        rowKey={"id"}
        size="small"
      />
    </>
  );
};

export default ListFileComponent;

