import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import authSlice from "./Slices/Auth/authSlice";
import productSlice from "./Slices/Product/productSlice";
import userSlice from "./Slices/User/userSlice";
import { tenantUserSlice } from "./Slices/TenantUser/tenantUserSlice";
import lookUpSlice from "./Slices/LookUp/lookupSlice";
const persistConfig = {
  key: "root",
  storage, // Defaults to localStorage for web
};

const rootReducer = combineReducers({
  auth: authSlice,
  product: productSlice,
  user: userSlice,
  tenantuser: tenantUserSlice,
  lookup: lookUpSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
