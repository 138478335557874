import {
  Button,
  Checkbox,
  Col,
  Empty,
  Image,
  Input,
  Modal,
  Row,
  Tabs,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { fallbackImage, LocalStorageEnums } from "../../../Constant/Common";
import { getLocalStorage, setLocalStorage } from "../../../Util/commonUtility";
import AddFileContainer from "../../../Containers/FileModule/addFile";
import { LocalLoader } from "../../Common/LocalLoader";

const ExistingImageComponent = (props) => {
  const { fileData, setSelectedImage, selectedImage } = props;
  return (
    <div
      className={` ${
        fileData?.length > 0 ? "grid" : "flex"
      }grid grid-cols-3 gap-4 overflow-y-scroll h-[50vh] w-full`}
    >
      {fileData?.length > 0 ? (
        fileData?.map((item, index) => {
          const selectedImageIndex =
            selectedImage?.existingFileUri === item?.fileUri &&
            selectedImage?.existingIndex === item?.id;

          const handleCheckBoxChange = (e) => {
            const checked = e.target.checked;
            if (checked) {
              setSelectedImage({
                existingFileUri: item.fileUri,
                existingIndex: item?.id,
              });
            } else {
              setSelectedImage(null);
            }
          };
          return (
            <div key={index} className="image-container">
              <Image
                src={item.fileUri}
                width={150}
                height={150}
                fallback={fallbackImage}
              />
              <div className="icon-overlay">
                <Tooltip
                  title={`${
                    selectedImageIndex ? "Deselect Image" : "Select Image"
                  }`}
                >
                  <Checkbox
                    checked={selectedImageIndex}
                    onChange={(e) => handleCheckBoxChange(e)}
                  />
                </Tooltip>
              </div>
            </div>
          );
        })
      ) : (
        <div className="flex justify-center items-center w-full h-full">
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description="No images found"
          />
        </div>
      )}
    </div>
  );
};

const UploadCDNComponent = (props) => {
  const { setSelectedImage, selectedImage } = props;
  const handleImageURLChange = (data) => {
    setSelectedImage &&
      setSelectedImage({
        cdnFileUri: data,
      });
  };
  return (
    <div>
      <Row>
        <Col span={24}>
          <label>Add Image URL :</label>
          <Input
            id="defaultValue"
            value={selectedImage?.cdnFileUri}
            prefixCls="add-notification-placeholder"
            onChange={(e) => handleImageURLChange(e.target.value)}
          />
          {selectedImage?.cdnFileUri && (
            <div className="upload-cdn-container">
              Image Preview :
              <Image
                src={selectedImage?.cdnFileUri}
                width={100}
                height={100}
                alt="Image Preview"
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
const FileListPopUpComponent = (props) => {
  const {
    fileData,
    setIsFetchAllFiles,
    setSelectedImageDefaultValue,
    isEdit,
    placeHolderData,
    usedForRollout,
    showLocalLoader,
    formContentRollout,
    language,
    allFormValues,
    setAllFormValues,
  } = props;

  const [selectedImage, setSelectedImage] = useState({
    existingFileUri: null,
    addFileUri: null,
    cdnFileUri: null,
  });
  const [currentImageMode, setCurrentImageMode] = useState(
    getLocalStorage(LocalStorageEnums.currentImageMode) || "1"
  );
  const [isFileAdding, setIsFileAdding] = useState();

  const [openUploadPopUp, setOpenUploadPopUp] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");

  useEffect(() => {
    if (currentImageMode) {
      setActiveTabKey(currentImageMode);
      setLocalStorage(LocalStorageEnums.currentImageMode, currentImageMode);
    }
  }, [currentImageMode]);

  useEffect(() => {
    if (!placeHolderData) return;
    if (!isEdit && !isFileAdding) {
      const { defaultValue } = placeHolderData;
      const filteredData = fileData?.find(
        (obj) => obj?.fileUri === defaultValue
      );
      if (!usedForRollout) {
        setSelectedImageDefaultValue(defaultValue);
        const imageData = {
          1: {
            existingFileUri: defaultValue,
            existingIndex: filteredData?.id,
          },
          2: { addFileUri: defaultValue },
          3: { cdnFileUri: defaultValue },
        };

        setSelectedImage(imageData[currentImageMode] || imageData[1]);
      }
    }
  }, [
    currentImageMode,
    fileData,
    isEdit,
    isFileAdding,
    placeHolderData,
    setSelectedImageDefaultValue,
    usedForRollout,
  ]);

  const showExistingModal = () => {
    setOpenUploadPopUp(true);
  };

  const handleExistingCancel = () => {
    setOpenUploadPopUp(false);
  };

  const onChange = (key) => {
    setActiveTabKey(key);
  };
  const handleModalFinish = () => {
    const imageUriMap = {
      1: "existingFileUri",
      2: "addFileUri",
      3: "cdnFileUri",
    };
    setCurrentImageMode(activeTabKey === "2" ? "1" : activeTabKey);

    const selectedUri = selectedImage?.[imageUriMap[activeTabKey]];

    if (usedForRollout) {
      formContentRollout.setFieldValue(
        `${language.code}_${placeHolderData.name}`,
        selectedUri
      );
      setAllFormValues &&
        setAllFormValues({
          ...allFormValues,
          [language.code]: {
            ...allFormValues[language.code],
            [`${language.code}_${placeHolderData.name}`]: selectedUri,
          },
        });
    } else {
      setSelectedImageDefaultValue(selectedUri);
    }

    setOpenUploadPopUp(false);
    setCurrentImageMode("1");
  };

  const items = [
    {
      key: "1",
      label: "Select Existing Image",
      children: showLocalLoader ? (
        <LocalLoader />
      ) : (
        <ExistingImageComponent
          setSelectedImage={setSelectedImage}
          fileData={fileData}
          selectedImage={selectedImage}
        />
      ),
    },
    {
      key: "2",
      label: "Upload New Image",
      children: (
        <AddFileContainer
          usedForAddFilePopUp
          setIsFetchAllFiles={setIsFetchAllFiles}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
          setIsFileAdding={setIsFileAdding}
        />
      ),
    },
    {
      key: "3",
      label: "Image URL",
      children: (
        <UploadCDNComponent
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="flex gap-2">
        <Button id="existingImage" type="primary" onClick={showExistingModal}>
          Upload
        </Button>
      </div>
      <div>
        <Modal
          open={openUploadPopUp}
          title="Image Uploader"
          onCancel={handleExistingCancel}
          onOk={handleModalFinish}
          okButtonProps={{
            disabled:
              !selectedImage?.existingFileUri &&
              !selectedImage?.addFileUri &&
              !selectedImage?.cdnFileUri,
          }}
          footer={(_, { OkBtn, CancelBtn }) => (
            <>
              <OkBtn />
              <CancelBtn />
            </>
          )}
        >
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            activeKey={activeTabKey}
          />
        </Modal>
      </div>
    </div>
  );
};

export default FileListPopUpComponent;

