import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Select, Form, Input, Space, Card } from "antd";
import { MaskedInput } from "antd-mask-input";
import {
  MASKED_PHONE_NUMBER,
  PHONE_NUMBER_REGEX,
  Role,
} from "../../Constant/Common";

const AddTenantUser = (props) => {
  const { onFinishFailed, onFinish, backToListPage } = props;
  return (
    <Card title={"Add Tenant User"}>
      <Form
        className="common-form"
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="userName"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[
            {
              pattern: PHONE_NUMBER_REGEX,
              message: "Must be a valid phone number",
            },
          ]}
        >
          <MaskedInput prefixCls="mask-input" mask={MASKED_PHONE_NUMBER} />
        </Form.Item>
        <Form.Item
          name="role"
          label="Role"
          rules={[
            {
              required: true,
              message: "Please select user role!",
            },
          ]}
        >
          <Select placeholder="Please select role" className="select-min-width">
            <Select.Option value={Role.ADMIN}>{Role.ADMIN}</Select.Option>
            <Select.Option value={Role.USER}>{Role.USER}</Select.Option>
            <Select.Option value={Role.CICDUser}>{Role.CICDUser}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Space>
            <Button
              id="backToTenantUserList"
              type="default"
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                backToListPage();
              }}
            >
              Back
            </Button>
            <Button
              className="submit-btn"
              id="submitTenantUser"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default AddTenantUser;
