import { useEffect, useState } from "react";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { isNullOrEmpty } from "../../Util/commonUtility";
import { useSelector } from "react-redux";
import ProductDetails from "../../Components/Products/productDetails";

const DetailsContainer = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [productDetails, SetProductDetails] = useState(false);
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);

  useEffect(() => {
    getProductByID();
  }, [productState]);

  const getProductByID = async () => {
    setShowLoading(true);
    const response = await httpClient.get(
      Urls.TENANT_GET_PRODUCT_BY_ID.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      ).replace("#{productId}", productState?.selectedProduct?.id)
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      SetProductDetails(response?.data);
    } else {
    }
    setShowLoading(false);
  };

  return <ProductDetails data={productDetails} />;
};

export default DetailsContainer;