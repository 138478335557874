import EditProfileContainer from "../../Containers/Settings/editProfileContainer";

const ProfileEditScreen = () => {
  return (
    <>
      <EditProfileContainer />
    </>
  );
};

export default ProfileEditScreen;
