import { List, Modal, Tag } from "antd";
import "./commonTestMessage.scss";

const CommonTestMessage = (props) => {
  const { title, testMessage, visible, closeTestMessage } = props;

  return (
    <Modal
     className="modal-testMessage"
      open={visible}
      onCancel={() => closeTestMessage()}
      type="info"
      footer={null}
    >
      <span
      className="span-title-testMessage" 
      >{title}</span>
      {testMessage?.length > 0 ? (
        testMessage?.map((item) => {
          if (item?.name){
            return (
              <List
              className="list-item-testMessage"
                bordered
              >
                {item.name}
              </List>
            );
          }
        })
      ) : (
        <>
          <br />
          <span className="span-noTest-testMessage"
           >No Test Found</span>
        </>
      )}
    </Modal>
  );
};
export default CommonTestMessage;
