import { httpClient, isSuccessStatus } from "../Api/client";
import Urls from "../Constant/Urls";
import { DefaultPagingValueForSearchableDropdown } from "../Constant/Common";
import { isNullOrEmpty } from "../Util/commonUtility";

export const SearchProductService = async (query, authState) => {
  let queryParam = query ? `&SearchText=${query}` : "";
  const response = await httpClient.get(
    `${Urls.TENANT_GET_ALL_PRODUCTS.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    )}?PageNumber=${
      DefaultPagingValueForSearchableDropdown.PAGE_INDEX
    }&PageSize=${
      DefaultPagingValueForSearchableDropdown.PAGE_SIZE
    }${queryParam}`
  );
  if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
    return response?.data?.result;
  } else {
    return [];
  }
};

export const SearchProductFeatures = async (query, authState, productState) => {
  const response = await httpClient.get(
    `${Urls.TENANT_GET_ALL_PRODUCT_FEATURE_FLAG.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    ).replace("#{productId}", productState?.selectedProduct?.id)}?PageNumber=${
      DefaultPagingValueForSearchableDropdown.PAGE_INDEX
    }&PageSize=${
      DefaultPagingValueForSearchableDropdown.PAGE_SIZE
    }${query}`
  );
  if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
    return response?.data?.result;
  } else {
    return [];
  }
};

export const SearchAllTargetOperators = async () => {
  const response = await httpClient.get(
    `${Urls.LOOKUP_TARGET_TARGETOPERATORS}`
  );
  if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
    return response?.data;
  } else {
    return [];
  }
};
