import { useSelector } from "react-redux";
import AddTargetAttributes from "../../Components/TargetAttributes/AddTargetAttributes";
import { useEffect, useState } from "react";
import { httpClient, isSuccessStatus } from "../../Api/client";
import { useNavigate, useParams } from "react-router-dom";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";

import NotificationService from "../../Services/notification";

const AddTargetAttributeContainer = () => {
  const [loading, setLoading] = useState(false);
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [targetattributeData, setTargetAttributeData] = useState();
  const navigate = useNavigate();
  let { id } = useParams();
  const submitAddTargetAttributeForm = async (data) => {
    setLoading(true);
    data.tenantId = authState?.accountData?.tenantId;
    if (data.id) {
      const response = await httpClient.put(
        Urls.TENANT_UPDATE_PRODUCT_TARGET_ATTRIBUTES_BY_ID.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{targetAttributeId}", data.id),
        data
      );
      if (isSuccessStatus(response?.status)) { 
         NotificationService.success("Success", "Target Attributes edit successfully");
        backToListPage();
      }
    } else {
      const response = await httpClient.post(
        Urls.TENANT_ADD_PRODUCT_TARGET_ATTRIBUTES.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        ).replace("#{productId}", productState?.selectedProduct?.id),
        data
      );
      if (isSuccessStatus(response?.status)) {   
          NotificationService.success("Success", "Target Attributes added successfully");
        backToListPage();
      } 
    }
    setLoading(false);
  };
  const backToListPage = () => {
    navigate("/product/targetattributes");
  };
  useEffect(() => {
    const fetchTargetAttributeWithId = async (id) => {
      setLoading(true);
      const response = await httpClient.get(
        Urls.TENANT_GET_PRODUCT_TARGET_ATTRIBUTE_BY_ID.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{targetAttributeId}", id)
      );
      if (isSuccessStatus(response?.status)) {  
        setTargetAttributeData(response.data);
      } else {
        backToListPage();
      }
      setLoading(false);
    };
    if (id) fetchTargetAttributeWithId(id);
  }, [id, productState, authState]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <AddTargetAttributes
          onFinish={(data) => {
            submitAddTargetAttributeForm(data);
          }}
          targetattributeData={targetattributeData}
          backToListPage={backToListPage}
        />
      )}
    </>
  );
};
export default AddTargetAttributeContainer;
