import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import NotificationService from "../../Services/notification";
import { internalRoutes } from "../../Constant/internalRoutes";
import AddFileComponent from "../../Components/FileModule/addFile";
import Loader from "../../Components/Common/Loader";

const AddFileContainer = React.forwardRef((props, ref) => {
  const {
    usedForAddFilePopUp = false,
    setIsFetchAllFiles,
    setSelectedImage,
    selectedImage,
    setIsFileAdding,
  } = props;
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState();

  const addFilesApi = async (data, addFilesForm) => {
    try {
      setLoading(true);
      const response = await httpClient.post(
        Urls.TENANT_ADD_FILE.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        ).replace("#{productId}", productState?.selectedProduct?.id),
        data
      );
      if (isSuccessStatus(response?.status)) {
        setIsFileAdding && setIsFileAdding(true);
        setSelectedImage &&
          setSelectedImage({
            addFileUri: response?.data?.fileUri,
          });
        addFilesForm && addFilesForm.resetFields();
        NotificationService.success("Success", "File Uploaded successfully");
        setIsFetchAllFiles && setIsFetchAllFiles(true);
        !usedForAddFilePopUp && backToListPage();
      } else {
        setFileData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const backToListPage = () => {
    navigate(internalRoutes.files);
  };

  return (
    <>
      {usedForAddFilePopUp ? (
        <AddFileComponent
          onFinish={(data) => addFilesApi(data)}
          backToListPage={backToListPage}
          fileData={fileData}
          showLocalLoader={loading}
          selectedImage={selectedImage}
          usedForAddFilePopUp={usedForAddFilePopUp}
          setSelectedImage={setSelectedImage}
        />
      ) : loading ? (
        <Loader />
      ) : (
        <AddFileComponent
          onFinish={(data, addFilesForm) => addFilesApi(data, addFilesForm)}
          backToListPage={backToListPage}
          fileData={fileData}
        />
      )}
    </>
  );
});

export default AddFileContainer;

