import React from "react";
import ListTragetContainer from "../../Containers/Target/ListTargetContainer";
const TargetListScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <ListTragetContainer />
      </React.Fragment>
    </>
  );
};

export default TargetListScreen;
