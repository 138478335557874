import { createSlice } from "@reduxjs/toolkit";
import { addTenantUser, getAllTenantUsers } from "./userAction";

let initialState = {
  tenantUsersData: undefined,
  status: "idle",
  error: [],
  //   status: 'idle' | 'loading' | 'succeeded' | 'failed',
  //   error: string | null
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    resetUser(state, action) {},
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTenantUsers.pending, (state, { payload }) => {});
    builder.addCase(getAllTenantUsers.fulfilled, (state, { payload }) => {
      state.tenantUsersData = payload;
    });
    builder.addCase(getAllTenantUsers.rejected, (state, { payload }) => {
      state.error = [];
    });
    builder.addCase(addTenantUser.pending, (state, { payload }) => {});
    builder.addCase(addTenantUser.fulfilled, (state, { payload }) => {
      state.status = "succeeded";
    });
    builder.addCase(addTenantUser.rejected, (state, { payload }) => {
      state.error = [];
    });
  },
});

export const { resetUser } = userSlice.actions;

export default userSlice.reducer;
