import React from "react";
import AddBuildContainer from "../../Containers/ProductBuild/AddBuildContainer";

const BuildAddScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <AddBuildContainer />
      </React.Fragment>
    </>
  );
};

export default BuildAddScreen;
