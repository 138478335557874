import { ANDOROPERATOR } from "../Constant/Common";
import { checkValueOfEquIsValidOrNot, isNullOrEmpty } from "./commonUtility";
export const targetCriteriaEquationCreationFunction = (
  obj,
  SelectorDropdownArray
) => {
  const getFieldType = (field) => {
    const filteredArr = SelectorDropdownArray?.filter(
      (data) => data.name === field
    )[0];
    return filteredArr?.type;
  };
  const mapOperatorToValueType = (op) => {
    switch (op) {
      case "String":
        return "stringValue";
      case "Boolean":
        return "booleanValue";
      case "Date":
        return "dateValue";
      case "Number":
        return "numberValue";
      case "Version":
        return "versionValue";
      default:
        break;
    }
  };

  const prepareInValuesForDate = (valueArray, valueDataType) => {
    if (Array.isArray(valueArray)) {
      return valueArray.map((ele) => ({ [valueDataType]: ele }));
    }
  };

  const extractFromToValuesForDate = (value, valueDataType) => {
    return {
      fromValue: { [valueDataType]: value && value.length ? value[0] : null },
      toValue: { [valueDataType]: value && value.length ? value[1] : null },
    };
  };

  const prepareInValuesForAll = (value, valueDataType) => {
    return {
      inValues: prepareInValuesForDate(value, valueDataType),
    };
  };
  const evaluateCondition = (operator, condition, value, valueDataType) => {
    switch (condition) {
      case "FromTo":
        return extractFromToValuesForDate(value, valueDataType);
      case "In":
      case "NotIn":
        return prepareInValuesForAll(value, valueDataType);
      default:
        return false;
    }
  };

  const checkValueTypeAndCondition = (operator, condition, value) => {
    const valueDataType = mapOperatorToValueType(operator);
    const conditionResult = evaluateCondition(
      operator,
      condition,
      value,
      valueDataType
    );
    return conditionResult || { [valueDataType]: value };
  };

  const createSingleEquation = (eq, i, opArr) => {
    let newOpArr = opArr?.filter((a) => typeof a === "string");
    const operator = getFieldType(eq.field);
    let id = idGeneratorForQuery(eq.field);
    const valueObj = checkValueTypeAndCondition(
      operator,
      eq.operator,
      eq.value
    );
    return {
      targetAttributeId: id,
      targetAttributeType: operator,
      targetConditionEnum: eq.operator,
      targetOperator: (newOpArr && newOpArr[i]) || ANDOROPERATOR.AND,
      valueToCheck: {
        // stringValue: eq.value,
        ...valueObj,
      },
      isValid: checkValueOfEquIsValidOrNot(operator, eq),
    };
  };

  const flattenObjectStructure = (obj) => {
    if (typeof obj !== "object") return obj;
    if (obj.rules && obj.rules.length === 1) {
      const obj1 = flattenObjectStructure(obj.rules[0]);
      return obj1;
    }
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (key === "rules") {
        newObj.rules = obj.rules.map((rule) => flattenObjectStructure(rule));
      } else newObj[key] = obj[key];
    });
    return newObj;
  };

  const generateTargetCriteria = (clearObj) => {
    if (!clearObj.rules) {
      return { equationGroup: [createSingleEquation(clearObj, 0)] };
    }
    const objWithoutOperator = clearObj.rules?.filter(
      (eq) => typeof eq !== "string"
    );
    const objWithOperator = clearObj.rules?.filter(
      (eq) => typeof eq === "string"
    );

    return {
      equationGroup: objWithoutOperator?.map((eq, i) => {
        if (eq.rules) {
          return {
            targetOperator: objWithOperator[i] || ANDOROPERATOR.AND,
            equationGroup: recurNestedEquations(eq),
          };
        } else {
          return createSingleEquation(eq, i, objWithOperator);
        }
      }),
    };
  };

  const recurNestedEquations = (eq) => {
    const operatorArry = eq.rules.filter((rule) => typeof rule == "string");
    const eqArray = eq.rules.filter((rule, i) => typeof rule !== "string");
    return eqArray.map((eq, i) => {
      if (eq.rules) {
        return {
          targetOperator: operatorArry[i] || ANDOROPERATOR.AND,
          equationGroup: recurNestedEquations(eq),
        };
      } else {
        return createSingleEquation(eq, i, operatorArry);
      }
    });
  };
  const idGeneratorForQuery = (value) => {
    const data = SelectorDropdownArray?.filter(
      (type) => type.name === value
    )[0];
    return data?.id;
  };
  const processCriteriaGeneration = (obj) => {
    return generateTargetCriteria(flattenObjectStructure(obj));
  };
  const processedCriteria = processCriteriaGeneration(obj);

  return processedCriteria;
};
