import { useEffect, useState } from "react";
import Loader from "../../Components/Common/Loader";
import ListProductBuildComponent from "../../Components/Build/listProductBuildComponent";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../Hooks/useFetchData";
import Urls from "../../Constant/Urls";
import { convertDataToQueryParam } from "../../Util/commonUtility";
import { DefaultPagingValue } from "../../Constant/Common";

const ListBuildContainer = () => {
  const [listFilterData, setListFilterData] = useState({});
  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.pageSize || 10,
    pageIndex: DefaultPagingValue.pageIndex || 1,
    totalPages: 0,
    totalRecords: 0,
  });

  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);

  // Fetch data using useFetchData
  const { data: productBuilds, loading, pagination: fetchedPagination } = useFetchData(
    `${Urls.TENANT_GET_ALL_PRODUCT_BUILDS.replace("#{tenantId}", authState?.accountData?.tenantId)
      .replace("#{productId}", productState?.selectedProduct?.id)
      .replace("#{environmentId}", productState?.selectedEnvironment?.id)}?${convertDataToQueryParam(listFilterData)}&PageNumber=${pagination.pageIndex}&PageSize=${pagination.pageSize}`,
    [
      pagination.pageIndex,
      pagination.pageSize,
      listFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
    ]
  );

  // Update local pagination state when fetchedPagination changes
  useEffect(() => {
    if (fetchedPagination) {
      setPagination(fetchedPagination);
    }
  }, [fetchedPagination]);

  const handlePageChange = (nextPage, pageSize) => {
    setPagination((prevPaging) => ({
      ...prevPaging,
      pageSize: pageSize,
      pageIndex: nextPage,
    }));
  };

  const editProductBuild = (data) => {
    navigate(`/product/build/edit/${data.id}`);
  };

  const listFilterSubmit = (data) => {
    setListFilterData(data);
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  const resetFilter = () => {
    setListFilterData({});
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  return loading ? (
    <Loader />
  ) : (
    <ListProductBuildComponent
      productBuilds={productBuilds}
      editProductBuild={editProductBuild}
      paging={pagination}
      handlePageChange={handlePageChange}
      listFilterSubmit={listFilterSubmit}
      listFilterData={listFilterData}
      resetFilter={resetFilter}
    />
  );
};

export default ListBuildContainer;
