import React from "react";
import JsonForm from "../Common/JsonForm";
import JsonEditor from "../Common/jsonEditor";
// eslint-disable-next-line no-unused-vars
import { Alert, Button, Collapse, Space } from "antd";
import NotificationService from "../../Services/notification";
import { isNullOrEmpty } from "../../Util/commonUtility";

const RolloutEditor = (props) => {
  const {
    editorSchema,
    formSchema,
    mode = "view",
    formValues,
    setFormValues,
    setCanSubmit,
    formContainerRef,
    formEditorRef,
    validationErrors,
    setValidationErrors,
  } = props;

  const items = [
    {
      key: "1",
      label: "How to Use the JSON Editor?",
      children: (
        <div>
          <ul>
            <li>Initially, the editor will not be visible.</li>
            <li>
              When you select any feature, the editor and the generated form for
              that feature will become visible.
            </li>
            <li>The editor is only for previewing the JSON values.</li>
            <li>
              You can change the form values, and the changed form values will
              be previewed in the editor.
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(formValues, null, 2)).then(
      () => NotificationService.success("JSON copied to clipboard."),
      (error) => console.error("Error copying text:", error)
    );
  };

  return (
    <>
      <div className="feature-flag-content">
        <Space.Compact>
          <Collapse
            prefixCls="feature-collapse"
            items={items}
            style={{ width: "100%" }}
          />
          {!isNullOrEmpty(formValues) && (
            <div className="feature-rollout-copy-button">
              <Button type="primary" id="copy-button">
                <i className="fas fa-copy"></i>
                <span className="copy-btn-text" onClick={handleCopyToClipboard}>
                  Copy JSON
                </span>
              </Button>
            </div>
          )}
        </Space.Compact>

        <div className={"feature-rollout-editor-container"}>
          <JsonEditor jsonSchema={editorSchema} mode={mode} />
          <JsonForm
            jsonSchema={formSchema}
            formValues={formValues}
            setFormValues={setFormValues}
            setCanSubmit={setCanSubmit}
            formContainerRef={formContainerRef}
            formEditorRef={formEditorRef}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
          />
        </div>
      </div>
    </>
  );
};
export default RolloutEditor;

