import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { useCallback, useEffect, useState } from "react";
import Loader from "../../Components/Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import AddTenantUserComponent from "../../Components/TenantUsers/AddTenantUsersComponent";
import moment from "moment";

import NotificationService from "../../Services/notification";
import { DOB_FORMAT_FORM } from "../../Constant/Common";

const AddTenantUsersContainer = () => {
  const authState = useSelector((state) => state.auth);
  const [showLoading, setShowLoading] = useState(false);
  const [editTenantUserData, setEditTenantUserData] = useState();
  const navigate = useNavigate();

  const submitAddTenantUserForm = async (data) => {
    setShowLoading(true);
    data.tenantId = authState?.accountData?.tenantId;
    data.birthDate = moment(data.birthDate).format(DOB_FORMAT_FORM);
    const response = await httpClient.post(
      Urls.TENANT_ADD_TENANT_USER.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      ),
      data
    );
    if (isSuccessStatus(response?.status)) {    NotificationService.success("Success", "Tenant user added successfully"); 
      backToListPage();
    } else {
    }

    setShowLoading(false);
  };
  const { id } = useParams();
  const backToListPage = useCallback(() => {
    navigate("/tenant-users");
  }, [navigate]);

  useEffect(() => {
    const getTenantUserDataById = async (id) => {
      setShowLoading(true);
      try {
        const response = await httpClient.get(
          `${Urls.TENANT_GET_PRODUCT_BY_ID.replace(
            "#{tenantId}",
            authState?.accountData?.tenantId
          ).replace("#{productId}", id)}`
        );

        if (isSuccessStatus(response?.status)) {    NotificationService.success("Success", "Tenant user update successfully");   
          setEditTenantUserData(response?.data);
        } else {
          // Handle unsuccessful status if needed
        }
      } catch (error) {
        // Handle any potential error during API call
      } finally {
        setShowLoading(false);
      }
    };
    if (id) {
      getTenantUserDataById(id);
    }
  }, [id, authState, backToListPage, navigate, setShowLoading]);

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <AddTenantUserComponent
          onFinish={(data) => {
            submitAddTenantUserForm(data);
          }}
          editTenantUserData={editTenantUserData}
          backToListPage={() => backToListPage()}
        />
      )}
    </>
  );
};
export default AddTenantUsersContainer;
