import {
  Alert,
  Button,
  Collapse,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Space,
  Tooltip,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import React, { useState } from "react";
import {
  END_CONDITION,
  FREQUENCY,
  MASKED_BUILD_VERSION,
  SHOW_AT,
} from "../../Constant/Common";
import { CaretRightOutlined, DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export const fieldGenerator = (opt, index) => {
  switch (opt) {
    case "String":
      return (
        <Input
          placeholder="Please enter string value"
          className="test-page-input-width"
        />
      );
    case "Boolean":
      return (
        <Radio.Group className="test-page-input-width">
          <Radio id="radioTrueTest" value={true}>
            True
          </Radio>
          <Radio id="radioFalseTest" value={false}>
            False
          </Radio>
        </Radio.Group>
      );
    case "Date":
      return (
        <DatePicker className="test-page-input-width" format="YYYY-MM-DD" />
      );

    case "Number":
      return <InputNumber className="test-page-input-width" />;
    case "Version":
      return (
        <MaskedInput
          mask={MASKED_BUILD_VERSION}
          className="test-page-input-width"
        />
      );
    default:
      break;
  }
};

const renderNotAvailableDescription = (type, data) => {
  switch (type) {
    case "build":
      return (
        <>
          <Space>
            <strong>Update Available:</strong> No
          </Space>
          <br />
          <Space>
            <strong>Download URL:</strong> Not Available
          </Space>
          <br />
          <Space>
            <strong>Version:</strong> Not Available
          </Space>
        </>
      );
    case "feature":
      return (
        <>
          <Space>
            <strong>Name:</strong>Not Available
          </Space>
          <br />
          <Space>
            <strong>Description:</strong> Not Available
          </Space>
          <br />
          <Space>
            <strong>Config:</strong>Not Available
          </Space>
        </>
      );
    case "content":
      return (
        <>
          <Space>
            <strong>Name:</strong> Not Available
          </Space>
          <br />
          <Space>
            <strong>Template Type:</strong> Not Available
          </Space>
          <br />
          <Space>
            <strong>Frequency:</strong> Not Available
          </Space>
          <br />
          <>
            <Space>
              <strong>Days:</strong> Not Available
            </Space>
            <br />
          </>
          <>
            <Space>
              <strong>Days:</strong> Not Available
            </Space>
            <br />
          </>
          <Space>
            <strong>Show At:</strong> Not Available
          </Space>
          <br />
          <>
            <Space>
              <strong>Show Time:</strong> Not Available
            </Space>
            <br />
          </>
          <Space>
            <strong>Start Date:</strong> Not Available
          </Space>
          <br />
          <Space>
            <strong>End:</strong> Not Available
          </Space>
          <br />
          <>
            <Space>
              <strong>End Date:</strong>
              Not Available
            </Space>
            <br />
          </>
          <>
            <Space>
              <strong>Number of Show:</strong> Not Available
            </Space>
            <br />
          </>
        </>
      );
    default:
      break;
  }
};
const renderDescription = (type, item) => {
  switch (type) {
    case "build":
      return (
        <Alert
          className="status"
          style={{ margin: "1rem 0" }}
          description={
            <>
              <Space>
                <strong>Update Available:</strong>{" "}
                {item?.isUpdateAvailable !== undefined
                  ? item.isUpdateAvailable
                    ? "Yes"
                    : "No"
                  : "Not Available"}
              </Space>
              <br />
              <Space>
                <strong>Download URL:</strong>{" "}
                {item?.downloadUrl ? (
                  <>
                    {item.downloadUrl}
                    <a target="_blank" href={item.downloadUrl} rel="noreferrer">
                      <Button
                        size="small"
                        title="Download"
                        icon={<DownloadOutlined />}
                      />
                    </a>
                  </>
                ) : (
                  "Not Available"
                )}
              </Space>
              <br />
              <Space>
                <strong>Version:</strong>{" "}
                {item?.version ? <>{item.version}</> : "Not Available"}
              </Space>
            </>
          }
        ></Alert>
      );
    case "feature":
      return (
        <Alert
          className="status"
          style={{ margin: "1rem 0" }}
          description={
            <>
              <Space>
                <strong>Name:</strong> {item?.name || "Not Available"}
              </Space>

              <br />

              <Space>
                <strong>Description:</strong>{" "}
                {item?.description || "Not Available"}
              </Space>

              <br />

              <Space className="test-config-value">
                <strong>Config:</strong> {item?.config || "Not Available"}
              </Space>
            </>
          }
        ></Alert>
      );
    case "content":
      return (
        <>
          <Collapse
            size="small"
            prefixCls="content-cfc-collapse"
            ghost
            expandIconPosition="left"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            items={[
              {
                key: "1",
                label: item.name,
                children: (
                  <>
                    {" "}
                    <Space>
                      <strong>Template Type:</strong>{" "}
                      {item?.templateType || "Not Available"}
                    </Space>
                    <br />
                    <Space>
                      <strong>Frequency:</strong>{" "}
                      {item?.frequency || "Not Available"}
                    </Space>
                    <br />
                    {item?.frequency === FREQUENCY.WEEKLY && (
                      <>
                        <Space>
                          <strong>Days:</strong> {item?.days || "Not Available"}
                        </Space>
                        <br />
                      </>
                    )}
                    {item?.frequency === FREQUENCY.MONTHLY && (
                      <>
                        <Space>
                          <strong>Days:</strong> {item?.days || "Not Available"}
                        </Space>
                        <br />
                      </>
                    )}
                    <Space>
                      <strong>Show At:</strong>{" "}
                      {item?.showAt || "Not Available"}
                    </Space>
                    <br />
                    {item?.showAt === SHOW_AT.SET_TIME && (
                      <>
                        <Space>
                          <strong>Show Time:</strong>{" "}
                          {item?.showTime || "Not Available"}
                        </Space>
                        <br />
                      </>
                    )}
                    <Space>
                      <strong>Start Date:</strong>{" "}
                      {item?.startDate
                        ? dayjs(item.startDate).format("MMMM D, YYYY")
                        : "Not Available"}
                    </Space>
                    <br />
                    <Space>
                      <strong>End:</strong> {item?.end || "Not Available"}
                    </Space>
                    <br />
                    {item?.end === END_CONDITION.SET_DATE && (
                      <>
                        <Space>
                          <strong>End Date:</strong>
                          {item.endDate
                            ? dayjs(item?.endDate).format("MMMM D, YYYY")
                            : "Not Available"}
                        </Space>
                        <br />
                      </>
                    )}
                    {item?.end === END_CONDITION.AFTER_N_SHOW && (
                      <>
                        <Space>
                          <strong>Number of Show:</strong>{" "}
                          {item?.numberOfShow || "Not Available"}
                        </Space>
                        <br />
                      </>
                    )}
                    <Space direction="vertical">
                      <strong>TemplateData:</strong>
                      {item?.templateData?.map((item) => {
                        return (
                          <div className="flex flex-col">
                            <Space>
                              <strong>Lang Code:</strong>{" "}
                              {item?.langCode || "Not Available"}
                            </Space>
                            <br />
                            <Space>
                              <strong>Lang Name:</strong>{" "}
                              {item?.langName || "Not Available"}
                            </Space>
                            <br />
                            <Space>
                              <strong>Content:</strong>{" "}
                              {item?.content || "Not Available"}
                            </Space>
                            <br />
                          </div>
                        );
                      }) || "Not Available"}
                    </Space>
                    <br />{" "}
                  </>
                ),
              },
            ]}
          />
        </>
      );
    default:
      break;
  }
};

export const responseHandle = (type, data) => {
  if (!data || data.length === 0) {
    return (
      <Alert
        description={renderNotAvailableDescription(type, data)}
        className="status"
        style={{ margin: "1rem 0" }}
      />
    );
  } else
    return data.map((item) => {
      return renderDescription(type, item);
      //   <Alert
      //     description={}
      //     className="status"
      //     style={{ margin: "1rem 0" }}
      //   />
      // );
    });
};

