import React from "react";
import { Route, Routes } from "react-router-dom";
import ContentRolloutListScreen from "./contentRolloutListScreen";
import ContentRolloutAddScreen from "./contentRolloutAddScreen";
const ContentRolloutRoutes = () => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route index path="/" element={<ContentRolloutListScreen />} />
          <Route index path="/edit/:id" element={<ContentRolloutAddScreen />} />
          <Route index path="/add" element={<ContentRolloutAddScreen />} />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default ContentRolloutRoutes;

