// PrivateRoute.js
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Role } from "../Constant/Common";
import { isJson } from "../Util/commonUtility";

const PrivateRoute = () => {
  const accountdata = localStorage.getItem("account");
  const localAccount = isJson(accountdata) ? JSON.parse(accountdata) : null;
  const auth = !!localAccount;
  if (auth) {
    return localAccount.roles.some(
      (role) => role === Role.USER || role === Role.ADMIN
    ) ? (
      <Outlet />
    ) : (
      <Navigate to="/unauthorized" />
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
