import { Tooltip } from "antd";

const ShowDescriptionInListing = (props) => {
  const { title, text } = props;
  return (
    <Tooltip overlayStyle={{ maxWidth: "600px",  boxShadow:"border-box"}} title={title}>
      <div className="listing-spacing"  
      >
        {text}
      </div>
    </Tooltip>
  );
};

export default ShowDescriptionInListing;
