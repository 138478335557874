import React from "react";
import ListFileContainer from "../../Containers/FileModule/ListFileContainer";

const FileListScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <ListFileContainer />
      </React.Fragment>
    </>
  );
};

export default FileListScreen;