import React, { useEffect, useState } from "react";
import ListTargetProductComponent from "../../Components/ProductTarget/listTargetProductComponent";
import Urls from "../../Constant/Urls";
import {
  DefaultPagingValue,
  DefaultPagingValueForSearchableDropdown,
} from "../../Constant/Common";
import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import {
  convertDataToQueryParam,
  isEmpty,
  isJson,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import Loader from "../../Components/Common/Loader";
import AddProductTargetComponent from "../../Components/ProductTarget/addProductTarget";
import CommonTestMessage from "../../Components/Common/commonTestMessage";
// import { Form } from "antd";
import NotificationService from "../../Services/notification";
import CommonFeatureCopy from "../../Components/Common/commonFeatureCopy";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../Hooks/useFetchData";
const ListTragetContainer = React.forwardRef((props, ref) => {
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [listFilterData, setListFilterData] = useState({});
  const navigate = useNavigate();

  const [productBuild, SetProductBuild] = useState([]);
  const [addEditTargetData, setAddEditTargetData] = useState({
    visible: false,
    data: undefined,
    title: "Target Hit",
  });
  const [showTestMessage, setShowTestMessage] = useState({
    visible: false,
    message: undefined,
    title: undefined,
  });
  const [showCopyTargetModal, setShowCopyTargetModal] = useState({
    visible: false,
    data: undefined,
  });

  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.pageSize || 10,
    pageIndex: DefaultPagingValue.pageIndex || 1,
    totalPages: 0,
    totalRecords: 0,
  });

  

  const {
    data: productTargets,
    loading: showLoading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_PRODUCT_TARGETS.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    )
      .replace("#{productId}", productState?.selectedProduct?.id)
      .replace(
        "#{environmentId}",
        productState?.selectedEnvironment?.id
      )}?${convertDataToQueryParam(listFilterData)}&PageNumber=${
        pagination.pageIndex
      }&PageSize=${pagination.pageSize}`,
    [
      pagination.pageIndex,
      pagination.pageSize,
      listFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
    ]
  );

  // Update local pagination state when fetchedPagination changes
  useEffect(() => {
    if (fetchedPagination) {
      setPagination(fetchedPagination);
    }
  }, [fetchedPagination]);

  useEffect(() => {
    setAddEditTargetData({
      visible: false,
      data: undefined,
    });
  }, [pagination.pageIndex, pagination.pageSize, productState]);

  const addUpdateProductTarget = async (data) => {
    if (data.id) {
      // console.log(
      //   Urls.TENANT_UPDATE_TARGET.replace(
      //     "#{tenantId}",
      //     authState?.accountData?.tenantId
      //   )
      //     .replace("#{productId}", productState?.selectedProduct?.id)
      //     .replace("#{environmentId}", productState?.selectedEnvironment?.id)
      //     .replace("#{targetId}", data.id)
      // );
      const response = await httpClient.put(
        Urls.TENANT_UPDATE_TARGET.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{environmentId}", productState?.selectedEnvironment?.id)
          .replace("#{targetId}", data.id),
        data
      );
      if (isSuccessStatus(response?.status)) {
        setAddEditTargetData({
          visible: false,
          data: undefined,
        });
        setListFilterData({}); // Reset filter to reflect changes
      } else {
        console.error("not done");
      }
      // }
    } else {
      const response = await httpClient.post(
        Urls.TENANT_ADD_TARGET.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{environmentId}", productState?.selectedEnvironment?.id),
        data
      );
      if (isSuccessStatus(response?.status)) {
        NotificationService.success("Success", "Target edit successfully");
        setAddEditTargetData({
          visible: false,
          data: undefined,
        });
        setListFilterData({}); // Reset filter to reflect changes
      } else {
      }
    }
  };

  const deleteProductTarget = async (data) => {
    const response = await httpClient.delete(
      Urls.TENANT_DELETE_TARGET.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{environmentId}", productState?.selectedEnvironment?.id)
        .replace("#{targetId}", data.id)
    );
    if (isSuccessStatus(response?.status)) {
      NotificationService.success("Success", "Target deleted successfully.");
      setListFilterData({}); // Reset filter to reflect changes
    }
  };

  const testProductTargetById = async (data) => {
    const response = await httpClient.get(
      `${Urls.TENANT_GET_TARGET_TEST.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{environmentId}", productState?.selectedEnvironment?.id)
        .replace("#{targetId}", data.id)}`
    );
    if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
      let allNames = [];
      if (response.data?.length > 0) {
        setShowTestMessage({
          visible: true,
          message: response?.data?.map((item) => item),
          title: "Target Hit",
        });
      }
    } else {
    }
  };

  const fetchEnvs = () => {
    return productState?.selectedProduct?.environments?.map((item) => {
      return {
        id: item.id,
        value: item.id,
        label: item.name,
      };
    });
  };

  const copyTarget = async (data) => {
    const allEnvs = fetchEnvs();
    setShowCopyTargetModal({
      visible: true,
      data: {
        title: "Copy Target : " + data.name,
        id: data.id,
        environments: allEnvs,
      },
    });
  };

  const copyFeatureTarget = async (formData) => {
    const response = await httpClient.post(
      `${Urls.TENANT_ADD_TARGET_COPIED_URL.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{environmentId}", productState?.selectedEnvironment?.id)
        .replace("#{targetId}", showCopyTargetModal?.data?.id)}`,
      formData
    );

    if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
      setShowCopyTargetModal({ data: undefined, visible: false });
      NotificationService.success(
        "Success",
        "Please check respective environment"
      );
    } else {
    }
  };

  const editProductTarget = async (data) => {
    navigate(`/product/target/edit/${data.id}`);
  };

  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize: pageSize,
    }));
  };

  const listFilterSubmit = (data) => {
    setListFilterData(data);
    setPagination((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter change
  };

  const resetFilter = () => {
    setListFilterData({});
    setPagination((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter reset
  };

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <ListTargetProductComponent
          productTargets={productTargets}
          listFilterData={listFilterData}
          paging={pagination}
          editProductTarget={(data) => editProductTarget(data)}
          deleteProductTarget={(data) => deleteProductTarget(data)}
          testProductTargetById={(data) => testProductTargetById(data)}
          CopyFeatureById={(data) => copyTarget(data)}
          handlePageChange={handlePageChange}
          listFilterSubmit={listFilterSubmit}
          resetFilter={resetFilter}
        />
      )}
      {showTestMessage && (
        <CommonTestMessage
          title={showTestMessage.title}
          visible={showTestMessage.visible}
          testMessage={showTestMessage.message}
          closeTestMessage={() => {
            setShowTestMessage({ visible: false, message: null });
          }}
        />
      )}
      {showCopyTargetModal && (
        <CommonFeatureCopy
          visible={showCopyTargetModal.visible}
          copyFeatureModalData={showCopyTargetModal.data}
          onCloseModalReset={() => {
            setShowCopyTargetModal({ visible: false, data: undefined });
          }}
          copyFeatureHandleSubmit={copyFeatureTarget}
        />
      )}
    </>
  );
});
export default ListTragetContainer;
