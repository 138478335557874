// QueryBuilderHelpers.js
import React from "react";
import { Input, Radio, Space, DatePicker, InputNumber, Typography } from "antd";
import { MASKED_BUILD_VERSION } from "../../Constant/Common";
import { MaskedInput } from "antd-mask-input";
import { handleKeyDownAllowOnlyNumber } from "../../Util/commonUtility";
import CommonTagSelector from "./CommonTagSelector";
import CommonRangeSelector from "./CommonRangeSelector";
import dayjs from "dayjs";
import ErrorMessages from "../../Constant/strings/errors";
import ErrorToolTip from "./ErrorToolTip";

const { Text } = Typography;
export const CustomValueEditor = (props) => {
  const onFieldChange = (data, type) => {
    props.fieldData.touched = true;
    switch (type) {
      case "select":
      case "date":
      case "RangeRule":
        props.handleOnChange(data);
        break;
      case "input":
      case "boolean":
        props.handleOnChange(data?.target?.value);
        break;
      case "input-number":
        props.handleOnChange(data);
        break;
      case "input-maked-value":
        props.handleOnChange(data);
        break;
      default:
        break;
    }
  };
  return generateInputElement(
    props?.fieldData?.type,
    props.operator,
    props.value,
    onFieldChange,
    props
  );
};
const generateErrorMessage = (props, message) => {
  return (
    !props.validation &&
    props?.fieldData?.touched && <ErrorToolTip title={message} />
  );
};
const generateInputComponent = (value, props, onFieldChange) => {
  const inputComponent = (
    <Space.Compact direction="vertical">
      <Input
        onChange={(data) => onFieldChange(data, "input")}
        onPressEnter={(data) => onFieldChange(data, "input")}
        defaultValue={value}
        placeholder={ErrorMessages.QUERYBUILDER.STRING}
        status={!props.validation && props.fieldData.touched ? "error" : ""}
        className={`react-query-builder-input-string ${
          props.validation ? "" : "common-error"
        }`}
      />
      {generateErrorMessage(props, ErrorMessages.QUERYBUILDER.STRING)}
    </Space.Compact>
  );
  return inputComponent;
};
const generateCommonTagSelector = (
  value,
  props,
  onFieldChange,
  inputType,
  onFieldBlur
) => {
  return (
    <CommonTagSelector
      initialValue={value}
      onTagChange={(data) => {
        onFieldChange(data, "select");
      }}
      inputType={inputType}
      validationMessage={!props.validation && props.fieldData.touched}
    />
  );
};
const generateDateComponent = (value, props, onFieldChange) => {
  return (
    <Space.Compact>
      <DatePicker
        defaultValue={value && dayjs(value).isValid() ? dayjs(value) : null}
        onChange={(date, dateString) => {
          onFieldChange(dateString, "date");
        }}
      />
      {generateErrorMessage(props, ErrorMessages.QUERYBUILDER.DATE)}
    </Space.Compact>
  );
};
const generateBooleanComponent = (value, props, onFieldChange) => {
  return (
    <Space.Compact direction="vertical">
      <Radio.Group
        onChange={(data) => onFieldChange(data, "boolean")}
        defaultValue={value}
      >
        <Radio value={true}>True</Radio>
        <Radio value={false}>False</Radio>
      </Radio.Group>
      {generateErrorMessage(props, ErrorMessages.QUERYBUILDER.BOOLEAN)}
    </Space.Compact>
  );
};
const generateInputNumberComponent = (value, props, onFieldChange) => {
  return (
    <Space.Compact direction="vertical">
      <InputNumber
        onChange={(data) => onFieldChange(data, "input-number")}
        onPressEnter={(data) => onFieldChange(data, "input-number")}
        defaultValue={value}
        onKeyDown={handleKeyDownAllowOnlyNumber}
        controls={false}
        status={!props.validation && props.fieldData.touched ? "error" : ""}
        className={`react-query-builder-input-string ${
          props.validation ? "" : "common-error"
        }`}
      />
      {generateErrorMessage(props, ErrorMessages.QUERYBUILDER.NUMBER)}
    </Space.Compact>
  );
};
const generateMaskedVersionComponent = (value, props, onFieldChange) => {
  return (
    <Space.Compact direction="vertical">
      <Input
        defaultValue={value}
        onChange={(e) => {
          onFieldChange(e.target.value, "input-maked-value");
        }}
        status={!props.validation && props.fieldData.touched ? "error" : ""}
        className={`react-query-builder-input-string ${
          props.validation ? "" : "common-error"
        }`}
      />
      {generateErrorMessage(props, ErrorMessages.QUERYBUILDER.VERSION)}
    </Space.Compact>
  );
};
const generateRangeRuleComponent = (value, props, onFieldChange, inputType) => {
  return (
    <Space.Compact direction="horizontal">
      <CommonRangeSelector
        initialValue={value}
        inputType={inputType}
        onTagChange={(data) => {
          onFieldChange(data, "RangeRule");
        }}
        touched={props.fieldData.touched}
        validationMessage={!props.validation}
      />
    </Space.Compact>
  );
};
const generateInputElement = (type, operator, value, onFieldChange, props) => {
  const elementMapping = {
    String: {
      Empty: <></>,
      NotEmpty: <></>,
      EqualsTo: generateInputComponent(value, props, onFieldChange),
      NotEqualsTo: generateInputComponent(value, props, onFieldChange),
      In: generateCommonTagSelector(value, props, onFieldChange, "Input"),
      NotIn: generateCommonTagSelector(value, props, onFieldChange, "Input"),
      Contains: generateInputComponent(value, props, onFieldChange),
      NotContains: generateInputComponent(value, props, onFieldChange),
      StartWith: generateInputComponent(value, props, onFieldChange),
      EndWith: generateInputComponent(value, props, onFieldChange),
    },
    Date: {
      Empty: <></>,
      NotEmpty: <></>,
      EqualsTo: generateDateComponent(value, props, onFieldChange),
      NotEqualsTo: generateDateComponent(value, props, onFieldChange),
      In: generateCommonTagSelector(value, props, onFieldChange, "Date"),
      NotIn: generateCommonTagSelector(value, props, onFieldChange, "Date"),
      GreaterThan: generateDateComponent(value, props, onFieldChange),
      LessThan: generateDateComponent(value, props, onFieldChange),
      GreaterThanEqualsTo: generateDateComponent(value, props, onFieldChange),
      LessThanEqualsTo: generateDateComponent(value, props, onFieldChange),
      FromTo: generateRangeRuleComponent(value, props, onFieldChange, "Date"),
      StartWith: generateDateComponent(value, props, onFieldChange),
      EndWith: generateDateComponent(value, props, onFieldChange),
    },
    Boolean: {
      Empty: <></>,
      NotEmpty: <></>,
      EqualsTo: generateBooleanComponent(value, props, onFieldChange),
      NotEqualsTo: generateBooleanComponent(value, props, onFieldChange),
    },
    Number: {
      Empty: <></>,
      NotEmpty: <></>,
      EqualsTo: generateInputNumberComponent(value, props, onFieldChange),
      NotEqualsTo: generateInputNumberComponent(value, props, onFieldChange),
      In: generateCommonTagSelector(value, props, onFieldChange, "InputNumber"),
      NotIn: generateCommonTagSelector(
        value,
        props,
        onFieldChange,
        "InputNumber"
      ),
      GreaterThan: generateInputNumberComponent(value, props, onFieldChange),
      LessThan: generateInputNumberComponent(value, props, onFieldChange),
      StartWith: generateInputNumberComponent(value, props, onFieldChange),
      EndWith: generateInputNumberComponent(value, props, onFieldChange),
      GreaterThanEqualsTo: generateInputNumberComponent(
        value,
        props,
        onFieldChange
      ),
      LessThanEqualsTo: generateInputNumberComponent(
        value,
        props,
        onFieldChange
      ),
      FromTo: generateRangeRuleComponent(value, props, onFieldChange, "Number"),
    },

    Version: {
      Empty: <></>,
      NotEmpty: <></>,
      EqualsTo: generateMaskedVersionComponent(value, props, onFieldChange),
      NotEqualsTo: generateMaskedVersionComponent(value, props, onFieldChange),
      In: generateCommonTagSelector(value, props, onFieldChange, "Version"),
      NotIn: generateCommonTagSelector(value, props, onFieldChange, "Version"),
      GreaterThan: generateMaskedVersionComponent(value, props, onFieldChange),
      LessThan: generateMaskedVersionComponent(value, props, onFieldChange),
      StartWith: generateMaskedVersionComponent(value, props, onFieldChange),
      EndWith: generateMaskedVersionComponent(value, props, onFieldChange),
      GreaterThanEqualsTo: generateMaskedVersionComponent(
        value,
        props,
        onFieldChange
      ),
      LessThanEqualsTo: generateMaskedVersionComponent(
        value,
        props,
        onFieldChange
      ),
      FromTo: generateRangeRuleComponent(
        value,
        props,
        onFieldChange,
        "Version"
      ),
    },
  };
  if (elementMapping[type] && elementMapping[type][operator]) {
    return elementMapping[type][operator];
  } else {
    return null;
  }
};
