import React, { useEffect, useState } from "react";
import ListProductUserComponent from "../../Components/ProductUsers/ListProductUserComponent";
import { useSelector } from "react-redux";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import { DefaultPagingValue } from "../../Constant/Common";
import { useNavigate } from "react-router-dom";
import NotificationService from "../../Services/notification";
import useFetchData from "../../Hooks/useFetchData"; // Adjust path as necessary
import { httpClient, isSuccessStatus } from "../../Api/client";
import {
  convertDataToQueryParam,
  isNullOrEmpty,
} from "../../Util/commonUtility";

const ProductUsersContainer = React.forwardRef((props, ref) => {
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [listFilterData, setListFilterData] = useState({});
  const navigate = useNavigate();

  const [paging, setPaging] = useState({
    pageSize: DefaultPagingValue.pageSize || 10,
    pageIndex: DefaultPagingValue.pageIndex || 1,
    totalPages: 0,
    totalRecords: 0,
  });
  // const [editProductUsersData, SetEditProductUsersData] = useState(false);
  // const [allUser, SetAllUser] = useState(false);
  // const [addEditProductUser, SetAddEditProductUser] = useState(false);

  // Fetch data using useFetchData
  const {
    data: productUsers,
    loading: showLoading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_PRODUCT_USERS.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    ).replace(
      "#{productId}",
      productState?.selectedProduct?.id
    )}?${convertDataToQueryParam(listFilterData)}&PageNumber=${
      paging.pageIndex
    }&PageSize=${paging.pageSize}`,
    [
      paging.pageIndex,
      paging.pageSize,
      listFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
    ]
  );

  // Update local pagination state when fetchedPagination changes
  useEffect(() => {
    if (fetchedPagination) {
      setPaging(fetchedPagination);
    }
  }, [fetchedPagination]);

  const deleteProductUser = async (data) => {
    try {
      const response = await httpClient.delete(
        `${Urls.TENANT_DELETE_PRODUCT_USER.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{userId}", data.accountId)
        }
          `
      );
      if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
        NotificationService.success(
          "Success",
          "Product User deleted successfully"
        );
        setListFilterData({}); // Reset filter to reflect changes
      } 
    } catch (error) {
       console.error(error)
    }
  };

  const handlePageChange = (page, pageSize) => {
    setPaging((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize: pageSize,
    }));
  };

  const addEditProductUserAction = (data) => {
    navigate(`/product/users/edit/${data.accountId}`);
  };

  const listFilterSubmit = (data) => {
    setListFilterData(data);
    setPaging((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter change
  };

  const resetFilter = () => {
    setListFilterData({});
    setPaging((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter reset
  };

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <ListProductUserComponent
          // addProductUser={(data) => addEditProductUserAction(data, "Add")}
          productUsers={productUsers}
          listFilterData={listFilterData}
          paging={paging}
          deleteProductUser={deleteProductUser}
          editProductUser={addEditProductUserAction}
          handlePageChange={handlePageChange}
          listFilterSubmit={listFilterSubmit}
          resetFilter={resetFilter}
        />
      )}
      {/* {addEditProductUser ? (
        <AddProductUser
          editProductUsersData={editProductUsersData}
          allUser={allUser}
          onFinish={(data, action) => addProductUserAPI(data, action)}
          backToListPage={() => {
            SetAddEditProductUser(false);
          }}
          allowedEnvironments={productState?.selectedProduct?.environments}
          allProductEnvironmentsData={allProductEnvironmentsData}
        />
      ) : (
        
      )} */}
    </>
  );
});

export default ProductUsersContainer;
