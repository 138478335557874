import React from "react";
import AddTargetContainer from "../../Containers/Target/AddTargetContainer";
const TargetAddScreen = (props) => {
  return (
    <>
      <React.Fragment>
        <AddTargetContainer />
      </React.Fragment>
    </>
  );
};

export default TargetAddScreen;
